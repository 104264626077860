import { useCustom, useGetIdentity, useQuery } from "@pankod/refine-core";
import { Autocomplete, Button, Card, Grid, MenuItem, Modal, Select, TextField, Typography, useAutocomplete } from "@pankod/refine-mui";
import { ISchool } from "interfaces";
import { useState } from "react";
import { $fetch } from "utils/fetch";
import { StudentType } from "utils/type";
import { cn } from "utils/utils";

interface Passage {
    grade: string
    passage: string[]
    key: string
    status: string
    isDisabled: boolean
    lexile: string
    answer: string
}

// "grade": 1,
// "passage": [
//     "Once upon a time, on a peaceful farm, there were three bold cows. Every night, they looked up at the moon. They wondered what it would be like to jump over it. One magical evening, as the moon shone bright, magic dust fell from the moon. The cows began to jump. They jumped and jumped. One touched a tree. One touched a star. The last one went over the moon. The moon cow landed in another state. The farmer looked for the moon cow everywhere. He was gone to a new farm."
// ],
// "answer": "",
// "status": "",
// "isDisabled": false,
// "lexile": "210L-400L",
// "key": "P1"


export function AssignablesList() {

    const [student, setStudent] = useState<StudentType | null>(null);
    const [school, setSchool] = useState<ISchool | null>(null)
    const { data: user } = useGetIdentity()
    const { autocompleteProps: schoolAutoComplete } = useAutocomplete<ISchool>({
        resource: "school",
        errorNotification: false
    });
    const { autocompleteProps } = useAutocomplete<StudentType>({
        resource: "student",
        filters: school ? [{
            field: "school",
            operator: "eq",
            value: school?.id
        }] : []
    });
    const [grade, setGrade] = useState("1")

    const { data: passages, refetch } = useQuery({
        queryKey: ["assignables", student?.id],
        queryFn: async () => {
            const res = await $fetch(`admin/assignableCheckpoint?studentId=${student?.id}`)
            return res.data as Passage[]
        }
    })

    const assigned = passages?.find(p => p.status.toLowerCase() === "assigned")

    return (
        <Grid
            container
            padding={4}
            gap={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <Card className="px-4 py-10 flex flex-col gap-4 h-min">
                {
                    user?.userType === "admin" && <>
                        <div>
                            <p className="font-bold text-lg">
                                Select School
                            </p>
                            <p className="text-sm opacity-75">
                                Select a school to filter students.
                            </p>
                        </div>
                        <Autocomplete
                            size="small"
                            {...schoolAutoComplete}
                            value={school}
                            onChange={(_, value) => {
                                setSchool(value)
                            }}
                            getOptionLabel={(item) => {
                                return item.name
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || option.id === value.id
                            }
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" required />
                            )}
                        />
                    </>
                }
                <div>
                    <p className="font-bold text-lg">
                        Select a student
                    </p>
                    <p className="text-sm opacity-75">
                        Select a student to assign to the lesson.
                    </p>
                </div>



                <Autocomplete
                    size="small"
                    {...autocompleteProps}
                    value={student}
                    onChange={(_, value) => {
                        setStudent(value);
                    }}
                    getOptionLabel={(item) => {
                        return `${item.firstName} ${item.lastName} (${item.name ?? ""})`;
                    }}
                    isOptionEqualToValue={(option, value) =>
                        value === undefined || option.id === value.id
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" required />
                    )}
                />

                {/* <Button variant="contained" className="mt-4">
                    Save Changes
                </Button> */}
            </Card>
            <Card sx={{ flex: 1, padding: 2 }}>
                <h1 className="font-bold text-lg text-black">Assignables</h1>
                {
                    assigned && <p className="font-bold text-sm text-green-800/70 mt-2">
                        Currently Assigned: Grade {assigned?.grade}, Passage {assigned?.key.replace("P", "")}
                    </p>
                }
                {
                    student ? <>
                        <div className="my-3 space-y-2">
                            <p className="text-black/70 font-bold">
                                Select Grade
                            </p>
                            <Select placeholder="Select Grade" className="h-10 w-full" value={grade} onChange={(e) => {
                                setGrade(e.target.value)
                            }}>
                                {[
                                    1, 2, 3, 4, 5, 6, 7, 8
                                ].map(g => (
                                    <MenuItem key={g} value={g} >
                                        Grade {g}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="my-3 space-y-2">
                            <p className="text-black/70 font-bold">
                                Select Passage
                            </p>
                            {
                                (student?.id && passages) && passages?.filter(p => p.grade.toString() === grade.toString()).map((passage) => (
                                    <Passage key={passage.key} passage={passage} studentId={student?.id} refetch={refetch} isAssigned={!!assigned} />
                                ))
                            }
                        </div>
                    </> : <div className="flex items-center justify-center flex-col py-5">
                        <p className="text-black/70 font-bold">
                            Select a student first
                        </p>
                        <p className="text-sm opacity-75">
                            Select a student to assign to the lesson
                        </p>
                    </div>
                }

            </Card>
        </Grid>
    )
}

export const Passage = ({ passage, studentId, refetch, isAssigned }: { passage: Passage, studentId: string, refetch: () => void, isAssigned: boolean }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={
            cn("border flex items-center justify-between border-gray-200 px-3 py-2 rounded-md", passage.isDisabled && "opacity-90")
        }>
            <div>
                <p className="text-black/70 font-bold">
                    Passage {passage.key}
                </p>
                <p className="text-sm text-opacity-50 text-black">
                    {passage.passage.join(" ").substring(0, 70)}...
                </p>
            </div>
            <div className="flex items-center gap-2">
                <Button size="small" variant="text" className="flex items-center gap-2" color="secondary" onClick={() => {
                    setIsOpen(true)
                }}>
                    View
                </Button>
                {
                    passage.status.toLowerCase() === "assigned" ? (
                        <Button size="small" variant="outlined" color="error" className="flex items-center gap-2" onClick={async () => {
                            await $fetch("admin/assignableCheckpoint/unassign", {
                                body: {
                                    textId: passage.key,
                                    grade: passage.grade,
                                    studentId: studentId,
                                },
                                method: "POST"
                            })
                            refetch()
                        }} >
                            Unassign
                        </Button>) : passage.status.toLowerCase() === "failed" ? (
                            <div className="flex items-center gap-2 py-1.5 rounded-lg px-3 bg-red-500 text-white text-sm">
                                Failed
                            </div>
                        ) : passage.status.toLowerCase() === "passed" ? (
                            (
                                <Button size="small" variant="outlined" className="flex items-center gap-2"
                                    color="success"
                                >
                                    Passed
                                </Button>
                            )
                        ) : (
                        <Button size="small" variant="outlined" className="flex items-center gap-2" onClick={async () => {
                            await $fetch("admin/assignableCheckpoint", {
                                body: {
                                    textId: passage.key,
                                    grade: passage.grade,
                                    studentId: studentId,
                                },
                                method: "POST"
                            })
                            refetch()
                        }} disabled={passage.isDisabled || isAssigned}>
                            Assign
                        </Button>
                    )

                }

            </div>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <div className="flex justify-center items-center h-full">
                    <Card className="px-10 py-6 max-w-2xl max-h-[88%]">
                        <div className="flex justify-between items-center">
                            <Typography variant="h6">

                            </Typography>
                            <Button variant="outlined" size="small" color="error" onClick={() => {
                                setIsOpen(false)
                            }}>
                                Close
                            </Button>
                        </div>
                        <div className="my-3 space-y-2 overflow-y-auto">
                            <div className="border-b border-black/10 pb-2">
                                <p className="text-black/70 font-bold">
                                    Passage {passage.key} (Lexile {passage.lexile})
                                </p>
                                <p className="text-blue-500 font-bold text-sm">
                                    {passage.passage.join(" ").split(" ").length} words
                                </p>
                            </div>
                            <div className="text-sm text-opacity-50 text-black flex flex-col gap-2  max-h-[600px] overflow-y-auto">
                                {passage.passage.map((p, i) => <p className="text-black/80" key={i}>{p}</p>)}
                            </div>
                        </div>
                    </Card>
                </div>
            </Modal>
        </div>
    )
}

