import React from "react";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useShow,
  useTranslate,
  useInvalidate,
  useCan,
} from "@pankod/refine-core";
import {
  Avatar,
  DataGrid,
  Grid,
  GridColumns,
  List,
  Paper,
  Stack,
  Typography,
  useDataGrid,
  Button,
  TagField,
  CreateButton,
  DeleteButton,
} from "@pankod/refine-mui";
import {
  AdminPanelSettings,
  CalendarViewMonthOutlined,
  AssignmentInd,
  AutoDelete,
  ChildCare,
  EventAvailable,
  EditOutlined,
  SchoolOutlined,
  LocationCityOutlined,
} from "@mui/icons-material";
import moment from "moment";

import { ISchool, ITeacher, ILicenseBulk } from "interfaces";
import { useModalForm } from "@pankod/refine-react-hook-form";
import { CreateLicenseBulk } from "components";
import { StudentType } from "utils/type";
import { ExtendLicense } from "components/license/extends";

type SchoolInfoTextProps = {
  icon: React.ReactNode;
  text?: string | React.ReactNode;
};

export const SchoolInfoText: React.FC<SchoolInfoTextProps> = ({
  icon,
  text,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent={{
      sm: "center",
      lg: "flex-start",
    }}
    gap={1}
  >
    {icon}
    <Typography variant="body1">{text}</Typography>
  </Stack>
);

export const SchoolShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show, push, createUrl, edit, list } = useNavigation();
  const {
    queryResult: { data },
    showId,
  } = useShow<ISchool>();
  const { data: canUpdateSchool } = useCan({
    resource: "school",
    action: "edit",
  });

  const { data: canLicenseSchool } = useCan({
    resource: "license",
    action: "create",
  });

  const { data: canExtendLicense } = useCan({
    resource: "license",
    action: "update",
  });

  const { data: canDeleteSchool } = useCan({
    resource: "school",
    action: "delete",
  });

  const invalidate = useInvalidate();

  const school = data?.data;
  const principal = school?.principal;
  const usedLicenses = school?.usedLicenses || 0;
  const expiredLicenses = school?.expiredLicenses || 0;
  const allLicenses = school?._count?.licenses || 0;

  const createDrawerFormProps = useModalForm<
    ILicenseBulk,
    HttpError,
    ILicenseBulk
  >({
    refineCoreProps: {
      action: "create",
      resource: "license",
      redirect: false,
      onMutationSuccess(data, variables, context) {
        invalidate({
          resource: "school",
          invalidates: ["all"],
        });
      },
    },
    defaultValues: {
      expiryType: "1year",
    },
  });

  const {
    modal: { show: showCreateDrawer },
  } = createDrawerFormProps;

  const createDrawerExtendProps = useModalForm<
    ILicenseBulk,
    HttpError,
    ILicenseBulk
  >({
    refineCoreProps: {
      action: "edit",
      resource: "license",
      redirect: false,
      onMutationSuccess(data, variables, context) {
        invalidate({
          resource: "school",
          invalidates: ["all"],
        });
      },
    },
  });

  const {
    modal: { show: showExtendDrawer },
  } = createDrawerExtendProps;

  const { dataGridProps: studentDataGridProps } = useDataGrid<
    StudentType,
    HttpError
  >({
    resource: "student",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "school",
        operator: "eq",
        value: school?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: school !== undefined,
    },
    syncWithLocation: false,
  });

  const studentCols = [
    {
      field: "firstName",
      headerName: "Full Name",
      minWidth: 250,
      flex: 1,
      renderCell(params) {
        const principal = params.row;
        return (
          <>
            <Avatar src={principal?.photoURL} alt={principal?.firstName} />
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {principal?.firstName} {principal?.lastName}
            </Typography>
          </>
        );
      },
    },
    // {
    //     field: "teacher",
    //     headerName: "Teacher",
    //     minWidth: 160,
    //     flex: 1,
    //     renderCell(params) {
    //         const teacher = params.row.teacher;
    //         return <><Avatar sx={{ width: 24, height: 24 }} src={teacher?.photoURL} alt={teacher?.firstName} />
    //             <Typography variant="subtitle2" sx={{ ml: 1 }}>
    //                 {teacher?.firstName} {teacher?.lastName}
    //             </Typography></>
    //     }
    // },
    {
      field: "teacherFirstName",
      headerName: "Teacher",
      minWidth: 160,
      flex: 1,
      renderCell(params) {
        const teacher = params.row.teacherFirstName;
        return (
          <>
            <Avatar
              sx={{ width: 24, height: 24 }}
              src={params.row.photoURL}
              alt={teacher}
            />
            <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
              {teacher}
            </Typography>
          </>
        );
      },
    },
    {
      field: "progress_lessonId",
      headerName: "Fluency Progress",
      minWidth: 120,
      flex: 1,
      valueGetter(params) {
        const progresses = (params.row as any).progress_lessonId;
        return progresses;
      },
      filterable: true,
      renderCell(params) {
        const progress = params.value;
        return (
          <Typography>
            <b>
              {progress <= 0
                ? "Not Started"
                : progress >= 21
                  ? "Completed"
                  : `Lesson ${progress}`}
            </b>
          </Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created On",
      flex: 1,
      minWidth: 200,
      valueFormatter(v) {
        const dt = moment(v.value);
        return dt.format("L") + ` (${dt.fromNow()})`;
      },
    },
  ];

  const { dataGridProps: teacherDataGridProps } = useDataGrid<
    ITeacher,
    HttpError
  >({
    resource: "teacher",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "school",
        operator: "eq",
        value: school?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: school !== undefined,
    },
    syncWithLocation: false,
  });

  const teacherCols = React.useMemo<GridColumns<ITeacher>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return (
            <>
              <Avatar src={principal?.photoURL} alt={principal?.firstName} />
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.firstName} {principal?.lastName}
              </Typography>
            </>
          );
        },
      },
      //   {
      //     field: "users[count]",
      //     headerName: "# Students",
      //     minWidth: 140,
      //     valueGetter(v) {
      //       // @ts-ignore
      //       return v.row?._count?.teacherOf;
      //     },
      //   },
      //   {
      //     field: "created_at",
      //     headerName: "Created On",
      //     flex: 1,
      //     minWidth: 200,
      //     valueFormatter(v) {
      //       const dt = moment(v.value);
      //       return dt.format("L") + ` (${dt.fromNow()})`;
      //     },
      //   },
    ],
    [t]
  );

  return (
    <>
      <CreateLicenseBulk {...createDrawerFormProps} schoolInfo={school} />
      <ExtendLicense {...createDrawerExtendProps} schoolInfo={school} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} spacing={2}>
          <Paper sx={{ p: 2 }}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">School</Typography>
            </Stack>
            <br />
            <Stack spacing={1}>
              {school && (
                <>
                  <SchoolInfoText
                    icon={<SchoolOutlined />}
                    text={school?.name}
                  />
                  <SchoolInfoText
                    icon={<LocationCityOutlined />}
                    text={school?.state?.name}
                  />
                  <SchoolInfoText
                    icon={<AdminPanelSettings />}
                    text={
                      principal ? (
                        <a
                          href="#"
                          onClick={() => {
                            show("principal", principal?.id);
                          }}
                          style={{
                            display: "flex",
                            color: "#000",
                            textDecorationLine: "underline",
                            flexDirection: "row",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{ width: 24, height: 24 }}
                            src={principal?.photoURL}
                            alt={principal?.firstName}
                          />
                          <Typography variant="subtitle2">
                            {principal?.firstName}
                          </Typography>
                        </a>
                      ) : (
                        <TagField value={"Not assigned"} />
                      )
                    }
                  />
                  <SchoolInfoText
                    icon={<CalendarViewMonthOutlined />}
                    text={
                      moment(school?.created_at).format("L") +
                      ` (${moment(school?.created_at).fromNow()})`
                    }
                  />
                  {canUpdateSchool?.can && (
                    <Button
                      sx={{ mt: 2 }}
                      endIcon={<EditOutlined />}
                      onClick={() => {
                        edit("school", school?.id!!);
                      }}
                    >
                      Update School
                    </Button>
                  )}
                </>
              )}
            </Stack>
          </Paper>
          <Paper sx={{ p: 2, my: 2 }}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">Licensing</Typography>
            </Stack>
            <br />
            <Stack spacing={1}>
              <SchoolInfoText
                icon={<AssignmentInd />}
                text={`${allLicenses} Licenses`}
              />
              <SchoolInfoText
                icon={<ChildCare />}
                text={`${usedLicenses} Used`}
              />

              <SchoolInfoText
                icon={<AutoDelete />}
                text={`${expiredLicenses} Expired`}
              />

              <SchoolInfoText
                icon={<EventAvailable />}
                text={`${allLicenses - usedLicenses - expiredLicenses
                  } Available`}
              />
              <SchoolInfoText
                icon={(
                  <svg xmlns="http://www.w3.org/2000/svg" strokeWidth={2} className="w-6 h-6" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.53 2c3.993 0 5.989 0 7.23 1.172C21 4.343 21 6.229 21 10v4c0 3.771 0 5.657-1.24 6.828C18.519 22 16.522 22 12.53 22h-1.06c-3.992 0-5.989 0-7.23-1.172C3 19.657 3 17.771 3 14v-4c0-3.771 0-5.657 1.24-6.828C5.481 2 7.478 2 11.47 2zM8 7h8m-8 5h8m-8 5h4" color="currentColor"></path></svg>
                )}
                text={`License Type: ${school?.licenses[0]?.license_type}`}
              />
              {canLicenseSchool?.can && (
                <Button
                  sx={{ mt: 2 }}
                  onClick={() => showCreateDrawer(school?.id)}
                >
                  ADD BULK
                </Button>
              )}

              {canLicenseSchool?.can && (
                <Button
                  sx={{ mt: 2 }}
                  onClick={() => showExtendDrawer(school?.id)}
                >
                  Extend License
                </Button>
              )}
              {canDeleteSchool?.can && (
                <DeleteButton
                  fullWidth
                  onSuccess={() => {
                    list("school");
                  }}
                  recordItemId={showId}
                />
              )}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9} spacing={2}>
          <Stack direction="column" spacing={2}>
            <List
              title="Students"
              breadcrumb={false}
              headerProps={{
                action: (
                  <CreateButton
                    resource="student"
                    onClick={() => {
                      push(createUrl("student") + `?school=${school?.id}`);
                    }}
                  />
                ),
              }}
              resource={"student"}
            >
              <DataGrid
                {...studentDataGridProps}
                columns={studentCols}
                autoHeight
                rowHeight={80}
                rowsPerPageOptions={[4, 10, 20, 100]}
                onCellClick={(cell) => {
                  if (cell.colDef.field === "teacher") {
                    show("teacher", cell.value.id);
                  } else {
                    show("student", cell.row.id);
                  }
                }}
              />
            </List>
            <List
              breadcrumb={false}
              cardHeaderProps={{
                title: "Teachers",
                action: (
                  <CreateButton
                    resource="teacher"
                    onClick={() => {
                      push(createUrl("teacher") + `?school=${school?.id}`);
                    }}
                  />
                ),
              }}
              resource={"teacher"}
            >
              <DataGrid
                {...teacherDataGridProps}
                columns={teacherCols}
                autoHeight
                rowHeight={80}
                onCellClick={(cell) => {
                  show("teacher", cell.row.id);
                }}
                rowsPerPageOptions={[4, 10, 20, 100]}
              />
            </List>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
