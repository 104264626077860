import React from "react";
const lData = require("./flData.json")
type MapType = {
    [code: number]: { title: string, reportColumns?: { children?: any[], headerName?: string, field?: string, cellRenderer?: (...props: any[]) => any }[], totalScoreIncluded?: boolean }
}

var correctnessRenderer = (params: { value: any; }) => {
    return <>{(typeof params?.value == "string") && ((params?.value == "✓") ?

        <svg style={{ marginTop: 10 }} width="18" height="18" viewBox="0 0 44 34" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 17L7 12L17 22L37 2L42 7L17 32L2 17Z" fill="#219653"
                stroke="#219653" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        :
        <svg style={{ marginTop: 10 }} width="18" height="18" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M2 7L7 2L20 15L33 2L38 7L25 20L38 33L33 38L20 25L7 38L2 33L15 20L2 7Z" fill="#D65050"
                stroke="#D65050" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )}<span className={"hidden-item"}>{params?.value}</span></>
}

var percentageRenderer = (params: { value: any; row: any }) => {
    return params.value ? <b style={{ textAlign: "center" }}>{Math.round(params.value * 100)}%</b> : <span style={{ textAlign: "center" }}>--</span>
}



var l8List: { [str: string]: string } = {
    "W1": "ai", //ai
    "W2": "ay", //ay
    "W3": "ae", //ae
    "W4": "ey", //ey
    "W5": "aw", //aw
    "W6": "au", //au
    "W7": "ea", //ea
    "W8": "ee", //ee
    "W9": "ei", //ei
    "W10": "ew", //ew
    "W11": "ue", //ue
    "W12": "oo", //oo
    "W13": "oi", //oi
    "W14": "oy", //oy
    "W15": "ie", //ie
    "W16": "ow", //ow
    "W17": "ou", //ou
    "W18": "on", //on
    "W19": "es", //es
    "W20": "ar", //ar
    "W21": "or", //or
    "W22": "en", //en
    "W23": "in", //in
    "W24": "ur", //ur
    "W25": "ir", //ir
    "W26": "er", //er
    "W27": "oa", //oa,
    "X1": "ai",
    "X2": "ay",
    "X3": "ae",
    "X4": "ey",
    "X5": "aw",
    "X6": "au",
    "X7": "ea",
    "X8": "ee",
    "X9": "ei",
    "X10": "eX",
    "X11": "ue",
    "X12": "oo",
    "X13": "oi",
    "X14": "oy",
    "X15": "ie",
    "X16": "ow",
    "X17": "ou",
    "X18": "on",
    "X19": "es",
    "X20": "ar",
    "X21": "or",
    "X22": "en",
    "X23": "in",
    "X24": "ur",
    "X25": "ir",
    "X26": "er",
    "X27": "oa",
}

var l10List: { [str: string]: string } = {
    "W1": "ail",
    "W2": "el",
    "W3": "ell",
    "W4": "ale",
    "W5": "ect",
    "W6": "act",
    "W7": "ict",
    "W8": "apt",
    "W9": "oun",
    "W10": "ish",
    "W11": "ash",
    "W12": "esh",
    "W13": "eel",
    "W14": "eal",
    "W15": "ook",
    "W16": "ept",
    "W17": "ion",
    "W18": "ush",
    "W19": "opt",
    "W20": "oft",
    "W21": "uck",
    "W22": "ight",
    "W23": "iar",
    "W24": "upt",
    "W25": "ite",
    "W26": "ute",
    "W27": "imp",
    "W28": "ack",
}

var l13List: { [str: string]: string } = {
    "W1": "anti-", //ai
    "W2": "de-", //ay
    "W3": "dis-", //ae
    "W4": "en-", //ey
    "W5": "em-", //aw
    "W6": "fore-", //au
    "W7": "in-", //ea
    "W8": "im-", //ee
    "W9": "il-", //ei
    "W10": "ir-", //ew
    "W11": "inter-", //ue
    "W12": "mid-", //oo
    "W13": "mis-", //oi
    "W14": "non-", //oy
    "W15": "over-", //ie
    "W16": "pre-", //ow
    "W17": "re-", //ou
    "W18": "semi-", //on
    "W19": "sub-", //es
    "W20": "super-", //ar
    "W21": "trans-", //or
    "W22": "un-", //en
    "W23": "under-", //in
}

var l15List: { [str: string]: string } = {
    "W1": "-le", //ai
    "W2": "-able", //ay
    "W3": "-ible", //ae
    "W4": "-ial", //ey
    "W5": "-ound", //aw
    "W6": "-ish", //au
    "W7": "-er", //ea
    "W8": "-est", //ee
    "W9": "-ful", //ei
    "W10": "-ic", //ew
    "W11": "-ing", //ue
    "W12": "-ion", //oo
    "W13": "-tion", //oi
    "W14": "-id", //oy
    "W15": "-esh", //ie
    "W16": "-ity", //ow
    "W17": "-ty", //ou
    "W18": "-ive", //on
    "W19": "-ative", //es
    "W20": "-itive", //ar
    "W21": "-less", //or
    "W22": "-ly", //en
    "W23": "-ment", //in
    "W24": "-ness", //in
    "W25": "-ous", //in
    "W26": "-eous", //in
    "W27": "-ious", //in
    "W28": "-ist", //in
    "W29": "-ess", //in
    "W30": "-y", //in
}

export const numberRender = (row: { value: number }) => {
    return (
        <span>
            {row.value !== null ? row.value : "-"}
        </span>
    )
}
// @ts-ignore
export const Lessons: MapType = {
    0: {
        title: "Pre-Test",
        reportColumns: [
            {
                headerName: '% Current Score',
                field: "correct_percent",
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Part 1\n(16)",
                cellRenderer: numberRender
            },
            {
                field: "p5",
                headerName: "Part 2\n(10)",
                cellRenderer: numberRender
            },
            {
                field: "p2",
                headerName: "Part 3\n(10)",
                cellRenderer: numberRender
            },
            {
                field: "p3",
                headerName: "Part 4\n(10)",
                cellRenderer: numberRender
            },
            {
                field: "p4",
                headerName: "Part 5\n(10)",
                cellRenderer: numberRender
            },
            {
                field: "p6",
                headerName: "Part 6\n(10)",
                cellRenderer: numberRender
            },
            {
                field: "p7",
                headerName: "Part 7\n(74)",
                cellRenderer: numberRender
            },
            {
                field: "p7wcpm",
                headerName: "Part 7\n(WCPM)",
                cellRenderer: (row) => {
                    return (
                        <span>
                            {row.value ?? "-"}
                        </span>
                    )
                }
            },
        ],
        totalScoreIncluded: false
    },
    1: {
        title: "Sounds of the Alphabet",
        reportColumns: [
            {
                headerName: '% Current Score',
                field: "correct_percent",
                cellRenderer: percentageRenderer
            },
            {
                headerName: 'Alphabets',
                children: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map(chr => ({
                    field: chr,
                    width: 60,
                    height: 60,
                    cellRenderer: correctnessRenderer,
                    headerName: chr
                }))
            },
        ],
        totalScoreIncluded: false
    },
    2: {
        title: "Single Letter Sounds (Random Order)",

        reportColumns: [
            {
                headerName: '% Current Score',
                field: "correct_percent",
                cellRenderer: percentageRenderer
            },
            {
                headerName: 'Alphabets (Random Order)',
                children: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map(chr => ({
                    field: chr,
                    width: 60,
                    height: 60,
                    cellRenderer: correctnessRenderer,
                    headerName: chr
                }))
            },
        ],
        totalScoreIncluded: false
    },
    3: {
        title: "Vowel Sounds (Short, Long, Y) ",
        reportColumns: [
            {
                headerName: '% Current Score',
                field: "correct_percent",
                cellRenderer: percentageRenderer
            },
            ...(Object.keys(lData["lesson3"].data).filter(itm => lData["lesson3"].data[itm].group == "p1")).map(chr => ({
                field: chr,
                width: 60,
                height: 60,
                cellRenderer: correctnessRenderer,
                headerComponent({ }) {
                    return <>
                        <img style={{
                            width: "100%",
                            objectFit: "contain",
                            objectPosition: "center"
                        }} src={`/assets/lesson3/phones/${chr.toLowerCase()}.svg`} />
                        <span className={"hidden-item"}>{chr}</span>
                    </>
                }
            }))]
    },
    4: {
        title: "Blending Basic Sounds",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(6)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)"
            },
            {
                field: "p3",
                headerName: "Practice 3\n(2)"
            },
            {
                field: "rio",
                headerName: "Read It Out\n(14)"
            }
        ]
    },
    5: {
        title: "Most Used Words",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },

            {
                field: "p1",
                headerName: "Practice 1\n(24)"
            },
            {
                field: "rio1",
                headerName: "Read It Out 1\n(21)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(24)"
            },
            {
                field: "rio2",
                headerName: "Read It Out 2\n(24)"
            }
        ]
    },
    6: {
        title: "Most Common Sounds-List 1 (Consonant Blends)",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                headerName: 'R Blend \n(8)',
                children: [
                    {
                        field: "rblend",
                        headerName: "pr, wr, tr, fr, gr, dr, cr"
                    }
                ]
            },
            {
                headerName: 'L Blend \n(6)',
                children: [
                    {
                        field: "lblend",
                        headerName: "bl, pl, sl, cl, fl, gl"
                    }
                ]
            },
            {
                headerName: 'S Blend \n(9)',
                children: [
                    {
                        field: "sblend",
                        headerName: "sc, sk, sm, sp, sn, st, sw, str"
                    }
                ]
            },
            {
                headerName: 'Mixed blend \n(11)',
                children: [
                    {
                        field: "xblend",
                        headerName: "tw, sh, shr, thr, sch"
                    }
                ]
            },
        ]
    },
    7: {
        title: "Blending with Consonant Blends",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(5)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)"
            },
            {
                field: "p3",
                headerName: "Practice 3\n(2)"
            },
            {
                field: "rio",
                headerName: "Read It Out\n(20)"
            }
        ]
    },
    8: {
        title: "Most Common Sounds-List 2 (Vowel Combinations)",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(23)",
                children: (Object.keys(lData["lesson8"].data).filter(itm => lData["lesson8"].data[itm].group == "p1")).map(itm => ({
                    field: (itm),
                    cellRenderer: correctnessRenderer,
                    headerName: l8List[itm]
                }))
            },
            {
                field: "p2",
                headerName: "Practice 2\n(14)",
            }
        ]
    },
    9: {
        title: "Blending with Vowel Combinations",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(10)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)"
            },
            {
                field: "p3",
                headerName: "Practice 3\n(2)"
            },
            {
                field: "rio",
                headerName: "Read It Out\n(24)"
            }
        ]
    },
    10: {
        title: "Most Common Sounds-List 3 (Vowel/Consonant Blends)",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(28)",
                children: (Object.keys(lData["lesson10"].data).filter(itm => lData["lesson10"].data[itm].group == "p1")).map(itm => ({
                    field: (itm),
                    cellRenderer: correctnessRenderer,
                    headerName: l10List[itm]
                }))
            },
            {
                field: "p2",
                headerName: "Practice 2\n(18)",
            }
        ]
    },
    11: {
        title: "Blending with Vowel/Consonant Blends",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(10)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)"
            },
            {
                field: "p3",
                headerName: "Practice 3\n(3)"
            },
            {
                field: "rio",
                headerName: "Read It Out\n(35)"
            }
        ]
    },
    12: {
        title: "Syllable Breakdowns",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(10)"
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)"
            },
            {
                field: "rio",
                headerName: "Read It Out\n(46)"
            }
        ]
    },
    13: {
        title: "Most Common Sounds-List 4 (Prefixes)",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Review \n(28)",
                children: (Object.keys(lData["lesson13"].data).filter(itm => lData["lesson13"].data[itm].group == "p1")).map(itm => ({
                    field: (itm),
                    cellRenderer: correctnessRenderer,
                    headerName: l13List[itm]
                }))
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)",
            }
        ]
    },
    14: {
        title: "Blending with Prefixes",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(10)",
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)",
            },
            {
                field: "p3",
                headerName: "Practice 3\n(3)",
            },
            {
                field: "rio",
                headerName: "Read It Out\n(24)",
            }
        ]
    },
    15: {
        title: "Most Common Sounds-List 5 (Suffixes)",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1 \n(30)",
                children: (Object.keys(lData["lesson15"].data).filter(itm => lData["lesson15"].data[itm].group == "p1")).map(itm => ({
                    field: (itm),
                    cellRenderer: correctnessRenderer,
                    headerName: l15List[itm]
                }))
            },
            {
                field: "p2",
                headerName: "Practice 2\n(24)",
            }
        ]
    },
    16: {
        title: "Blending with Suffixes",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(10)",
            },
            {
                field: "p2",
                headerName: "Practice 2\n(20)",
            },
            {
                field: "p3",
                headerName: "Practice 3\n(3)",
            },
            {
                field: "rio",
                headerName: "Read It Out\n(34)",
            }
        ]
    },
    17: {
        title: "Most Common Irregular Words",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(39)",
            },
            {
                field: "p2",
                headerName: "Practice 2\n(10)",
            },

            {
                field: "rio",
                headerName: "Read It Out\n(58)",
            }
        ]
    },
    18: {
        title: "Commonly Mispronounced Sounds",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "p1",
                headerName: "Practice 1\n(11)",
            },
            {
                field: "p2",
                headerName: "Practice 2\n(11)",
            },
            {
                field: "rio",
                headerName: "Read It Out\n(48)",
            }
        ]
    },
    19: {
        title: "Common Phrases",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "rio",
                headerName: "Phrase Practice\n(25)",
            },
        ]
    },
    20: {
        title: "Sentence Practice",
        reportColumns: [
            {
                field: "correct_count",
                headerName: '# Correct',
            },
            {
                field: "correct_percent",
                headerName: '% Current Score',
                cellRenderer: percentageRenderer
            },
            {
                field: "rio",
                headerName: "Phrase Practice\n(25)",
            },
        ]
    },
    21: {
        title: "Fluency Reads",
        reportColumns: [
            {
                field: "wcpm",
                headerName: "WCPM (Word Count Per Minute)",
                cellRenderer: (params: { value: any; }) => {
                    console.log(params)
                    return (
                        <b style={{ textAlign: "center" }}>
                            L3: ({params.value.wcpmL3 ?? "-"})
                            L4: ({params.value.wcpmL4 ?? "-"})
                            L5: ({params.value.wcpmL5 ?? "-"})
                            L6: ({params.value.wcpmL6 ?? "-"})
                            L7: ({params.value.wcpmL7 ?? "-"})
                            L8: ({params.value.wcpmL8 ?? "-"})
                        </b>
                    )
                }
            },
            {
                field: "l3",
                headerName: "Level 3\n(91)",
                cellRenderer: percentageRenderer
            },

            {
                field: "l4",
                headerName: "Level 4\n(120)",
                cellRenderer: percentageRenderer
            },
            {
                field: "l5",
                headerName: "Level 5\n(158)",
                cellRenderer: percentageRenderer
            },
            {
                field: "l6",
                headerName: "Level 6\n(289)",
                cellRenderer: percentageRenderer
            },
            {
                field: "l7",
                headerName: "Level 7\n(289)",
                cellRenderer: percentageRenderer
            },
            {
                field: "l8",
                headerName: "Level 8\n(289)",
                cellRenderer: percentageRenderer
            }
        ]
    }
}
    ;