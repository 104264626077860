import { Add, CalendarMonth, PlusOne, PrintRounded, RemoveCircleOutline } from "@mui/icons-material";
import { useGetIdentity, useMutation, useNavigation } from "@pankod/refine-core";
import { Autocomplete, Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useAutocomplete } from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { ISchool, ITeacher } from "interfaces";
import { useState } from "react";

import { DateRangePicker } from 'react-dates';
import { flushSync } from "react-dom";
import { $fetch } from "utils/fetch";
import { StudentType } from "utils/type";
import { exportAsPdf } from "utils/utils";

export interface DyslexiaLog {
    id: string
    dyslexiaId: string
    textId: string
    answer: string
    replayed: number
    wasCorrect: boolean
    created_at: string
}

export interface DyslexiaReportI {
    student: {
        id: string
        firstName: string
        lastName: string
        grade: number,
        school: {
            name: string
        },
        parent: {
            firstName: string
            lastName: string
        },
        teacher: {
            firstName: string
            lastName: string
        }
    },
    totalReplayed: number,
    dyslexiaRecord: {
        id: string,
        score: number,
        isComplete: boolean,
        current: number,
    },
    dyslexiaLogs: DyslexiaLog[]
}
export interface SchoolRepAgg {
    "average_minutes": number,
    "total_minutes": number[],
    "median_lesson": number,
    "lessons": number[]
}
export interface Report {
    [key: string]: SchoolRepAgg
}

export const DyslexiaReportList = () => {
    const { mutate, data } = useMutation({
        mutationFn: async () => {
            const res = await $fetch<DyslexiaReportI>("admin/dyslexiaReports/class/:id", {
                params: {
                    id: teacher?.id
                }
            })
            return res.data as unknown as DyslexiaReportI[]
        }
    })

    const { autocompleteProps: schoolAutoCompleteProps } = useAutocomplete<ISchool>({
        resource: "school",
    });
    const { push } = useNavigation()
    const [school, setSchool] = useState<ISchool | null>(null)
    const { autocompleteProps } = useAutocomplete<ITeacher>({
        resource: "teacher",
        filters: [{
            field: "school",
            value: school?.id,
            operator: "eq"
        }]
    });

    const { data: user } = useGetIdentity()
    function isLikelyDyslexic(score: number, grade: number) {
        if (!score) return "-"
        if (grade <= 2) {
            if (score <= 10) {
                return "Show Possible Risk for Dyslexia"
            } else {
                return "Likely No Risk for Dyslexia"
            }
        } else {
            if (score <= 12) {
                return "Show Possible Risk for Dyslexia"
            } else {
                return "Likely No Risk for Dyslexia"
            }
        }
    }

    const [teacher, setTeacher] = useState<ITeacher | null>(null)

    return (
        <Grid
            container
            padding={4}
            gap={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <div className="flex bg-white rounded-md flex-col gap-4 px-8 py-4 h-max">
                <h1 className="font-bold text-xl">
                    Dyslexia Report
                </h1>

                {
                    user?.userType === "admin" && <div>
                        <p className="font-bold">
                            Select School
                        </p>
                        <Autocomplete
                            size="small"
                            {...schoolAutoCompleteProps}
                            value={school}
                            onChange={(_, value) => {
                                setSchool(value)
                            }}
                            getOptionLabel={(item) => {
                                return item.name
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || option.id === value.id
                            }
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" required />
                            )}
                        />
                    </div>
                }
                <p className="font-bold">
                    Select Teacher
                </p>

                <Autocomplete
                    size="small"
                    {...autocompleteProps}
                    value={teacher}
                    onChange={(_, value) => {
                        setTeacher(value);
                    }}
                    getOptionLabel={(item) => {
                        return `${item.firstName} ${item.lastName}`;
                    }}
                    isOptionEqualToValue={(option, value) =>
                        value === undefined || option.id === value.id
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" required />
                    )}
                />


                <Button variant="contained" style={{ alignSelf: "flex-start" }} onClick={() => {
                    mutate()
                }}>
                    Generate
                </Button>
            </div>
            <Card sx={{ flex: 1, padding: 2 }}>
                <div className="flex justify-between gap-2 cursor-pointer" onClick={() => {
                    exportAsPdf("#student-report", "student-report", true)
                }}>
                    <h1 className="font-bold text-xl text-center my-2">Dyslexia Screening Report</h1>
                    <div className="flex items-center gap-2 px-4" >
                        <PrintRounded className="text-green-700" />
                        <p className="text-green-600">
                            Print
                        </p>
                    </div>
                </div>
                <div id="student-report" className="py-4">
                    {
                        data && <div className="flex border-t border-stone-600 mt-4 p-4 flex-col gap-4" id="student-report">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Student</TableCell>
                                        <TableCell>Score</TableCell>
                                        <TableCell>
                                            Remark
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.map((report, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="cursor-pointer hover:underline hover:text-blue-600" onClick={() => {
                                                    push(`/student/show/${report.student?.id}`)
                                                }}>{report.student?.firstName} {report.student?.lastName}</TableCell>
                                                <TableCell>{report.dyslexiaRecord.score || "-"}</TableCell>
                                                <TableCell>{isLikelyDyslexic(
                                                    report.dyslexiaRecord.score,
                                                    report.student.grade || 0
                                                ) || "-"}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    }
                </div>
            </Card>
        </Grid>
    )

}