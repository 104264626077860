import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useCustom, useNavigation, useOne, useShow } from "@pankod/refine-core";
import {
  Autocomplete,
  Button,
  Card,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useAutocomplete,
} from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { apiUrl } from "const/constants";
import { IStudent } from "interfaces";
import { useEffect, useMemo, useState } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from "recharts";
import { StudentType } from "utils/type";
import { cn, exportAsPdf } from "utils/utils";

const rules = [
  {
    grade: 1,
    minWcpm: 16,
  },
  {
    grade: 2,
    minWcpm: 59,
  },
  {
    grade: 3,
    minWcpm: 79,
  },
  {
    grade: 4,
    minWcpm: 95,
  },
  {
    grade: 5,
    minWcpm: 109,
  },
  {
    grade: 6,
    minWcpm: 116,
  },
  {
    grade: 7,
    minWcpm: 117,
  },
  {
    grade: 8,
    minWcpm: 118,
  },
];

interface Checkpoint {
  id: string;
  sentence: {
    [key: string]: boolean
  }
  textId: string;
  wcpm: number;
  lexile: string
  passage: string[]
  score: number;
  required: number;
  created_at: string;
  date: string;
  grade: string;
  key: string
  percentile: number
  wasCorrect: boolean
  CheckPointTrial: {
    CheckPointProgress: {
      lessonId: number;
    };
  };
  type: "assignable" | "checkpoint"
}

export const IndividualCheckpointShow = () => {
  const { showId } = useShow();
  const { data } = useCustom<{
    checkpoint: Checkpoint[];
    allLogs: Checkpoint[]
  }>({
    url: `${apiUrl}/admin/chreport/${showId}`,
    method: "get",
  });
  const [selectedTab, setSelectedTab] = useState("checkpoint");

  const [graphData, setGraphData] = useState(data?.data.checkpoint.map(ch => ({
    ...ch,
    date: `${new Date(ch.created_at).getMonth() + 1}/${new Date(ch.created_at).getDate()}`,
    //@ts-expect-error
  })).filter(ch => ch.wcpm > 0 && ch.type === "checkpoint").sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
  )
  const tableData = useMemo(() => {
    return data?.data.allLogs.map(ch => ({
      ...ch,
      date: `${new Date(ch.created_at).getMonth() + 1}/${new Date(ch.created_at).getDate()}`,
      //@ts-expect-error
    })).filter(ch => ch.wcpm > 0 && ch.type === "checkpoint").sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
  }, [data?.data])

  useEffect(() => {
    if (selectedTab === "checkpoint" || selectedTab === "assignable") {
      setGraphData(data?.data.checkpoint.map(ch => ({
        ...ch,
        date: `${new Date(ch.created_at).getMonth() + 1}/${new Date(ch.created_at).getDate()}`,
        //@ts-expect-error
      })).filter(ch => ch.type === selectedTab && ch.wcpm > 0).sort((a, b) => new Date(a.created_at) - new Date(b.created_at)))
    }
  }, [data?.data, selectedTab])

  const { autocompleteProps } = useAutocomplete<StudentType>({
    resource: "student",
  });

  const [student, setStudent] = useState<StudentType | null>(null);

  const { showUrl, push } = useNavigation();

  const CustomizedLabel = (props: Record<string, any>) => {
    const { x, y, stroke, value, index } = props;
    console.log({ props })
    let data = graphData?.find((item, idx) => item.grade === value && idx === index)
    const wcpm = data?.wcpm
    return (
      <svg>
        {/* {gLevel ? (
          <text
            x={x}
            y={y}
            dy={-23}
            fill={stroke}
            fontSize={12}
            fontWeight={500}
            textAnchor="middle"
          >
            Grade {formatGradeLevel(gLevel)}
          </text>
        ) : null} */}
        {value ? (
          <text
            x={x}
            y={y + 3}
            dy={10}
            fill={stroke}
            fontSize={12}
            fontWeight={500}
            textAnchor="middle"
          >
            {wcpm}
          </text>
        ) : null}
      </svg>
    );
  };

  const { data: currentStudent } = useOne<IStudent>({
    resource: "student",
    id: showId ?? "",
  });



  return (
    <div className="h-full w-full flex items-center gap-4">
      <Card
        style={{
          width: "100%",
          height: "100%",
          padding: 20,
        }}
        className="flex flex-col"
      >
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          <Tab label="Checkpoints" value="checkpoint" />
          <Tab label="Assignables" value="assignable" />
          <Tab label="Detailed Report" value="detail" />
        </Tabs>
        {
          (selectedTab === "checkpoint" || selectedTab === "assignable") && (
            <div className="w-full h-full flex  gap-2 mt-2">
              <div className="w-full h-full flex flex-col" id="graph">
                <div className="">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      exportAsPdf("#graph", `Progress Report `);
                    }}
                  >
                    Print
                  </Button>
                </div>
                <div className="flex-grow" id="graph">
                  <div className="h-[12%] flex items-center justify-between">
                    <div>
                      <p className="text-2xl font-bold">
                        WCPM Per Grade-Level
                      </p>
                      <p className="font-medium">
                        Student Name: {currentStudent?.data.firstName}{" "}
                        {currentStudent?.data.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between h-full">
                    <p style={{
                      writingMode: "vertical-rl",
                      rotate: "180deg"
                    }} className="text-xl font-bold text-center">
                      Grade Level
                    </p>
                    <ResponsiveContainer width="100%" height="88%">
                      <LineChart
                        title="Progress Report"
                        style={{
                          color: "yellow",
                        }}
                        data={graphData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <Text textAnchor="start" fontSize={40} scaleToFit>
                          Progress Report
                        </Text>
                        <CartesianGrid strokeDasharray="4 4" fillRule="evenodd" horizontalFill={["#E8E9EB", "#fff"]} />
                        <XAxis dataKey="date" interval={0} />
                        <YAxis dataKey="grade" interval={0} type="number" ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8]} includeHidden tickFormatter={(value) => `${value}`} tickMargin={10} />
                        <Tooltip
                          contentStyle={{
                            color: "black",
                          }}
                        />

                        <Line
                          type="monotone"
                          dataKey="grade"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                          label={<CustomizedLabel />}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <Card className="w-1/4 border-2 border-black h-max">
                <div className="bg-yellow-300 py-1">
                  <p className="font-bold text-center">Key</p>
                </div>
                {rules.map((item) => {
                  return (
                    <div
                      key={item.grade}
                      className="py-1 bg-sky-200 px-3 border-b border-black text-black font-medium"
                    >
                      <p className="font-bold">PASSED Grade {item.grade}</p>
                      <p>Min. wcpm: {item.minWcpm}</p>
                    </div>
                  );
                })}
              </Card>
            </div>
          )
        }
        {
          selectedTab === "detail" && (
            <DetailReport data={tableData || []} />
          )
        }
      </Card>
    </div>
  );
};

export const DetailReport = ({ data }: { data: Checkpoint[] }) => {
  const [checkpoint, setCheckpoint] = useState<Checkpoint>()
  const [localData, setLocalData] = useState<Checkpoint[]>(data)

  const [sorting, setSorting] = useState<Record<string, "asc" | "desc">>()
  useEffect(() => {
    setLocalData(data)
  }, [data])
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="cursor-pointer !flex !items-center !gap-2" onClick={() => {
              setSorting({ ...sorting, created_at: sorting?.created_at === "asc" ? "desc" : "asc" })
              if (sorting?.created_at === "asc") {
                //@ts-expect-error
                setLocalData(data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)))
              } else {
                //@ts-expect-error
                setLocalData(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
              }
            }}>
              <p>
                Date
              </p>
              <div className="bg-gray-50 hover:bg-gray-200 w-7 h-7 rounded-full flex items-center justify-center transition-all">
                {
                  sorting?.created_at === "asc" ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />
                }
              </div>
            </TableCell>
            <TableCell>Grade</TableCell>
            <TableCell>WCPM</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Percentile</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localData.map((item, idx) => (
            <TableRow key={item.id} selected={
              idx % 2 === 0 ? true : false
            }>
              <TableCell className="!p-2">{item.date}</TableCell>
              <TableCell className="!p-2">{item.grade}</TableCell>
              <TableCell className="!p-2">{item.wcpm ?? "-"}</TableCell>
              <TableCell className={
                cn(
                  "!p-0 !px-4 !text-white",
                  item.wasCorrect ? "bg-green-600" : "bg-red-600"
                )
              }>
                {
                  item.wasCorrect ? "Passed" : "Failed"
                }
              </TableCell>
              <TableCell className="!p-2 !px-6 ">{item.percentile}</TableCell>
              <TableCell className="!p-2 !px-6 !font-bold">{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</TableCell>
              <TableCell className="!p-2">
                <Button variant="outlined" color="primary" size="small" onClick={() => {
                  setCheckpoint(item)
                }}>
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={!!checkpoint} onClose={() => setCheckpoint(undefined)}>
        <div className="flex justify-center items-center h-full" >
          <Card className="px-10 py-6 max-w-2xl max-h-[88%]" >
            <div className="flex justify-between items-center">
              <Button variant="outlined" size="small" color="secondary" onClick={() => {
                exportAsPdf("#checkpoint-report", "checkpoint report")
              }}>
                Save as PDF
              </Button>
              <Button variant="outlined" size="small" color="error" onClick={() => {
                setCheckpoint(undefined)
              }}>
                Close
              </Button>
            </div>
            <div className="my-3 space-y-2 overflow-y-auto p-2 h-full" id="checkpoint-report">
              <div className="border-b border-black/10 pb-2">
                <p className="text-black/70 font-bold">
                  Passage {checkpoint?.key} (Lexile {checkpoint?.lexile})
                </p>
                <p className="text-blue-500 font-bold text-sm">
                  {checkpoint?.passage.join(" ").split(" ").length} words
                </p>
                <div className="my-2 flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M19 20a7 7 0 1 0 0-14a7 7 0 0 0 0 14M33 8s2.25 4.5 0 10m7-14s4.5 8.1 0 18M4 40.8V42h30v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C27.92 28 25.68 28 21.2 28h-4.4c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C4 34.08 4 36.32 4 40.8"></path></svg>
                    <span className="text-black/70 font-bold text-sm">
                      WCPM {checkpoint?.wcpm}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="currentColor" d="M14 23h8v2h-8zm-4 0h2v2h-2zm4-5h8v2h-8zm-4 0h2v2h-2zm4-5h8v2h-8zm-4 0h2v2h-2z"></path><path fill="currentColor" d="M25 5h-3V4a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v1H7a2 2 0 0 0-2 2v21a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2M12 4h8v4h-8Zm13 24H7V7h3v3h12V7h3Z"></path></svg>
                    <span className={cn("font-bold text-sm", checkpoint?.wasCorrect ? "text-green-600" : "text-red-400")}>
                      Status {checkpoint?.wasCorrect ? "Passed" : "Failed"}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path fill="currentColor" d="M12 3a9 9 0 0 1 8.497 6.025L12 12z" stroke="none"></path><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0-18 0"></path></g></svg>
                    <span className={cn("font-bold text-sm")}>
                      Percentile {checkpoint?.percentile}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-sm text-opacity-50 py-3 text-black flex flex-col gap-2  max-h-[600px] overflow-y-auto">
                {checkpoint?.passage.map((p, i) => {
                  return (
                    <p key={i} className="flex items-center gap-1 flex-wrap">{p.split(" ").map((w, j) => {
                      const previousTickers = Array.from(new Array(i)).reduce((prev, curr, ci) => {
                        return checkpoint.passage[ci].split(" ").length + prev
                      }, 0)
                      var n = previousTickers + j
                      const word = w.trim().replace(/[^A-Za-z0-9_ -]+/ig, '')

                      const isCorrect = checkpoint.sentence[`${word}${n}`]
                      console.log({
                        word: `${word}${n}`,
                        isCorrect,
                        checkpoint: checkpoint.sentence
                      })
                      return <span key={j} className={cn(isCorrect ? "text-green-600" : "text-red-400 font-bold")}>{w}</span>
                    })}
                    </p>
                  )
                })}
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    </div>
  )
}