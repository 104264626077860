import {
    useCustom,
    useDataProvider,
    useGetIdentity,
    useInvalidate,
    useList,
    useNotification,
    useShow
} from "@pankod/refine-core";
import { IActivityLog, IActivityProgress, IProgress, IStudent, IUser } from "../../interfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { useConfirm } from "material-ui-confirm";
import { Badge, Box, Button, Card, Stack, Tab, Tabs, Typography } from "@pankod/refine-mui";
import { betterFetch } from "@better-tools/fetch"
import {
    CheckOutlined,
    ClearOutlined, DownloadOutlined, HistoryOutlined, PauseOutlined, PlayArrowTwoTone, PlayLessonOutlined
} from "@mui/icons-material";
import { useCTLesson } from "../../const/ct"
import { createAxios } from "../../utils/customAxios";
import { $fetch } from "utils/fetch";
function a11yProps(index: number) {
    return {
        id: `tab-L${index}`,
        'aria-controls': `L${index}`,
    };
}
var timeSince = function (date: any) {
    if (typeof date !== 'object') {
        date = new Date(date);
    }

    var seconds = Math.floor(((new Date()).getTime() - date.getTime()) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'y';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'm';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'd';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "hr";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "min";
                    } else {
                        interval = seconds;
                        intervalType = "sec";
                    }
                }
            }
        }
    }

    if (interval > 1 || interval === 0) {
        intervalType += 's';
    }
    if (intervalType != "now") {
        intervalType += " ago"
    }

    return interval + '' + intervalType;
};

function sortByGroup(myArray: any[], info: { [x: string]: { group: any; }; }, order: string | any[],) {
    myArray.sort(function (a, b) {
        return order.indexOf(info[a.textId].group) - order.indexOf(info[b.textId].group);
    });
    return myArray
}


function TextItem({ toogleAudio, isPlaying, log, dataRef, lessonId }: { toogleAudio: any, isPlaying: boolean, log: any, dataRef: any, lessonId: number }) {
    const { data: user } = useGetIdentity();
    const notification = useNotification();
    var resultText = log.text || "";
    if (Array.isArray(log.text) && (dataRef.subType == "iWord")) {
        resultText = dataRef.question.split(" ").filter((x: any, i: number) => log.text.indexOf(i) != -1).join(", ")
    } else if ((dataRef.subType == "cSent")) {
        resultText = `Sentence #${Number.parseInt(log.text) + 1}`
    } else if ((dataRef.subType == "cOpts")) {
        // console.log({dat})
        // resultText = dataRef.options[Number.parseInt(log.text)].item
    } else if ((dataRef.subType == "iReplace")) {
        resultText = dataRef.options[Number.parseInt(log.text)]
    }
    return <Card key={log.id} sx={{ display: "flex", border: "1px solid", padding: 1, flexDirection: "column" }}>

        <div style={{ display: "flex", flexDirection: "row", gap: 5, marginBottom: 5 }}>

            {
                log.wasCorrect ? <CheckOutlined color={"success"} />
                    :
                    <ClearOutlined color={"error"} />
            }
            {
                dataRef.subType == "iWord" && <span >Select important words</span>
            }
            {
                dataRef.subType == "cSent" && <span>Select sentence</span>
            }

            {
                dataRef.subType == "cOpts" && <span>{dataRef.question}</span>
            }
            {
                ((dataRef.subType == "types") || (dataRef.type == "types")) && <span>Spelling</span>
            }
            {
                dataRef.type == "question" && <span>{dataRef.item}</span>
            }
            {
                dataRef.subType == "iReplace" && <span>Fill blank space</span>
            }
            {/*{*/}
            {/*    (dataRef[log.textId].text || log.textId)*/}
            {/*}*/}

        </div>


        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <span style={{ color: "rgba(0,0,0,0.95)", alignSelf: "center", flex: 1, textAlign: "center", marginBottom: 2 }}>{resultText || ""}</span>
            <span style={{ color: "rgba(0,0,0,0.7)", alignSelf: "center", flex: 1, textAlign: "center", marginBottom: 2, fontSize: 10 }}>(Typing Practice)</span>
        </Stack>

    </Card>
}


function WordItem({ toogleAudio, showPlay, isPlaying, log, word, lessonId }: { toogleAudio: any, showPlay: boolean, isPlaying: boolean, log: any, word: any, lessonId: number }) {
    const { data: user } = useGetIdentity();
    const notification = useNotification();

    return <Card key={log.id} sx={{ display: "flex", border: "1px solid", padding: 1, flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: 5, marginBottom: 5 }}>

            {
                log.wasCorrect ? <CheckOutlined color={"success"} />
                    :
                    <ClearOutlined color={"error"} />
            }
            <span dangerouslySetInnerHTML={{ __html: word }}></span>

        </div>


        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>

            {
                log.audioId && <>
                    <span style={{ marginRight: 5, fontSize: 12 }}>
                        {timeSince(log.created_at)}
                    </span>

                    {
                        showPlay && <>
                            {
                                (isPlaying ? <PauseOutlined onClick={() => toogleAudio()} /> : <PlayArrowTwoTone onClick={() => toogleAudio()} />)
                            }
                        </>
                    }

                    <DownloadOutlined onClick={() => {
                        //var content = content of file;
                        var dl = document.createElement('a');
                        dl.setAttribute('href', `https://asr-record.s3.amazonaws.com/${log.audioId}`);
                        dl.setAttribute('download', log.textId + ".wav");
                        dl.target = "_blank"
                        dl.click();
                    }} />

                </>
            }
            {
                !log.audioId && <span style={{ color: "rgba(0,0,0,0.25)", alignSelf: "center", flex: 1, textAlign: "center", marginBottom: 2 }}>Recording empty</span>
            }
        </Stack>

    </Card>
}

function SentenceItem({ toogleAudio, isPlaying, log, sentence, shouldShowLevel, sentenceIndex }: { toogleAudio: any, isPlaying: boolean, log: any, sentence: string, shouldShowLevel: any, sentenceIndex: any }) {
    var wRes = log.sentence;
    if (Array.isArray(sentence)) {
        sentence = sentence.join(" ")
    }
    return <Card key={log.id} sx={{
        display: "flex", border: "1px solid", padding: 1, flexDirection: "column",
        gridColumn: (log.format == "sentence") ? "1/-1" : undefined
    }}>
        {
            shouldShowLevel && <div style={{ display: "flex", flexDirection: "row", gap: 5, marginBottom: 5 }}>
                <span style={{ color: "rgba(0,0,0,0.6)", alignSelf: "start", flex: 1, textAlign: "start", marginBottom: 2, fontSize: 18, fontWeight: "bold" }}>Level {sentenceIndex}</span>
            </div>
        }
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
            {
                wRes && <span style={{ display: "flex", gap: 5, flexWrap: "wrap" }}> {sentence.split(" ").map((word: string, i: any) => {
                    var wrdK = word.toLowerCase().replace(/(\.|\?|'|`|"|’)/ig, "").replace(",", "");
                    var keyInwRes = Object.keys(wRes).find((k) => k.toLowerCase().replace(/(\.|\?|'|`|"|’)/ig, "").replace(",", "") == (wrdK + i))
                    var result = keyInwRes ? wRes?.[keyInwRes] : wRes?.[wrdK + i]
                    return <span key={wrdK + i} style={{ color: result ? "green" : "red", display: "inline-block" }}  >{word}</span>
                })} </span>
            }


        </div>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            {
                log.audioId && <>
                    <span style={{ marginRight: 5, fontSize: 12 }}>
                        {timeSince(log.created_at)}
                    </span>
                    {
                        (isPlaying ? <PauseOutlined onClick={() => toogleAudio()} /> : <PlayArrowTwoTone onClick={() => toogleAudio()} />)
                    }
                    <DownloadOutlined onClick={() => {
                        //var content = content of file;
                        var dl = document.createElement('a');
                        dl.setAttribute('href', `https://asr-record.s3.amazonaws.com/${log.audioId}`);
                        dl.setAttribute('download', log.itemNumber + ".wav");
                        dl.target = "_blank"
                        dl.click();
                    }} />

                </>
            }
            {
                !log.audioId && <span style={{ color: "rgba(0,0,0,0.25)", alignSelf: "center", flex: 1, textAlign: "center", marginBottom: 2 }}>Recording empty</span>
            }
        </Stack>


    </Card>
}


export default function CTRecordings({ userIden, student }: { userIden: IUser, student: IStudent }) {
    const apiUrl = process.env.REACT_APP_BACKEND;
    const confirm = useConfirm();
    const dataProvider = useDataProvider()
    const [expanded, setExpandeds] = useState({})
    const notification = useNotification();
    const invalidate = useInvalidate();
    const [selectedLesson, setSelectedLesson] = useState(1);
    const [playState, setPlayState] = useState<{ [ky: string]: any }>({})
    const LessonKeys = useCTLesson(student.grade)
    const lessonsCount = LessonKeys.days.length / 4

    //refine use get custom to pull ActivityProgress of critical thinking

    const { data: progressesData } = useList<IActivityProgress>(
        {
            resource: `ctProgress/${student.id}`,
            config: {
                filters: [
                    {
                        field: "studentId",
                        operator: "eq",
                        value: student.id
                    }
                ]
            }
        })
    const progresses = progressesData?.data || []
    useEffect(() => {
        var hashChecker: string | number | NodeJS.Timeout | undefined;
        var hashChanged = (hash: string) => {
            var lessonId = Number.parseInt(hash.replace("#", "").replace("L", "")) || 0;
            document.getElementById(`tab-L${lessonId}`)!!.scrollIntoView({

                behavior: "smooth",
            })
            setSelectedLesson(lessonId)
        }
        if (("onhashchange" in window)) {
            window.onhashchange = function () {
                hashChanged(window.location.hash);
            }
            // Or $(window).bind( 'hashchange',function(e) {
            //       alert(window.location.hash);
            //   });
        }
        else {
            // @ts-ignore
            var prevHash = window.location.hash;
            // @ts-ignore
            hashChecker = window.setInterval(function () {
                if (window.location.hash != prevHash) {
                    prevHash = window.location.hash;
                    hashChanged(window.location.hash);
                }
            }, 100);
        }
        hashChanged(window.location.hash);
        return () => {
            clearInterval(hashChecker)
        }
    }, [])


    const currentLesson = useMemo(() => {
        if (progressesData?.data) {
            const progresses = progressesData?.data
            var currentLesson = Array.from(new Array(lessonsCount)).map((_, i) => {
                var prgItem = progresses
                    .find((a) =>
                        a.itemNumber === i)
                return {
                    lessonId: i,
                    isLocked: prgItem == null
                }
            })
                .filter(a => !a.isLocked)
                .map(a => a.lessonId)
                //     ||
                //     ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
                // )
                // .map((a, i)=>{
                //     return ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
                //         ? (a.lessonId - 1) : a.lessonId
                // })
                .sort((a, b) => a - b)
                ?.[0];

            currentLesson = (currentLesson >= 1) ? currentLesson : 0
            return currentLesson
        } else {
            return null
        }

    }, [progressesData])

    useEffect(() => {
        if (!window.location.hash && currentLesson) {
            document.getElementById(`tab-L${currentLesson}`)!!.scrollIntoView({
                behavior: "smooth",
            })

            setSelectedLesson(currentLesson)
        }
    }, [currentLesson])


    return <>
        <Typography variant={"h4"} style={{ textAlign: "center", fontWeight: "bold" }}>CT Recordings</Typography>
        <Box
            sx={{ flexGrow: 1, alignSelf: "stretch", overflow: "hidden", display: 'flex' }}
        >
            <Box
                sx={{ alignSelf: "stretch", overflow: "hidden", display: 'flex', height: "80vh" }}>
                <Tabs value={selectedLesson}
                    onChange={(_, v) => setSelectedLesson(v)}
                    variant="scrollable"
                    indicatorColor={"secondary"}
                    textColor={"secondary"}
                    orientation={"vertical"} aria-label="basic tabs example">
                    {(Array.from(Array(lessonsCount))).map((label, i) => {
                        var progress = progresses.filter(pr => Math.floor((pr.itemNumber) / 4) == i + 1)
                        var isActive = progress?.length >= 1
                        /// i is 0...39
                        // check if i * 4 + actId is complete
                        // @ts-ignore

                        var wasComplete = progress.some(prg => prg.isComplete && (prg.itemNumber == ((i + 1) * 4)))
                        var lessonId = i + 1

                        var itemTitle = `Lesson ${lessonId}`
                        var itemColor = (isActive ? (wasComplete ? "green" : "blue") : "gray")
                        var itemStatus = isActive ? (wasComplete ?
                            <b style={{ textAlign: "center", verticalAlign: "center" }}><CheckOutlined fontSize={"inherit"} /> Complete</b>
                            :
                            <b style={{ textAlign: "center", verticalAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}><PlayArrowTwoTone fontSize={"inherit"} /> Pending</b>)
                            : <b style={{ textAlign: "center", verticalAlign: "center" }}>In-Active</b>

                        return <Tab iconPosition={"bottom"} icon={
                            <Typography variant={"body2"} style={{ fontSize: 11 }}>{itemStatus}</Typography>
                        } key={i} value={i} sx={{ color: itemColor }}
                            label={itemTitle} {...a11yProps(i)} />
                    })}
                </Tabs>
            </Box>
            {
                progressesData?.data && Array.from(new Array(lessonsCount)).map((_, i) => {
                    var progress = progresses.filter(pr => Math.floor((pr.itemNumber - 1) / 4) == i)
                    var isActive = progress?.length > 0
                    var completeTrials = progress && progress.filter(itm => itm.isComplete)
                    var failedTrials = progress && progress.filter(itm => itm.isComplete && !itm.wasSuccessful)?.length
                    // @ts-ignore
                    var wasComplete = progress && ((completeTrials > 0) || progress.hasPassed)
                    var wasSuccessful = progress && progress.find(itm => itm.wasSuccessful)
                    var lessonId = i + 1

                    // @ts-ignore
                    // @ts-ignore
                    // @ts-ignore
                    const lastTrial = progress && completeTrials.sort((a, b) => (new Date(b.updated_at)) - (new Date(a.updated_at)))[0]
                    // @ts-ignore
                    const firstSuccessfulTrial = progress && completeTrials.filter(a => a.wasSuccessful).sort((a, b) => (new Date(a.updated_at)) - (new Date(b.updated_at)))[0];

                    const isPassed = completeTrials.length >= 4


                    function getDataRef(lesson: typeof LessonKeys["days"][number], ignore: boolean) {
                        if (student.grade <= 5 && !ignore) {
                            let questions: any = lesson.questions
                            if (lesson?.sentenceList) {
                                questions = [{
                                    text: lesson.sentenceList?.map(d => d.trim().replace(/[^\w\s]|_/g, "")).join(" "),
                                    type: "rio"
                                }, ...questions]
                            }
                            return questions
                        }
                        return lesson.questions
                    }

                    return (selectedLesson == (i)) && <Stack key={`progress_${i}`} sx={{ padding: 2, position: "relative", minHeight: 90, flex: 1 }} >
                        {
                            progress && <Card><Stack p={2} justifyContent="space-between" direction={"column"} spacing={1}>
                                <div className="flex items-center justify-between">
                                    <Typography variant="h6">
                                        <b>
                                            Lesson {lessonId}
                                        </b>
                                    </Typography>
                                    {
                                        userIden.userType === "admin" && (
                                            <div className="flex flex-col items-end gap-1" onClick={async () => {
                                                const { error } = await $fetch("admin/ctProgress/create", {
                                                    body: {
                                                        "userId": student.id,
                                                        itemNumber: (lessonId - 1) * 4 + 1
                                                    },
                                                    method: "POST",
                                                    // onSuccess(response) {
                                                    //     notification.open?.({
                                                    //         message: "Success",
                                                    //         description: "Lesson has been reset",
                                                    //         type: "success"
                                                    //     })
                                                    // },
                                                    // onError(error) {
                                                    //     notification.open?.({
                                                    //         message: "Error",
                                                    //         description: error.response.statusText,
                                                    //         type: "error"
                                                    //     })
                                                    // }
                                                })

                                                if (error) {
                                                    notification.open?.({
                                                        message: "Error",
                                                        description: error.statusText,
                                                        type: "error"
                                                    })
                                                } else {
                                                    notification.open?.({
                                                        message: "Success",
                                                        description: "Lesson has been reset",
                                                        type: "success"
                                                    })
                                                    invalidate({
                                                        resource: "student",
                                                        invalidates: ["all"],
                                                    });
                                                }


                                            }}>
                                                <Button variant="contained" size="small" color={
                                                    isActive ? "error" : "success"
                                                }>
                                                    {
                                                        isActive ? "Reset" : "Start"
                                                    } Progress
                                                </Button>
                                                {
                                                    isActive && <p className="text-xs text-red">
                                                        *This will reset all progress before and including this lesson.
                                                    </p>
                                                }

                                            </div>
                                        )
                                    }


                                </div>
                                <Stack justifyContent="space-between" alignItems={"center"} direction={"row"} spacing={1}>
                                    {
                                        progress && ((lastTrial && !firstSuccessfulTrial) || (firstSuccessfulTrial)) ? <>
                                            {
                                                lastTrial && !firstSuccessfulTrial &&
                                                <Badge sx={{
                                                    background: "#D01D24",
                                                    px: 1,
                                                    py: 0.1,
                                                    borderRadius: 10,
                                                    fontSize: 16,
                                                    color: "#fff"
                                                }}
                                                    color={"success"}><span style={{ textAlign: "center", verticalAlign: "center", display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}><ClearOutlined fontSize={"inherit"} />Failed <b>{moment(lastTrial.updated_at).format("MM/DD/YY")}</b></span></Badge>
                                            }
                                            {
                                                isPassed && firstSuccessfulTrial && <Badge sx={{
                                                    background: "#04A94D",
                                                    px: 1,
                                                    py: 0.1,
                                                    borderRadius: 10,
                                                    fontSize: 16,
                                                    color: "#fff"
                                                }}
                                                    color={"success"}><span style={{ textAlign: "center", verticalAlign: "center", display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}><CheckOutlined fontSize={"inherit"} />Passed <b>{moment(firstSuccessfulTrial.updated_at).format("MM/DD/YY")}</b></span></Badge>
                                            }
                                        </> : <>
                                            <Badge sx={{
                                                background: "gray",
                                                px: 1,
                                                py: 0.1,
                                                borderRadius: 10,
                                                fontSize: 16,
                                                color: "#000"
                                            }}
                                                color={"warning"}><span style={{ textAlign: "center", verticalAlign: "center" }}>In-Progress</span></Badge>
                                        </>
                                    }
                                </Stack>
                            </Stack>
                            </Card>
                        }
                        {
                            progress && <>
                                <Stack sx={{ mt: 2 }} spacing={1} justifyContent={"flex-start"}>
                                    {/*<UserInfoText>*/}
                                    {/*    <PercentOutlined />*/}
                                    {/*    <Typography variant="body1">Maximum Score <b>{(progress?.trial as any[]).sort((a, b)=>(a.percentage - b.percentage))[0]?.percentage}</b></Typography>*/}
                                    {/*</UserInfoText>*/}
                                    {
                                        [0, 1, 2, 3].map(itm => {
                                            var activityId = itm + 1
                                            var progressList = progress.filter(pr =>
                                                ((pr.itemNumber - 1) % 4) == itm
                                            )


                                            //get best score
                                            var bestScore = progressList && progressList.filter(act => act.isComplete).sort((a: { created_at: string; }, b: { created_at: string; }) => Date.parse(b.created_at) - Date.parse(a.created_at))[0]
                                            var bestPercentage = bestScore && (bestScore.actualScore / bestScore.expectedScore)
                                            var wasSuccessful = bestScore && bestScore.wasSuccessful
                                            if (activityId === 1 || (activityId === 3 && student.grade > 5)) {

                                                return <div style={{ background: "rgba(155,155,155,0.14)", border: "1px solid #000", padding: 15, display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                                                    <Typography variant={"h6"}><b>Activity {activityId}:
                                                        {activityId === 1 && " Vocabulary Builder"}
                                                        {activityId === 3 && " Critical Thinking & Comprehension"}
                                                    </b></Typography>
                                                    <div>
                                                        {
                                                            bestPercentage ? <div style={{ padding: "5px 10px", borderRadius: 10, background: wasSuccessful ? "#04A94D" : "#D01D24", color: "#fff" }}>
                                                                <b style={{ fontSize: 20 }}>{Math.floor(bestPercentage * 100)}%</b>
                                                            </div> : <div />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            return <div style={{ background: "rgba(155,155,155,0.14)", border: "1px solid #000", padding: 15, display: "flex", flexDirection: "column", gap: 10 }}>
                                                <Typography variant={"h6"}><b>Activity {activityId}:
                                                    {activityId == 1 && " Vocabulary Builder"}
                                                    {activityId == 2 && " Vocabulary Review"}
                                                    {activityId == 3 && " Critical Thinking & Comprehension"}
                                                    {activityId == 4 && " Closing Practice"}
                                                </b></Typography>
                                                {
                                                    progressList.sort((a: { created_at: string; }, b: { created_at: string; }) => Date.parse(a.created_at) - Date.parse(b.created_at))
                                                        .map((trial, i) => {
                                                            // @ts-ignore
                                                            const isExpanded = expanded[trial.id]
                                                            return <ListingGroup activityId={activityId} lessonId={trial.itemNumber}
                                                                isScorable={true}
                                                                key={trial.id}
                                                                {...{
                                                                    setExpandeds,
                                                                    id: trial.id,
                                                                    logs: trial.logs,
                                                                    title: `#${i + 1}`,
                                                                    dataRef: getDataRef(
                                                                        LessonKeys.days[trial.itemNumber - 1], false
                                                                    ),
                                                                    isExpanded,
                                                                    userIden,
                                                                    setPlayState,
                                                                    playState,
                                                                    created_at: trial.created_at,
                                                                    isComplete: trial.isComplete,
                                                                    wasSuccessful: trial.wasSuccessful,
                                                                    percentage: trial.actualScore / trial.expectedScore
                                                                }} />
                                                        })
                                                }
                                            </div>
                                        })
                                    }



                                </Stack>
                            </>
                        }
                        {
                            isActive && progress && (progress?.length < 1) && <Stack justifyContent={"center"} alignItems={"center"} sx={{ alignSelf: "stretch", flex: 1 }}>

                                <Typography variant={"h4"} sx={{ verticalAlign: "center", textAlign: "center", fontSize: 30 }}> <PlayLessonOutlined fontSize={"inherit"} />
                                    Lesson {lessonId}
                                </Typography>
                                <span>No data yet, check back later.</span>
                            </Stack>
                        }
                    </Stack>
                })
            }
        </Box >
    </>
}



// @ts-ignore
function ListingGroup({ setExpandeds, activityId, showPlay = true, logs, isExpanded, dataRef, lessonId, setPlayState, playState, isScorable, id, title, created_at, isComplete, wasSuccessful, percentage }) {
    // @ts-ignore
    return <Card sx={{ ...(isExpanded ? {} : { maxHeight: 200 }), padding: 1, position: "relative", marginTop: 0.4, marginBottom: 1 }}>
        <Stack justifyContent="space-between" alignItems={"center"} direction={"row"} spacing={1}>
            {/*{*/}
            {/*    trial?.isComplete ?*/}

            {/*        <Typography variant="subtitle1">#{i+1} (<b>{moment(trial.created_at).format("MM/DD/YY")}</b>)*/}
            {/*            <Badge sx={{px: 1, py: 0.1, ml: 2, borderRadius: 10, background:trial.wasSuccessful ?  "#04A94D" : "#D01D24", color:"#fff"}} color={"error"}>{trial.wasSuccessful ? "Passed" : "Failed"}</Badge></Typography>*/}


            {/*        : */}

            {/*}*/}
            <Typography variant="subtitle1" style={{ textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}><Typography style={{ fontSize: 24, fontWeight: "bold" }} variant={"h4"}>{title}</Typography> <Badge sx={{
                px: 1,
                py: 0.1,
                borderRadius: 10,
                fontSize: 16,
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 0.5
            }} ><HistoryOutlined fontSize={"inherit"} /> <span>{moment(created_at).fromNow()}</span></Badge></Typography>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                {
                    <>
                        {!isExpanded ? <a style={{ color: "#000", fontSize: 16, marginRight: 10 }} href={"javascript:void(0);"} onClick={() => {
                            setExpandeds((exp: any) => ({ ...exp, [id]: true }))
                        }
                        }>Show more</a> : <a style={{ color: "#000", fontSize: 16, marginRight: 10 }} href={"javascript:void(0);"} onClick={() => {
                            setExpandeds((exp: any) => ({ ...exp, [id]: false }))
                        }
                        }>Show less</a>}</>
                }
                {
                    isComplete ?
                        isScorable ?
                            <div style={{ padding: "5px 10px", borderRadius: 10, background: wasSuccessful ? "#04A94D" : "#D01D24", color: "#fff" }}>
                                <b style={{ fontSize: 20 }}>{Math.floor(percentage * 100)}%</b>
                            </div>
                            : <Badge sx={{ px: 1, py: 0.1, ml: 2, borderRadius: 10, background: "#04A94D", color: "#fff" }} color={"success"}>Complete</Badge>


                        : <Badge sx={{ px: 1, py: 0.1, ml: 2, borderRadius: 10, background: "#A3A3A3", color: "#000" }} color={"error"}>In-Progress</Badge>
                }
            </div>
        </Stack>
        {
            !(logs.length > 0) &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 20px" }}>
                <span>Not practiced yet</span>
            </div>
        }
        <div style={{ display: "flex", flexDirection: "column" }}>

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: 10, marginTop: 10 }}>
                {
                    logs
                        .sort((a: { created_at: string; }, b: { created_at: string; }) => Date.parse(a.created_at) - Date.parse(b.created_at))
                        .map((log: IActivityLog, index: number) => {
                            var logData = dataRef[log.itemIndex.toString()]
                            if (!logData) {
                                return null
                            }
                            return <>
                                {/*{*/}
                                {/*    JSON.stringify(logData)*/}
                                {/*}*/}
                                {
                                    logData.type == "rio" && <SentenceItem sentenceIndex={log.itemIndex} isPlaying={playState[log.id]} toogleAudio={() => {
                                        // @ts-ignore
                                        var audioElem: HTMLAudioElement = document.getElementById(`audio_${log.id}`)!!
                                        var nPs = { ...playState };
                                        if (playState[log.id]) {
                                            audioElem.pause()
                                            delete nPs[log.id]
                                        } else {
                                            audioElem.play()
                                            nPs[log.id] = true;
                                        }
                                        setPlayState(nPs)
                                    }} log={log} sentence={logData.text} shouldShowLevel={false} />
                                }
                                {
                                    log.format == "word" && <WordItem
                                        showPlay={showPlay}
                                        lessonId={lessonId} isPlaying={playState[log.id]} toogleAudio={() => {
                                            // @ts-ignore
                                            var audioElem: HTMLAudioElement = document.getElementById(`audio_${log.id}`)!!
                                            var nPs = { ...playState };
                                            if (playState[log.id]) {
                                                audioElem.pause()
                                                delete nPs[log.id]
                                            } else {
                                                audioElem.play()
                                                nPs[log.id] = true;
                                            }
                                            setPlayState(nPs)
                                        }} log={log} word={logData.text} />
                                }

                                {
                                    log.format == "text" && <TextItem lessonId={lessonId} isPlaying={playState[log.id]} toogleAudio={() => {
                                        // @ts-ignore
                                        var audioElem: HTMLAudioElement = document.getElementById(`audioText_${log.id}`)!!
                                        var nPs = { ...playState };
                                        if (playState[log.id]) {
                                            audioElem.pause()
                                            delete nPs[log.id]
                                        } else {
                                            audioElem.play()
                                            nPs[log.id] = true;
                                        }
                                        setPlayState(nPs)
                                    }} log={log} dataRef={logData} />
                                }
                                {/*{*/}
                                {/*    log.format == "text" && <audio onEnded={()=>{*/}
                                {/*        var nPs = {...playState};*/}
                                {/*        delete nPs[log.id]*/}
                                {/*        setPlayState(nPs)*/}

                                {/*    }*/}
                                {/*    } hidden={true} id={`audioText_${log.id}`} src={`https://read21-assets.s3.amazonaws.com/audios/lesson/${lessonId}/answers/${log.textId.toLowerCase()}.mp3`} preload="none"/>*/}
                                {/*}*/}
                                {
                                    log.audioId && <audio onEnded={() => {
                                        var nPs = { ...playState };
                                        delete nPs[log.id]
                                        setPlayState(nPs)

                                    }
                                    } hidden={true} id={`audio_${log.id}`} src={`https://asr-record.s3.amazonaws.com/${log.audioId}`} preload="none" />
                                }

                            </>
                        })
                }
            </div>

        </div>



    </Card>
}