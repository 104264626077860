import {
  HttpError,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import {
  Avatar,
  Button,
  CreateButton,
  DataGrid,
  DeleteButton,
  Grid,
  GridColumns,
  List,
  Paper,
  Stack,
  Typography,
  useDataGrid,
} from "@pankod/refine-mui";
import React from "react";

import {
  CalendarMonthOutlined,
  EditOutlined,
  SchoolOutlined,
} from "@mui/icons-material";

import { DurationDate } from "components";
import { IPrincipal, ITeacher } from "interfaces";

const UserInfoText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent={{
      sm: "center",
      lg: "flex-start",
    }}
    gap={1}
  >
    {children}
  </Stack>
);

export const PrincipalShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show, edit, push, createUrl } = useNavigation();
  const { data: canEditPrincipal } = useCan({
    resource: "principal",
    action: "edit",
  });
  const { mutate } = useDelete();
  const { queryResult, showId } = useShow<IPrincipal>();
  const principal = queryResult.data?.data;

  const { dataGridProps } = useDataGrid<ITeacher, HttpError, ITeacher>({
    resource: "teacher",
    permanentFilter: [
      {
        field: "school",
        operator: "eq",
        value: principal?.principality?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: principal !== undefined,
    },
    syncWithLocation: false,
  });

  const columns = React.useMemo<GridColumns<ITeacher>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return (
            <>
              <Avatar src={principal?.photoURL} alt={principal?.firstName} />
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.firstName} {principal?.lastName}
              </Typography>
            </>
          );
        },
      },
      {
        field: "users[count]",
        headerName: "# Students",
        minWidth: 140,
        valueGetter(v) {
          // @ts-ignore
          return v.row?._count?.teacherOf;
        },
      },
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        minWidth: 200,
        renderCell(params) {
          const created_at = params.row.created_at;
          return (
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              <DurationDate date={created_at} />
            </Typography>
          );
        },
      },
    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Paper sx={{ p: 2, paddingX: { xs: 4, md: 2 } }}>
          <Stack alignItems="center" spacing={1}>
            <Avatar
              src={principal?.photoURL}
              sx={{ width: 120, height: 120 }}
            />
            <Typography variant="h6">
              {principal?.firstName} {principal?.lastName}
            </Typography>
          </Stack>
          <br />
          <Stack spacing={1}>
            <UserInfoText>
              <SchoolOutlined />

              <a
                href="#"
                onClick={() => {
                  show("school", principal?.principality?.id!);
                }}
                style={{
                  display: "flex",
                  color: "#000",
                  textDecorationLine: "underline",
                  flexDirection: "row",
                  gap: 5,
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">
                  {principal?.principality?.name}
                </Typography>
              </a>
            </UserInfoText>
            <UserInfoText>
              <CalendarMonthOutlined />
              <Typography variant="body1">
                <DurationDate date={principal?.created_at} />
              </Typography>
            </UserInfoText>
          </Stack>
          <br />
          <Stack
            sx={{ mt: 2 }}
            spacing={1}
            direction={"row"}
            justifyContent="space-between"
          >
            {canEditPrincipal?.can && (
              <Button
                startIcon={<EditOutlined />}
                onClick={() => {
                  edit("user", showId!);
                }}
              >
                Edit
              </Button>
            )}
            <DeleteButton
              onSuccess={() => {
                show("school", principal?.principality?.id!);
              }}
              recordItemId={showId}
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Stack direction="column" spacing={2}>
          <List
            resource="teacher"
            cardHeaderProps={{
              title: "Teachers",
              action: (
                <CreateButton
                  onClick={() => {
                    push(
                      createUrl("teacher") +
                        `?school=${principal?.principality?.id}`
                    );
                  }}
                />
              ),
            }}
            cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
          >
            <DataGrid
              {...dataGridProps}
              columns={columns}
              autoHeight
              onCellClick={(cell) => {
                show("teacher", cell.row.id);
              }}
              rowsPerPageOptions={[4, 10, 20, 100]}
            />
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};
