import React from "react";
import axios from "axios";
import { useTranslate, useApiUrl, HttpError } from "@pankod/refine-core";
import {
    Controller,
    UseModalFormReturnType,
} from "@pankod/refine-react-hook-form";
import {
    Drawer,
    FormControlLabel,
    Input,
    Radio,
    RadioGroup,
    Avatar,
    Typography,
    FormLabel,
    Stack,
    Box,
    IconButton,
    FormControl,
    Autocomplete,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    Create,
    useAutocomplete,
    TextField, MenuItem, Select
} from "@pankod/refine-mui";
import { CloseOutlined } from "@mui/icons-material";

import { ISchool, ILicenseBulk } from "interfaces";
import moment from "moment";
import { useEffect } from "react";

export const CreateLicenseBulk: React.FC<
    UseModalFormReturnType<ILicenseBulk, HttpError, ILicenseBulk> & any
> = ({
    watch,
    setValue,
    register,
    formState: { errors },
    control,
    refineCore: { onFinish },
    handleSubmit,
    modal: { visible, close },
    saveButtonProps,
    schoolInfo
}) => {
        const apiUrl = useApiUrl();

        const { autocompleteProps } = useAutocomplete<ISchool>({
            resource: "school"
        });
        useEffect(() => {
            if (schoolInfo) {
                setValue("school", {
                    id: schoolInfo?.id,
                })
            }
        }, [schoolInfo])

        const expiryType = watch("expiryType", "1year")

        return (
            <Drawer
                sx={{ zIndex: "1301" }}
                PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
                open={visible}
                onClose={close}
                anchor="right"
                title="Create License"
            >
                <Create
                    saveButtonProps={saveButtonProps}
                    resource={"license"}
                    cardHeaderProps={{
                        avatar: (
                            <IconButton
                                onClick={() => close()}
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    mb: "5px",
                                }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        ),
                        action: null,
                        resource: "license"
                    }}
                    cardProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
                >
                    <Stack>
                        <Box
                            paddingX="50px"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                paddingX: {
                                    xs: 1,
                                    md: 6,
                                },
                            }}
                        >
                            <form onSubmit={handleSubmit(onFinish)}>
                                <Stack gap="10px" marginTop="10px">
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="school"
                                            rules={{
                                                required: "School Required!",
                                            }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    {...autocompleteProps}
                                                    {...field}
                                                    onChange={(_, value) => {
                                                        field.onChange({
                                                            id: value.id,
                                                        });
                                                    }}
                                                    value={field.value || null}
                                                    getOptionLabel={(item) => {
                                                        return item.name
                                                            ? item.name
                                                            : "";
                                                    }}
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value,
                                                    ) =>
                                                        value === undefined ||
                                                        option.id === value.id
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="School"
                                                            variant="outlined"
                                                            error={
                                                                !!errors.school?.message
                                                            }
                                                            required
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        {errors.school && (
                                            <FormHelperText error>
                                                {errors.school?.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            License Type
                                        </FormLabel>
                                        <Select
                                            id="licenseType"
                                            {...register("licenseType", {
                                                required: "License Type is required",
                                            })}
                                            // value={expiryType || null}
                                            style={{ height: "40px" }}
                                        >
                                            <MenuItem value={"fl"}>Fluency</MenuItem>
                                            <MenuItem value={"ct"}>Critical Thinking</MenuItem>
                                            <MenuItem value={"full"}>
                                                Full
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText >
                                            The type of license to be purchased.
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            # of Licenses
                                        </FormLabel>
                                        <OutlinedInput
                                            id="amount"
                                            {...register("amount", {
                                                required: "Amount of licenses required!",
                                                valueAsNumber: true
                                            })}
                                            type={"number"}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            style={{ height: "40px" }}
                                        />
                                        {errors.amount && (
                                            <FormHelperText error>
                                                {errors.amount.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            Expires After
                                        </FormLabel>
                                        <Select
                                            id="expiryType"
                                            {...register("expiryType", {
                                                required: "Status is required",
                                            })}
                                            value={expiryType || null}
                                            style={{ height: "40px" }}
                                        >
                                            <MenuItem value={"1week"}>1 Week</MenuItem>
                                            <MenuItem value={"2week"}>2 Weeks</MenuItem>
                                            <MenuItem value={"1month"}>1 Month</MenuItem>
                                            <MenuItem value={"2month"}>2 Months</MenuItem>
                                            <MenuItem value={"3month"}>3 Months</MenuItem>
                                            <MenuItem value={"6month"}>6 Months</MenuItem>
                                            <MenuItem value={"1year"}>1 Year</MenuItem>
                                        </Select>
                                        <FormHelperText >
                                            The purchased licenses will expire within the period set.
                                        </FormHelperText>
                                    </FormControl>


                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Create>
            </Drawer>
        );
    };