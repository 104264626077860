import { AuthProvider } from "@pankod/refine-core";
import axios, { AxiosRequestConfig } from "axios";
import Cookies from 'js-cookie';
import { createAxios } from "utils/customAxios";

const authRest = createAxios(`${process.env.REACT_APP_BACKEND}`);
authRest.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: AxiosRequestConfig) => {
        // Retrieve the token from local storage
        const token = localStorage.getItem("token");
        // Check if the header property exists
        if (request.headers) {
            // Set the Authorization header if it exists
            request.headers[
                "Authorization"
            ] = `Bearer ${token}`;
        } else {
            // Create the headers property if it does not exist
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        return request;
    },
);
export const authProvider: AuthProvider = {
    login: async ({ email, password }) => {
        let result;
        try {
            result = await authRest.post("/auth/staff", {email, password}, {validateStatus(status){
                return (status==200)||(status==401)
            }});
        } catch (error) {
            throw new Error("Something went wrong!")
        }
        if(result.status == 401){
            throw new Error("User doesn't exist!");
        }
        localStorage.setItem("token",result.data.token!!)
        return Promise.resolve();
    },
    logout: async () => {
        localStorage.removeItem("token")
    },
    checkError: (error) => {
        if (error.status == 401) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem("token")!!.length > 0 ? Promise.resolve() : Promise.reject();
    },
    getPermissions: (...params) => {
        return Promise.resolve()
    },
    getUserIdentity: async () => {
        var token = localStorage.getItem("token")!!;
        if (!(token?.length > 0)) {
            return Promise.reject();
        }
        const user = await authRest.get("/user/me");
        if(user.status==200){
            return Promise.resolve(user.data.user);
        }else{
            return Promise.reject();
        }
        
    },
};