import React, { useEffect, useRef, useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useShow,
  useList,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Grid,
  Card,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Lessons } from "../../../const/lessons";
import { IPrincipal, ISchool, IStudent, ITeacher } from "interfaces";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import {
  ChartJSOrUndefined,
  TypedChartComponent,
} from "react-chartjs-2/dist/types";
import { ExportAs } from "components/export";
import { StudentType } from "utils/type";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// {
//     <Grid item xs={2} sm={4} md={4} key={index}>
//         <ReportCard index={index} onClick={(sc)=>{
//             push(`${showUrl("lessonReport", index+1)}?school=${sc?.id}`)
//         }}/>
//     </Grid>
// ))}
//['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function genColor(seed: number) {
  return `hsl(${(seed * 72) % 360}, 95%, 35%)`;
}

var clsList: { [key: string]: string } = {
  first: "First",
  second: "Second",
  third: "Third",
};
export const StudentReportShow: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const {
    queryResult: { data: studentData },
    showId,
  } = useShow();
  const chart = useRef<ChartJSOrUndefined<"bar">>();
  const [student, setStudent] = useState<StudentType | null>(null);
  useEffect(() => {
    setStudent(studentData?.data?.student as StudentType);
  }, [studentData?.data]);
  const { autocompleteProps } = useAutocomplete<StudentType>({
    resource: "student",
  });
  const trails = studentData?.data?.trials as
    | {
        first: { lessonId: number; percentage: number }[];
        second: { lessonId: number; percentage: number }[];
        third: { lessonId: number; percentage: number }[];
      }
    | undefined;

  function getHighest(lessonId: number) {
    let highest = 0;
    if (trails) {
      if (trails.first) {
        const trial = trails.first.find((k) => k.lessonId === lessonId);
        if (trial) {
          highest = Math.max(highest, trial.percentage);
        }
      }
      if (trails.second) {
        const trial = trails.second.find((k) => k.lessonId === lessonId);
        if (trial) {
          highest = Math.max(highest, trial.percentage);
        }
      }
      if (trails.third) {
        const trial = trails.third.find((k) => k.lessonId === lessonId);
        if (trial) {
          highest = Math.max(highest, trial.percentage);
        }
      }
    }
    const res = highest * 100;
    return res > 0 ? `${res.toFixed(0)}%` : "🔒";
  }

  const labels = Array.from(Array(21)).map(
    (_, index) => `Lesson ${index + 1} (${getHighest(index + 1)})`
  );

  function getPreTestLabel(pretest?: {
    pretest1: number;
    pretest2: number;
    pretest3: number;
    pretest4: number;
    pretest5: number;
    pretest6: number;
    pretest7: number;
    wcpm: number | null;
    level6: number | null;
  }) {
    if (!pretest) {
      return "No Data";
    }
    let label = "";
    if (pretest.pretest1 < 0.8) {
      label = "Beginning Reader";
    } else if (
      [
        pretest.pretest2,
        pretest.pretest3,
        pretest.pretest4,
        pretest.pretest5,
      ].some((k) => k < 0.8)
    ) {
      label = "Some Gaps";
    } else if (pretest.pretest6 < 0.8) {
      label = "Grade level 1";
    } else if (pretest.pretest7 < 0.8) {
      label = "Grade level 2+";
    } else {
      label = `Grade level 3+ ${pretest.wcpm ? `(${pretest.wcpm} wcpm)` : ""} `;
    }
    return label;
  }
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  return (
    <Grid
      container
      padding={4}
      gap={5}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Card
        style={{
          padding: 20,
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
          Individual Progress Reports
        </Typography>
        <Autocomplete
          size="small"
          {...autocompleteProps}
          value={student}
          onChange={(_, value) => {
            setStudent(value);
          }}
          getOptionLabel={(item) => {
            return `${item.firstName} ${item.lastName} (${
              item?.name ?? (item as any).school?.name
            })`;
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || option.id === value.id
          }
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" required />
          )}
        />
        <div style={{ alignSelf: "flex-end" }}>
          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              if (student?.id) {
                push(`${showUrl("student", student?.id)} `);
              }
            }}
          >
            Open
          </Button>
          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              if (student?.id) {
                push(`${showUrl("studentReport", student?.id)} `);
              }
            }}
          >
            Generate
          </Button>
        </div>
      </Card>
      <Card sx={{ flex: 1, padding: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant={"h5"}
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {student?.firstName} {student?.lastName}
          </Typography>
          <ExportAs
            id="graph"
            multiplePages
            pageCount={2}
            getExportName={() => {
              const name = `${student?.firstName} ${student?.lastName} Report`;
              return name;
            }}
            centerRow
            getRows={() => {
              const rows: (string | number)[][] = [
                ["Lesson", "First", "Second", "Third"],
              ];
              for (let i = 1; i <= 20; i++) {
                const row: (string | number)[] = [`L ${i} `];
                if (trails?.first) {
                  const trial = trails.first.find((k) => k.lessonId === i);
                  if (trial) {
                    row.push(`${(trial.percentage * 100).toFixed(0)}% `);
                  } else {
                    row.push("🔒");
                  }
                } else {
                  row.push("🔒");
                }
                if (trails?.second) {
                  const trial = trails.second.find((k) => k.lessonId === i);
                  if (trial) {
                    row.push(`${(trial.percentage * 100).toFixed(0)}% `);
                  } else {
                    row.push("🔒");
                  }
                } else {
                  row.push("🔒");
                }
                if (trails?.third) {
                  const trial = trails.third.find((k) => k.lessonId === i);
                  if (trial) {
                    row.push(`${(trial.percentage * 100).toFixed(0)}% `);
                  } else {
                    row.push("🔒");
                  }
                } else {
                  row.push("🔒");
                }
                rows.push(row);
              }
              return rows;
            }}
          />
        </Stack>
        <div
          id="graph"
          style={{
            overflow: "visible",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {` ${student?.firstName ?? ""} ${student?.lastName ?? ""}`} Lesson
            Report
          </p>
          {studentData?.data.pretestData?.pretest1 && (
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                gap: 10,
                alignItems: "center",
                color: "#000",
                backgroundColor: "rgb(1,174,72, 0.05)",
                paddingLeft: 10,
                marginTop: 10,
                borderLeft: "4px solid #01ae48",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M17 21q-1.671 0-2.836-1.164T13 17q0-1.671 1.164-2.836T17 13q1.671 0 2.836 1.164T21 17q0 1.671-1.164 2.836T17 21m.2-3.673l1.929-1.91l-.546-.546l-1.91 1.929zM3 11.5V6.615q0-.69.463-1.152Q3.925 5 4.615 5h14.77q.69 0 1.152.463q.463.462.463 1.152v5.427q-.846-.698-1.874-1.062q-1.028-.365-2.126-.365q-1.008 0-1.909.286q-.9.286-1.676.826L11.438 7.78q-.067-.116-.182-.173Q11.14 7.55 11 7.55t-.265.058q-.125.057-.193.173L7 14.89l-1.567-3.11q-.068-.135-.18-.208T5 11.5zM4.615 19q-.69 0-1.152-.462Q3 18.075 3 17.385V12.5h1.683l1.86 3.738q.067.14.18.201T7 16.5q.14 0 .253-.06t.18-.202l3.548-7.103l1.623 3.246q-.92.873-1.454 2.062q-.535 1.19-.535 2.557q0 .506.076 1.009q.076.503.253.991z"
                />
              </svg>
              <p>Pre Test: {getPreTestLabel(studentData?.data.pretestData)}</p>
            </div>
          )}
          <Chart
            type={"bar"}
            ref={chart}
            height={screenSize > 1500 ? 250 : screenSize > 1400 ? 300 : 350}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                title: {
                  display: false,
                  text: "Student Lesson Report",
                },
              },
              indexAxis: "y",
              scales: {
                x: {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                      return value + "%";
                    },
                  },
                  max: 100,
                  min: 0,
                },
              },
            }}
            onClick={function (e) {
              var xLabel =
                chart.current!!.scales["y"].getValueForPixel(
                  e.nativeEvent.offsetY
                )!! + 1;
              // const elements = chart.current.getElementsAtEventForMode(e, "x", 1)
              push(
                showUrl("lessonReport", xLabel) +
                  `? school = ${student?.schoolId} `
              );
            }}
            // plugins={[
            //   {
            //     id: "custom_canvas_background_color",
            //     afterDraw: (chart) => {
            //       const ctx = chart.ctx;
            //       ctx.save();

            //       Array.from(Array(21)).forEach((_, index) => {
            //         const meta = chart.getDatasetMeta(0);
            //         const element = meta.data[index];
            //         const { x, y, height } = element.getProps(
            //           ["x", "y", "height"],
            //           true
            //         );
            //         ctx.textAlign = "left";
            //         ctx.textBaseline = "bottom";
            //         const text = "⭐️⭐️⭐️⭐️⭐️⭐️";
            //         const yPos = y + (height / 2 + 5); // this should place the text in the middle of the bar
            //         ctx.fillText(text, chart.width / 2, yPos);
            //       });

            //       ctx.restore();
            //     },
            //   },
            // ]}
            data={{
              labels,
              datasets: Object.entries(studentData?.data?.trials || {}).map(
                (curr, index: number) => {
                  // @ts-ignore
                  return {
                    label: clsList[curr[0] as string],
                    data: Array.from(Array(21))
                      .map((_, i) =>
                        (curr[1] as any[]).find((k) => k.lessonId === i + 1)
                      )
                      .map((trial: any) =>
                        trial ? trial?.percentage * 100 : null
                      ),
                    backgroundColor: genColor((index + 1) * 2),
                    //grouped: true,
                    barThickness: 10,
                  };
                }
              ),
            }}
          />
          {studentData?.data.pretestData.level6 ? (
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                gap: 10,
                alignItems: "center",
                color: "black",
                backgroundColor: "rgba(255,215,0,0.2)",
                paddingLeft: 10,
                borderLeft: "4px solid #fe8800",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 25c-11.594 0-23 12.8-23 31s11.406 31 23 31s23-12.8 23-31s-11.406-31-23-31m-103.951 2.975l-16.098 8.05c15.092 30.185 51.37 56.81 82.188 74.442L232.334 295H247V192h18v103h14.666l14.195-184.533c30.818-17.632 67.096-44.257 82.188-74.442l-16.098-8.05c-19.91 29.9-44.891 49.148-71.334 57.77C281.311 97.28 269.75 105 256 105c-13.75 0-25.31-7.72-32.617-19.256c-26.443-8.62-51.424-27.87-71.334-57.77zM169 313v96H25v78h462v-30H343V313z"
                />
              </svg>
              <p>
                Grade Level: {studentData.data.pretestData.level6} (wcpm:{" "}
                {studentData?.data.pretestData.wcpmL6})
              </p>
            </div>
          ) : null}
        </div>
      </Card>
    </Grid>
  );
};
