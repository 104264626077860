import React, {useCallback, useMemo, useState} from "react";
import {
    Avatar,
    DataGrid,
    DateField,
    Grid,
    GridColumns,
    List,
    NumberField,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useDataGrid,
    Button,
    DeleteButton,
    CreateButton,
    Card,
    Badge,
    FormLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    Autocomplete,
    TextField,
    useAutocomplete
} from "@pankod/refine-mui";
import {
    HttpError,
    IResourceComponentsProps,
    useApiUrl, useCan,
    useDataProvider,
    useDelete, useGetIdentity,
    useInvalidate,
    useNavigation,
    useNotification,
    useShow,
    useTranslate,
} from "@pankod/refine-core";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import {
    StoreOutlined,
    EditOutlined,
    Groups2,
    MilitaryTech,
    DeleteOutlined,
    SchoolOutlined,
    CalendarMonthOutlined,
    PercentOutlined, LockOutlined, LockOpenOutlined, CircleOutlined, Circle
} from "@mui/icons-material";


import {IPrincipal, ITeacher, IStudent} from "interfaces";
import moment from "moment";
import {DurationDate} from "components";
import {useConfirm} from 'material-ui-confirm';
import {createAxios} from "utils/customAxios";
import {Lessons} from "../../const/lessons";
import {Controller} from "@pankod/refine-react-hook-form";

const UserInfoText: React.FC<{ children: React.ReactNode }> = ({
                                                                   children,
                                                               }) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent={{
            sm: "center",
            lg: "flex-start",
        }}
        gap={1}
    >
        {children}
    </Stack>
);

export const LessonManageShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const apiUrl = process.env.REACT_APP_BACKEND;
    const confirm = useConfirm();
    const {data: canShowTeacher} = useCan({action:"list", resource:"teacher"})
    const dataProvider = useDataProvider()
    const {show, edit, push, createUrl} = useNavigation();
    const {mutate} = useDelete();
    const notification = useNotification();
    const invalidate = useInvalidate();
    const [selectionType, setSelectionType]  = useState<any>("class")

    const [selectedTeacher, setSelectedTeacher] = useState<any>(null)
    const [selectedStudents, setSelectedStudents] = useState<any>([])
    const [lockStates, setLockStates] = useState<any>({})

    const lessonLockAction = useCallback(() => {
        confirm({description: 'Are you sure?'})
            .then(() => {
                // @ts-ignore
                return dataProvider()!!.custom!!({
                    method: "post",
                    url: `${apiUrl}/admin/student/batchUpdate`,
                    payload: {
                        lockStates,
                        selectionType,
                        updateOption: {
                            selectedTeacher: selectedTeacher? selectedTeacher.id : null,
                            selectedStudents: selectedStudents ? (selectedStudents || [])?.map((sls: any)=>sls?.id) : null
                        }
                    }
                })
                    .then(result => {
                        notification.open!!({
                            type: "success",
                            message: "Lesson states successfully updated!"
                        });
                        invalidate({
                            resource: "student",
                            invalidates: ["all"]
                        });

                    })
                    .catch(err => {
                        notification.open!!({
                            type: "error",
                            message: "Something went wrong!"
                        });
                    });

            })
            .catch(console.error);

    }, [selectionType, selectedTeacher, selectedStudents, lockStates])
    const { autocompleteProps: teacherAutoComplete } = useAutocomplete<ITeacher>({
        resource: "teacher"
    });
    const { autocompleteProps: studentAutoComplete } = useAutocomplete<IStudent>({
        resource: "student"
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
                <Paper sx={{p: 2, paddingX: {xs: 4, md: 2}}} >
                    <Stack gap={2}>
                        <FormControl fullWidth>
                            <FormLabel >
                                Selection Type
                            </FormLabel>
                            <Select
                                id="expiryType"
                                onChange={(v)=>{setSelectionType(v?.target?.value)}}
                                value={selectionType}
                                style={{ height: "40px" }}
                            >
                                <MenuItem value={"class"}>Full Class</MenuItem>
                                <MenuItem value={"individual"}>Individual Student</MenuItem>
                            </Select>

                        </FormControl>
                        {
                            ((selectionType == "class") && canShowTeacher?.can) && <>
                                <FormControl fullWidth>
                                    <FormLabel

                                        sx={{
                                            marginBottom: "8px",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            color: "text.primary",
                                        }}
                                    >
                                        Teacher
                                    </FormLabel>
                                    <Autocomplete
                                        size="small"
                                        {...teacherAutoComplete}
                                        value={selectedTeacher||null}
                                        onChange={(_, value) => {
                                            setSelectedTeacher(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return item.firstName + " " + item.lastName;
                                        }}
                                        isOptionEqualToValue={(
                                            option,
                                            value,
                                        ) =>
                                            value === undefined ||
                                            option.id === value.id
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </FormControl>
                            </>
                        }
                        {
                            (selectionType == "individual") && <>

                                <FormControl fullWidth>
                                    <FormLabel
                                        required
                                        sx={{
                                            marginBottom: "8px",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            color: "text.primary",
                                        }}
                                    >
                                        Students
                                    </FormLabel>
                                    <Autocomplete
                                        size="small"
                                        {...studentAutoComplete}
                                        value={selectedStudents}
                                        multiple={true}
                                        onChange={(_, value) => {
                                            setSelectedStudents(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return item.firstName + " " + item.lastName;
                                        }}
                                        isOptionEqualToValue={(
                                            option,
                                            value,
                                        ) =>
                                            value === undefined ||
                                            option.id === value.id
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                    <FormHelperText >
                                        Select students
                                    </FormHelperText>
                                </FormControl>
                            </>
                        }
                        <Button variant={"contained"} fullWidth={true} onClick={()=>{lessonLockAction()}}>Save Changes</Button>
                    </Stack>

                </Paper>
            </Grid>
            <Grid item xs={12} lg={9}>
                <Paper sx={{p: 2, paddingX: {xs: 4, md: 2}}}>
                    <table id={"newExport"} className={"report-table-nowidth"} style={{flex:1}}>
                        <thead>
                        <th>Manage Lessons</th>
                        <th>Lock/Unlock</th>
                        </thead>
                        <tbody>
                        {
                            Object.keys(Lessons).filter(lid=>Number.parseInt(lid)>0).map(lId=>{
                                // @ts-ignore
                                var lessonData = Lessons[lId]
                                return <tr key={lId}>
                                    <td >
                                        <b>Lesson {lId}</b>: {lessonData.title}
                                    </td>
                                    <td onClick={()=>{
                                        var newLockedStates = {...lockStates}
                                        if(newLockedStates[lId] == "lock"){
                                            newLockedStates[lId] = "unlock"
                                        } else if(newLockedStates[lId] == "unlock") {
                                            delete newLockedStates[lId]
                                        } else {
                                            newLockedStates[lId] = "lock";
                                        }
                                        setLockStates(newLockedStates)
                                    }} style={{display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer", color: (lockStates[lId] == "lock") ? "red" : ((lockStates[lId] == "unlock") ? "green" : "gray")}}>
                                        {
                                            (lockStates[lId] == "lock") ? <>
                                                <LockOutlined/>
                                                <span style={{textDecorationLine:"underline", fontWeight:"bold"}}>Locked</span>

                                            </> : ( (lockStates[lId] == "unlock") ?<>
                                                <LockOpenOutlined/>
                                                <span style={{textDecorationLine:"underline", fontWeight:"bold"}}>Not Locked</span>
                                            </>: <>
                                                <Circle color={"disabled"}/>
                                            </>)
                                        }

                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </Paper>
            </Grid>
        </Grid>
    );
};