/** @format */

import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Chip,
  Modal,
  Stack,
  Typography,
  useDataGrid,
} from "@pankod/refine-mui";
import { useGetIdentity, useList } from "@pankod/refine-core";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { createAxios } from "../utils/customAxios";
import { INotification, IStudent, IUser } from "../interfaces";
import moment from "moment";
import { DashboardPageProps } from "@pankod/refine-core/dist/interfaces";
import { PrincipalDashboard } from "components/principal-dashboard";
import { makeRandom } from "../utils/string";
import { SysDashboard } from "components/sys-admin-dashbaord";
import { ParentDashboard } from "components/parent-dashboard";
import { ChevronLeft, ChevronRight, ChevronRightOutlined } from "@mui/icons-material";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const columns: GridColDef[] = [
  {
    field: "studentName",
    headerName: "Student name",
    width: 150,
    valueGetter(v) {
      return v.row.firstName + " " + v.row.lastName;
    },
  },
  {
    field: "currentProgress",
    headerName: "Current Progress",
    width: 150,
    valueGetter(v) {
      return "Lesson " + v.row.latestLessonId + " of 21";
    },
  },
  {
    field: "lessonPassed",
    headerName: "Lessons Passed",
    width: 150,
    valueGetter(v) {
      return v.row.lessonsPassed + " Lessons";
    },
  },
  {
    field: "timeInApp",
    headerName: "Time spent",
    width: 150,
    valueGetter(v) {
      var minuteSpend = v.row.lastWeekMinutes;
      return minuteSpend.toString() + " mins";
    },
  },
];

var notificationTypeToAlertSeverity: {
  [i: string]: "error" | "success" | "warning" | "info" | undefined;
} = {
  failed_lesson: "error",
  fluency_report: "success",
  success: "success",
  inactive_students: "warning",
  warning: "warning",
  info: "info",
};

var notificationTypeToAlertTitle: {
  [i: string]: string;
} = {
  failed_lesson: "Failed Lesson",
  fluency_report: "Fluency Report",
  success: "Success",
  inactive_students: "Inactive Students",
  warning: "Warning",
  info: "Info",
};
function TeacherDashboard() {
  const { data: user } = useGetIdentity();
  const [notificationsLoading, setNotificationsLoading] = React.useState(true);
  const [inactive7days, setInactive7days] = React.useState<IStudent[]>([]);
  const [notificationRefreshToken, setNotificationRefreshToken] =
    useState("null");
  const [notificationPages, setNotificationPages] = React.useState<{
    [i: number]: INotification[];
  }>({});

  const notifications = useMemo(() => {
    return Object.values(notificationPages).reduce(
      (acc, val) => acc.concat(val),
      []
    );
  }, [notificationPages]);
  useEffect(() => {
    (async function () {
      const { data } = await createAxios(process.env.REACT_APP_BACKEND).get(
        "/admin/student",
        {
          params: {
            inactiveForXDays: 7,
            _end: 200,
            _start: 0,
          },
        }
      );

      setInactive7days(data);
    })();
  }, []);

  const [currentNotificationPage, setCurrentNotificationPage] =
    React.useState(1);

  useEffect(() => {
    const fetchNotifications = async () => {
      setNotificationsLoading(true);

      try {
        const { data } = await createAxios(process.env.REACT_APP_BACKEND).get(
          "/admin/notification",
          {
            params: {
              _start: (currentNotificationPage - 1) * 10,
              _limit: 10,
              notificationType: "failed_lesson",
            },
          }
        );
        setNotificationPages((prev) => ({
          ...prev,
          [currentNotificationPage]: data,
        }));
        if (currentNotificationPage > 1) {
          setTimeout(() => {
            //scroll scrollableTeacherNotifications to bottom, animated

            const scrollableTeacherNotifications = document.getElementById(
              "scrollableTeacherNotifications"
            );

            scrollableTeacherNotifications?.scrollTo?.({
              top: scrollableTeacherNotifications.scrollHeight,
              behavior: "smooth",
            });
          }, 200);
        }

        setNotificationsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotifications();
  }, [currentNotificationPage, notificationRefreshToken]);

  const { dataGridProps } = useDataGrid<IStudent>({
    initialPageSize: 20,

    resource: "/weeklyFluencyReport",
    initialSorter: [
      {
        field: "last_active",
        order: "desc",
      },
    ],
  });

  const [lessonResources, setLessonResources] = useState<boolean>(false);

  const [lessonResourceId, setLessonResourceId] = useState<number>();
  const [audioPlayingIds, setAudioPlayingIds] = useState<string[]>([])


  const data = [{
    lessonId: 1,
    data: [{
      word: "oO",
      audio: "https://asr-record.s3.amazonaws.com/01c9660e-716e-48e7-8e2d-24bf09420aac/10/W9/cy82yymq0z.wav",
    }, {
      word: "oi",
      audio: "https://asr-record.s3.amazonaws.com/01c9660e-716e-48e7-8e2d-24bf09420aac/10/W9/cy82yasq0z.wav",
    }, {
      word: "oy",
      audio: "https://asr-record.s3.amazonaws.com/01c9660e-716e-48e7-8e2d-24bf09420aac/10/W9/cy8asymq0z.wav",
    }]
  }]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ height: "100%" }}
        >
          <Grid xs={12} sm={12} md={12}>
            <Item>
              <Typography variant="h6" sx={{ ml: 1 }}>
                Inactive students
              </Typography>
              <Paper
                style={{
                  height: "30vh",
                  overflowY: "scroll",
                }}
                elevation={0}
              >
                {/** Show list of tags with name and x days ago **/}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    flexWrap: "wrap",
                    marginTop: 15,
                  }}
                >
                  {inactive7days.map((student) => {
                    var inactiveAgo = moment().diff(
                      moment(student.last_active),
                      "days"
                    );
                    return (
                      <Badge
                        style={{ fontSize: 10 }}
                        badgeContent={`${inactiveAgo}d`}
                        color="error"
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          href={`/student/show/${student.id}`}
                        >
                          <Chip
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                            }}
                            variant="outlined"
                            color={"warning"}
                            label={`${student.firstName} ${student.lastName}`}
                          ></Chip>
                        </a>
                      </Badge>
                    );
                  })}
                </div>
              </Paper>
              {/**
               * Show lis
               */}
            </Item>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <Item>
              {" "}
              {/* Adjust the height here */}
              <Typography variant="h6" sx={{ ml: 1 }}>
                Failed Lesson Alerts
              </Typography>
              {notifications.length > 0 && (
                <Paper
                  style={{
                    overflowY: "auto", // Changed to 'auto' for better behavior
                    gap: 20,
                    height: "27vh",
                    marginTop: 10,
                  }}
                  elevation={0}
                  id={"scrollableTeacherNotifications"}
                >
                  <div
                    style={{
                      overflowY: "scroll",
                      gap: 20,
                    }}
                  >
                    {notifications.map((notification) => (
                      <Alert
                        severity={
                          notificationTypeToAlertSeverity[
                          notification.notification_type
                          ]
                        }
                        style={{ marginBottom: 5, padding: 1, paddingLeft: 5 }}
                        icon={null}
                        iconMapping={{}}
                        onClick={() => {
                          window.location.href = `/student`;
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignSelf: "stretch",
                            flexDirection: "row",
                            gap: 10,
                          }}
                        >
                          <AlertTitle
                            style={{
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                          >
                            {
                              notificationTypeToAlertTitle[
                              notification.notification_type
                              ]
                            }
                          </AlertTitle>
                          <Typography
                            style={{
                              fontSize: 9,
                            }}
                          >
                            {moment(notification.createdAt).fromNow()}
                          </Typography>
                        </div>

                        <Typography
                          style={{
                            fontSize: 10,
                          }}
                        >
                          {notification.content}
                        </Typography>
                      </Alert>
                    ))}
                  </div>
                </Paper>
              )}
              {notificationsLoading && (
                <div
                  style={{
                    flex: 1,
                    minHeight: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "stretch",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>Loading...</Typography>
                </div>
              )}
              {!notificationsLoading && (
                <Button
                  disabled={notificationsLoading}
                  onClick={() => {
                    setCurrentNotificationPage((prev) => prev + 1);
                  }}
                  style={{ alignSelf: "stretch", marginTop: 5 }}
                >
                  Load more
                </Button>
              )}
              {!notificationsLoading && (
                <Button
                  disabled={notificationsLoading}
                  onClick={() => {
                    setNotificationPages({});
                    setCurrentNotificationPage((prev) => 1);
                    setNotificationRefreshToken(makeRandom(10));
                  }}
                  style={{ alignSelf: "stretch", marginTop: 5 }}
                >
                  Refresh
                </Button>
              )}
            </Item>
          </Grid>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={6} style={{ height: "100%" }}>
          <Item
            style={{ display: "flex", flexDirection: "column", height: "38vh" }}
          >
            <Typography variant="h6" sx={{ ml: 1 }}>
              Weekly Fluency Report
            </Typography>
            <Paper
              style={{
                height: "100%",
                overflowY: "scroll",
              }}
              elevation={0}
            >
              {/* (1) current progress
(2) # of lessons passed
(3) time in app */}
              <DataGrid
                {...dataGridProps}
                columns={columns}
                density={"compact"}
                initialState={{
                  pagination: {
                    page: 0,
                    pageSize: 5,
                  },
                }}
                onRowClick={(params, event, details) => {
                  window.location.href = `/student/show/${params.row.id}`;
                }}
              />
            </Paper>
          </Item>
          <Item
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
              height: "40vh",
            }}
          >
            <Typography variant="h6" sx={{ ml: 1 }}>
              Resources
            </Typography>
            <Paper
              style={{
                height: "100%",
                overflowY: "scroll",
                marginRight: 20,
              }}
              elevation={0}
            >
              <a
                href="https://www.paperturn-view.com/read-21/teacher-manual-read-21?pid=MzM331043&v=5"
                target="_blank"
                rel="noreferrer"
              >
                <Typography className="text-blue-500 font-bold underline" style={{}}>
                  Teacher's Manual
                </Typography>
              </a>
              <a
                href="https://read21.org/explainer/"
                target="_blank"
                rel="noreferrer"
              >
                <Typography className="text-blue-500 font-bold underline" style={{}}>
                  Explainer Videos
                </Typography>
              </a>
              {/* <div >
                <div className="flex items-center gap-2 cursor-pointer" onClick={() => {
                  setLessonResources(!lessonResources)
                }}>
                  {
                    lessonResources ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="fill-blue-400  bg-black/10 rounded-sm rotate-90 p-0 border" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="currentColor" d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.6 4.6q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7z"></path></svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="fill-blue-400  bg-black/10 rounded-sm p-0 border" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="currentColor" d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.6 4.6q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7z"></path></svg>
                    )
                  }
                  <Typography className=" font-bold" style={{}}>
                    Lesson Resources
                  </Typography>
                </div>

                {
                  lessonResources && (
                    <div className="px-7 cursor-pointer">
                      <button onClick={() => {
                        setLessonResourceId(1)
                      }}>
                        <Typography className="font-bold text-blue-600">
                          Lesson 1
                        </Typography>
                      </button>
                    </div>
                  )
                }
              </div> */}
            </Paper>
          </Item>
        </Grid>
      </Grid>
      <Modal open={lessonResourceId !== undefined} onClose={() => {
        setLessonResourceId(undefined)
      }}>
        <div className="flex justify-center items-center h-full">
          <div className="bg-white w-1/2 p-4 rounded-md flex flex-col gap-2">
            <p className="text-2xl font-bold ">
              Lesson {lessonResourceId}
            </p>
            <p className="border-l-4 border-black/50 px-2">
              Sounds
            </p>
            {data.map((lesson) => (
              lesson.lessonId === lessonResourceId && (
                <div key={lesson.lessonId} className="flex gap-4 flex-wrap">
                  {lesson.data.map((item, index) => (
                    <div key={index} className="bg-gray-500 rounded-full w-14 text-white italic border border-black/70 h-14 cursor-pointer flex flex-col items-center justify-center px-4 mb-2" onClick={async () => {
                      const audioElement = document.querySelector(`audio[src="${item.audio}"]`) as HTMLAudioElement;
                      if (audioElement) {
                        setAudioPlayingIds([...audioPlayingIds, item.audio])
                        await audioElement.play();
                      }
                    }}>
                      <span>{item.word}</span>
                      <audio src={item.audio} style={{ display: 'none' }}
                        onEnded={() => {
                          setAudioPlayingIds(prev => prev.filter(id => id !== item.audio));
                        }}
                      />
                      {
                        audioPlayingIds.includes(item.audio) ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M200 36h-40a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12m4 172a4 4 0 0 1-4 4h-40a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4ZM96 36H56a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12m4 172a4 4 0 0 1-4 4H56a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4Z"></path></svg>
                        ) : (<svg xmlns="http://www.w3.org/2000/svg" className="text-white cursor-pointer" width="1.4em" height="1.4em" viewBox="0 0 24 24" >
                          <path fill="currentColor" d="M9 15.714V8.287q0-.368.244-.588q.243-.22.568-.22q.102 0 .213.028q.11.027.211.083l5.843 3.733q.186.13.28.298q.093.167.093.379t-.093.379t-.28.298l-5.843 3.733q-.101.055-.213.083t-.213.028q-.326 0-.568-.22T9 15.714"></path>
                        </svg>)
                      }
                    </div>
                  ))}
                </div>
              )
            ))}
            <Button className="mt-6" onClick={() => {
              setLessonResourceId(undefined)
            }}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </Box>
  );
}
const OtherDashboard = ({ }) => {
  const { data: user } = useGetIdentity();
  return (
    <>
      {user.userType === "principal" ? (
        <PrincipalDashboard />
      ) : user.userType === "admin" ? (
        <SysDashboard />
      ) : user.userType === "parent" ? (
        <ParentDashboard />
      ) : (
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
        >
          <img height={250} src={"/images/read21.svg"} />
          <Typography variant={"h4"} color={"#0057FF"}>
            Welcome {user?.firstName}!
          </Typography>
        </Stack>
      )}
    </>
  );
};
const DefaultAppPage: FunctionComponent<DashboardPageProps<any>> = ({ }) => {
  const { data: user, isLoading } = useGetIdentity<IUser>();
  return (
    <>
      {!isLoading && (
        <>
          {user?.userType === "teacher" ? (
            <TeacherDashboard />
          ) : (
            <OtherDashboard />
          )}
        </>
      )}
    </>
  );
};

export default DefaultAppPage;
