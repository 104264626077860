import {
  HttpError,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import {
  Avatar,
  Button,
  CreateButton,
  DataGrid,
  DeleteButton,
  Grid,
  GridColumns,
  List,
  Paper,
  Stack,
  Typography,
  useDataGrid,
} from "@pankod/refine-mui";
import React from "react";

import {
  CalendarMonthOutlined,
  EditOutlined,
  SchoolOutlined,
} from "@mui/icons-material";

import { DurationDate } from "components";
import { ITeacher } from "interfaces";
import moment from "moment";
import UserAvatar from "../../components/custom";

const UserInfoText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent={{
      sm: "center",
      lg: "flex-start",
    }}
    gap={1}
  >
    {children}
  </Stack>
);

export const TeacherShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show, edit, push, createUrl } = useNavigation();
  const { mutate } = useDelete();
  const { data: canEditTeacher } = useCan({
    resource: "teacher",
    action: "edit",
  });
  const { data: canDeleteTeacher } = useCan({
    resource: "teacher",
    action: "delete",
  });
  const { queryResult, showId } = useShow<ITeacher>();
  const teacher = queryResult.data?.data;

  const { dataGridProps } = useDataGrid<ITeacher, HttpError, ITeacher>({
    resource: "student",
    permanentFilter: [
      {
        field: "teacher",
        operator: "eq",
        value: teacher?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: teacher !== undefined,
    },
    syncWithLocation: false,
    initialSorter: [
      {
        field: "last_active",
        order: "desc",
      },
    ],
  });

  const columns = React.useMemo<GridColumns<ITeacher>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 250,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return <UserAvatar user={principal} />;
        },
      },
      {
        field: "progress_lessonId",
        headerName: "Fluency Progress",
        minWidth: 120,
        flex: 1,
        valueGetter(params) {
          const progresses = (params.row as any).progress_lessonId;
          return progresses;
        },
        filterable: true,
        renderCell(params) {
          const progress = params.value;
          const pretest = (params.row as any).introductionPresented === 0 ? false : true;
          return (
            <Typography>
              <b>
                {progress <= 0
                  ? pretest ? "Pre Test" : "Not Started"
                  : progress >= 21
                    ? "Completed"
                    : `Lesson ${progress}`}
              </b>
            </Typography>
          );
        },
      },
      {
        field: "ctProgressItemNumber",
        headerName: "CT Progress",
        minWidth: 120,
        flex: 1,
        renderCell(params) {
          // const license = params.row.license;
          // const expiresOn = moment(license.expiresOn);
          // const createdOn = moment(license.created_at);
          const progress = (params.row as any).ctProgressItemNumber ?? 0;
          return progress >= 160 && (params.row as any).isComplete ? (
            <b>Complete</b>
          ) : progress ? (
            <span>
              Lesson <b>{Math.ceil((progress - 4) / 4) + 1}</b>
              <br />
              Activity <b>{((progress - 1) % 4) + 1}</b>
            </span>
          ) : (
            <b>Not Started</b>
          );
        },
      },
      {
        field: "last_active",
        headerName: "Last Active",
        flex: 1,
        minWidth: 150,
        renderCell(params) {
          var diffTime = moment(params.row.last_active).fromNow();

          // var lastLesson =
          //   progresses
          //     .sort((a, b) => b.last_active - a.last_active)
          //     .find(
          //       (a) => moment(new Date()).diff(a.last_active, "minute") < 1
          //     ) || null;

          return (
            <Typography variant="body1">
              {diffTime}
              {/* {lastLesson && (
                <>
                  <br />
                  on{" "}
                  <b>
                    {lastLesson.lessonId > 0 ? (
                      <>
                        Lesson <b>{lastLesson.lessonId}</b>
                      </>
                    ) : (
                      <b>Pre-Test</b>
                    )}
                  </b>
                </>
              )} */}
            </Typography>
          );
        },
      },
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        minWidth: 200,
        valueFormatter(v) {
          const dt = moment(v.value);
          return dt.format("L") + ` (${dt.fromNow()})`;
        },
      },

    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Paper sx={{ p: 2, paddingX: { xs: 4, md: 2 } }}>
          <Stack alignItems="center" spacing={1}>
            <Avatar src={teacher?.photoURL} sx={{ width: 120, height: 120 }} />
            <Typography variant="h6">
              {teacher?.firstName} {teacher?.lastName}
            </Typography>
          </Stack>
          <br />
          <Stack spacing={1}>
            <UserInfoText>
              <SchoolOutlined />

              <a
                href="#"
                onClick={() => {
                  show("school", teacher?.school?.id!);
                }}
                style={{
                  display: "flex",
                  color: "#000",
                  textDecorationLine: "underline",
                  flexDirection: "row",
                  gap: 5,
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">{teacher?.school?.name}</Typography>
              </a>
            </UserInfoText>
            <UserInfoText>
              <CalendarMonthOutlined />
              <Typography variant="body1">
                <DurationDate date={teacher?.created_at} />
              </Typography>
            </UserInfoText>
          </Stack>
          <br />
          <Stack
            sx={{ mt: 2 }}
            spacing={1}
            direction={"row"}
            justifyContent="space-between"
          >
            {canEditTeacher?.can && (
              <Button
                startIcon={<EditOutlined />}
                onClick={() => {
                  edit("user", showId!);
                }}
              >
                Edit
              </Button>
            )}

            {canDeleteTeacher?.can && (
              <DeleteButton
                onSuccess={() => {
                  show("school", teacher?.school?.id!);
                }}
                recordItemId={showId}
              />
            )}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Stack direction="column" spacing={2}>
          <List
            resource="teacher"
            cardHeaderProps={{
              title: "Students",
              action: (
                <CreateButton
                  onClick={() => {
                    push(
                      createUrl("student") + `?school=${teacher?.school?.id}`
                    );
                  }}
                />
              ),
            }}
            cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
          >
            <DataGrid
              {...dataGridProps}
              columns={columns}
              autoHeight
              onCellClick={(cell) => {
                show("student", cell.row.id);
              }}
              rowsPerPageOptions={[4, 10, 20, 100]}
            />
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};
