import Lesson1 from "./lesson-1.json"
import Lesson2 from "./lesson-2.json"
import Lesson3 from "./lesson-3.json"
import Lesson4 from "./lesson-4.json"
import Lesson5 from "./lesson-5.json"
import Lesson6 from "./lesson-6.json"
import Lesson7 from "./lesson-7.json"
import Lesson8 from "./lesson-8.json"
import Lesson9 from "./lesson-9.json"
import Lesson10 from "./lesson-10.json"
import Lesson11 from "./lesson-11.json"
import Lesson12 from "./lesson-12.json"

export const g2 = {
    "audioPrefix": "g2ct",

    days: [
        ...Lesson1,
        ...Lesson2,
        ...Lesson3,
        ...Lesson4,
        ...Lesson5,
        ...Lesson6,
        ...Lesson7,
        ...Lesson8,
        ...Lesson9,
        ...Lesson10,
        ...Lesson11,
        ...Lesson12
    ]
}