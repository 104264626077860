import betterFetch from "@better-tools/fetch"
import { Download, DownloadingOutlined } from "@mui/icons-material"
import { useQuery } from "@pankod/refine-core"
import { Button, FormControl, FormLabel, MenuItem, Modal, Select, TextField } from "@pankod/refine-mui"
import JSZip from "jszip"
import { useRef, useState } from "react"
import { $fetch } from "utils/fetch"
import { saveAs } from "file-saver"


export const DownloadLog = () => {
    const lessonIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(d => d.toString())
    const [logType, setLogType] = useState("correct")
    const [lessonId, setLessonId] = useState<string>("1")
    const [data, setData] = useState<{
        [key: string]: string[]
    }>()
    const [isDownloading, setIsDownloading] = useState(false)
    const signal = useRef(new AbortController())
    const [progress, setProgress] = useState(0)
    const [total, setTotal] = useState(0)
    return (
        <div>
            <h2 className="font-bold text-xl text-black">
                Download Logs
            </h2>
            <div className="mt-4 flex flex-col gap-4">
                <FormControl fullWidth>
                    <p className="font-bold py-2 text-black">
                        Select Log Type
                    </p>
                    <Select defaultValue="correct" onChange={(e) => setLogType(e.target.value)}>
                        <MenuItem value="correct">
                            Correct
                        </MenuItem>
                        <MenuItem value="incorrect">
                            Incorrect
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <p className="font-bold py-2 text-black">
                        Lesson
                    </p>
                    <TextField onChange={(e) => setLessonId(e.target.value)} value={lessonId} />
                </FormControl>
                <Button variant="contained" className="w-max" onClick={async () => {
                    setIsDownloading(true)
                    signal.current = new AbortController()
                    const res = await $fetch<{
                        [key: string]: string[]
                    }>(`admin/downloadLogs?lessonId=${lessonId}&wasCorrect=${logType === "correct"}`)
                    const all = Object.keys(res.data || {}).flatMap(key => ({
                        key,
                        data: res.data?.[key]
                    })).filter(v => v)

                    setTotal(all.flatMap(d => d.data).length)
                    for (const key of all) {
                        const zip = new JSZip();
                        const folder = zip.folder(key.key);
                        const allUrls = key.data
                        if (!allUrls) {
                            continue
                        }
                        for (const url of allUrls) {
                            if (signal.current.signal.aborted) break;
                            const fileName = url?.split('/').pop() || "";
                            const uri = `https://asr-record.s3.amazonaws.com/${url}`
                            const res = await betterFetch<Blob>(uri, {
                                signal: signal.current.signal
                            })
                            if (res.data) {
                                folder?.file(fileName, res.data);
                            }
                            setProgress(pr => pr + 1)
                        }
                        const content = await zip.generateAsync({ type: 'blob' })
                        saveAs(content, `Lesson ${lessonId} (${key.key}) - ${logType}`);
                    }
                    if (!signal.current.signal.aborted) {
                        setIsDownloading(false)
                        setProgress(0)
                    }
                }}>
                    Download
                </Button>
            </div>
            <Modal open={isDownloading}>
                <div className="h-full w-full flex items-center justify-center">
                    <div className="bg-white rounded-md px-4 py-2 flex gap-4 flex-col">
                        <div className="flex items-center gap-2 mt-2 animate-pulse">
                            <DownloadingOutlined />
                            <p className="text-black">
                                Downloading...
                            </p>
                            <p>
                                ({Number.isNaN(((progress / total) * 100).toFixed(1)) ? "" : ((progress / total) * 100).toFixed(1)}%)
                            </p>
                        </div>
                        <Button variant="outlined" className='mt-4' color="error" size="small" onClick={() => {
                            setIsDownloading(false)
                            signal.current.abort("user aborted")
                            setProgress(0)
                        }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}