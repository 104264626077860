import { useState, useEffect, useContext } from "react";
import {
  AppBar,
  IconButton,
  Link,
  Avatar,
  Typography,
  TextField,
  Toolbar,
  Box,
  Autocomplete,
  Stack,
  FormControl,
  MenuItem,
  Select,
} from "@pankod/refine-mui";
import {
  useList,
  useTranslate,
  useGetIdentity,
  useGetLocale,
  useSetLocale,
  useNavigation,
} from "@pankod/refine-core";
import {
  SearchOutlined,
  DarkModeOutlined,
  LightModeOutlined,
} from "@mui/icons-material";
import * as React from "react";

interface IOptions {
  label: string;
  url: string;
  link: string;
  category: string;
}

var userTypeToTitle = {
  student: "Student",
  teacher: "Teacher",
  principal: "School Administrator",
  admin: "System Admin",
};
export const Header: React.FC = () => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const { data: user } = useGetIdentity();
  const { edit } = useNavigation();
  console.log({ user })

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" flex={1} style={{ alignItems: "center" }}>
            <Typography variant="h6">
              Welcome <b>{user?.firstName}</b>!
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            {user?.userType === "parent" && (
              <div className="flex flex-col items-end">
                <p className="text-sm font-medium">
                  {user?.firstName} {user?.lastName}
                </p>
                <p className="text-xs">
                  School Id: {user?.school?.code ?? "" } 
                </p>
              </div>
            )}
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                edit("user", "me");
              }}
            >
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle2">
                  {userTypeToTitle[user?.userType]}
                </Typography>
                <Avatar src={user?.photoURL} alt={user?.firstName} />
              </Stack>
            </button>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
