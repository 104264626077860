import { AccessControlProvider } from "@pankod/refine-core";
import { authProvider } from "authProvider";
import memoizee from "memoizee"


const getUserIdentity = memoizee(async function () {
    return (await authProvider.getUserIdentity!!()!!)
}, { promise: true });
setInterval(() => {
    getUserIdentity.clear()
}, 6000)
export const acProvider: AccessControlProvider = {
    can: async ({ resource, action, params }) => {
        console.log(resource, action, params)
        let user = await getUserIdentity()
        var ctReports = ["ctActivity", "lessonReportCT", "studentReportCT", "classReportCT"].map(itm => itm.toLowerCase())
        var flReports = ["lessonReport", "studentReport", "flActivity", "classReport"].map(itm => itm.toLowerCase())
        const chReport = [
            "chreport", "lessonreport", "assignable"
        ]

        if ((resource === "schoolreport" || resource === "teacherreport" || resource === "gradelevelreport" || resource === "dyslexiareport" || resource === "dyslexiaReport" || resource === "gradeLevelReport") && user?.userType === "principal") {
            return Promise.resolve({ can: true });
        }


        if (resource === "contact") {
            return Promise.resolve({ can: true });
        }


        if (resource === "schoolReport" || resource === "teacherReport") {
            return Promise.resolve({ can: true });
        }

        if (user?.userType === "parent") {
            return Promise.resolve({ can: parentAc(resource) });
        }

        if (resource === "report" && user?.userType !== "parent") {
            return Promise.resolve({ can: false });
        }

        if ((user.userType === "admin") || (resource === "dashboard") || (resource === "lesson") || (resource === "report") || (resource.toLowerCase() === "fluencyreport") || (resource.toLowerCase() === "criticalreport") || (resource === "checkpoint-report") || (chReport.indexOf(resource.toLowerCase()) !== -1) || (
            ctReports.indexOf(resource.toLowerCase()) !== -1
        ) || ((flReports.indexOf(resource.toLowerCase()) !== -1)) || (resource === "student")
            || (resource === "user" && (action === "edit"))
        ) {
            if (user.userType)
                return Promise.resolve({ can: true });
        }
        else if (user.userType === "principal") {
            if ((resource === "school") && (action === "show")) {
                return Promise.resolve({ can: true });
            } else if (resource === "principal" && (user.id === params?.id) && (action === "show")) {
                return Promise.resolve({ can: true });
            } else if (resource === "teacher") {
                return Promise.resolve({ can: true });
            }
        } else if (user.userType === "teacher") {
            if (resource === "teacher" && (user.id === params?.id) && (action === "show")) {
                return Promise.resolve({ can: true });
            } else {
            }
        }

        if (user.name === "reportSuperLevel") {
            return Promise.resolve({ can: true });
        } else {
            return Promise.resolve({ can: false, reason: "Unauthorized" });
        }

    }
};

function parentAc(pg: string) {
    const possible = ["children", "report", "dashboard", "studentreportct", "studentreport", "student", "user"]
    console.log(pg)
    return possible.indexOf(pg) !== -1
}