import { Typography } from "@pankod/refine-mui";
import React from "react";

export const ProgressItem: React.FC<{
  progress: number;
  introductionPresented: boolean;
}> = ({ progress, introductionPresented }) => {
  return (
    <Typography>
      <b>
        {progress <= 0
          ? introductionPresented
            ? "Pre Test"
            : "Not Started"
          : progress}
      </b>
    </Typography>
  );
};
