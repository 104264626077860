import { CrudFilters, useNavigation } from "@pankod/refine-core";
import {
  Button,
  DataGrid,
  GridColumns,
  Input,
  List,
  useDataGrid,
} from "@pankod/refine-mui";
import UserAvatar from "components/custom";
import React, { useEffect, useMemo, useState } from "react";
import { Parent } from "./type";
import { IUser } from "interfaces";

export const ParentList = () => {
  const [search, setSearch] = React.useState<string>("");
  const permanentFilters = useMemo<CrudFilters>(() => {
    const crudFilter: CrudFilters = [];
    if (search?.length > 0) {
      crudFilter.push({
        field: "title",
        operator: "contains",
        value: search,
      });
    }
    return crudFilter;
  }, [search]);
  const [selected, setSelected] = useState<string[]>([]);
  const { dataGridProps, tableQueryResult, setFilters, filters, setCurrent } = useDataGrid<Parent[]>({
    initialPageSize: 10,
    permanentFilter: permanentFilters,
    initialSorter: [
      {
        field: "last_active",
        order: "desc",
      },
    ],
  });

  const columns = React.useMemo<GridColumns<Parent>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const row = params.row;
          return (
            <UserAvatar
              user={
                {
                  firstName: row.firstName,
                  lastName: row.lastName,
                  user_status: row.user_status as "archive",
                  last_active: row.last_active,
                } as IUser
              }
            />
          );
        },
      },
      {
        field: "_count['parentOf']",
        headerName: "Students Count",
        flex: 1,
        minWidth: 100,
        valueGetter(v) {
          return v.row._count.parentOf;
        },
      },
      {
        field: "Delete",
        headerName: "Actions",
      },
    ],
    []
  );
  const { show } = useNavigation();

  const [hideUnselected, setHideUnselected] = useState(false);
  return (
    <List>
      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {
            (!!selected.length || hideUnselected) && (
              <div className="flex items-center gap-2">
                {/* <Button color="secondary" onClick={() => {
                  setReportCardModal(true)
                }}>
                  Generate Report Card
                </Button> */}
                <Button onClick={() => {
                  if (!hideUnselected) {
                    setHideUnselected(true)
                    setFilters([
                      ...filters,
                      {
                        field: "id",
                        operator: "in",
                        value: selected,
                      },
                    ])
                    setCurrent(1)
                  } else {
                    setHideUnselected(false)
                    //@ts-expect-error
                    setFilters(prev => prev.filter(f => f.field !== "id"))
                  }
                }}>
                  {
                    hideUnselected ? "Show All" : "Hide Unselected"
                  }
                </Button>
              </div>

            )
          }
        </div>
      </div>

      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        checkboxSelection
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        sortingOrder={["asc", "desc"]}
        onCellClick={(cell) => {
          if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected(prev => prev.filter(id => id !== cell.row.id))
            } else {
              setSelected(prev => [...prev, cell.row.id])
            }
            return
          }
          show("parent", cell.id);
        }}
        onColumnHeaderClick={(col) => {
          if (col.field === "__check__") {
            setSelected([])
          }

        }}
      />
    </List>
  );
};
