/** @format */

import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Tooltip,
  TagField,
  GridToolbar,
  Input,
  Button,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

import { ISchool } from "interfaces";
import moment from "moment";

export const SchoolList: React.FC<IResourceComponentsProps> = () => {
  const { show, create, createUrl } = useNavigation();
  const t = useTranslate();
  const { useHistory } = useRouterContext();
  const history = useHistory();
  const { mutate: mutateDelete } = useDelete();
  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);


  const { dataGridProps, setFilters, setCurrent, filters } = useDataGrid<ISchool>({
    initialPageSize: 10,
    permanentFilter:
      search?.length > 0
        ? [
          {
            // @ts-ignore
            field: "title_like",
            operator: "eq",
            value: search,
          },
        ]
        : [],
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
    hasPagination: true,
  });

  const columns = React.useMemo<GridColumns<ISchool>>(
    () => [
      {
        field: "code",
        headerName: "Code",
        minWidth: 100,
      },
      {
        field: "name",
        headerName: "School Name",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "principalId",
        headerName: "Administrator",
        flex: 1,
        renderCell(params) {
          if (params.row.principalId) {
            const principal = params.row.principal;
            return (
              <a
                style={{
                  display: "flex",
                  textDecorationLine: "underline",
                  flexDirection: "row",
                  gap: 5,
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src={principal?.photoURL}
                  alt={principal?.firstName}
                />
                <Typography variant="subtitle2">
                  {principal?.firstName}
                </Typography>
              </a>
            );
          } else {
            return <TagField value={"Not assigned"} />;
          }
        },
      },
      {
        field: "users[count]",
        headerName: "# Students",
        flex: 1,
        valueGetter(v) {
          return v.row._count.users;
        },
      },
      {
        field: "licenses[count]",
        headerName: "# Unused Licenses",
        flex: 1,
        valueGetter(v) {
          return v.row._count.licenses;
        },
      },
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        valueFormatter(v) {
          const dt = moment(v.value);
          return dt.format("L") + ` (${dt.fromNow()})`;
        },
      },
    ],
    []
  );
  const [hideUnselected, setHideUnselected] = useState(false);

  return (
    <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>

      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {
            (!!selected.length || hideUnselected) && (
              <div className="flex items-center gap-2">
                {/* <Button color="secondary" onClick={() => {
                  setReportCardModal(true)
                }}>
                  Generate Report Card
                </Button> */}
                <Button onClick={() => {
                  if (!hideUnselected) {
                    setHideUnselected(true)
                    setFilters([
                      ...filters,
                      {
                        field: "id",
                        operator: "in",
                        value: selected,
                      },
                    ])
                    setCurrent(1)
                  } else {
                    setHideUnselected(false)
                    //@ts-expect-error
                    setFilters(prev => prev.filter(f => f.field !== "id"))
                  }
                }}>
                  {
                    hideUnselected ? "Show All" : "Hide Unselected"
                  }
                </Button>
              </div>

            )
          }
        </div>
      </div>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        checkboxSelection
        disableColumnFilter={true}
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        onColumnHeaderClick={(col) => {
          if (col.field === "__check__") {
            setSelected([])
          }
        }}
        onCellClick={(cell) => {
          if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected(prev => prev.filter(id => id !== cell.row.id))
            } else {
              setSelected(prev => [...prev, cell.row.id])
            }
            return
          }
          if (cell.colDef.field === "principalId") {
            if (cell.value) {
              show("principal", cell.value);
            } else {
              history.push(createUrl("principal") + `?school=` + cell.row.id);
            }
          } else {
            show("school", cell.row.id);
          }
        }}
      />
    </List>
  );
};
