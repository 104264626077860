import { useList, useQuery, useShow } from "@pankod/refine-core"
import { $fetch } from "utils/fetch"
import { DyslexiaReportI } from "../list/dsylexia"



export const DyslexiaReportShow = () => {
    const { showId } = useShow()
    const { data } = useQuery({
        queryKey: ["dyslexiaReport", showId],
        queryFn: async () => {
            const res = await $fetch<DyslexiaReportI[]>("admin/dyslexiaReports/class/:id", {
                params: {
                    id: showId
                }
            })
            console.log(res)
            return res
        }
    })
    return (
        <div>
            <h1>Dyslexia Report</h1>
        </div>
    )
}