import { useCustom, useGetIdentity, useNavigation } from "@pankod/refine-core";
import {
  Avatar,
  Card,
  CardContent,
  DataGrid,
  FormControl,
  GridColumns,
  InputLabel,
  Select,
  Stack,
  Typography,
} from "@pankod/refine-mui";
import { apiUrl } from "const/constants";
import { useEffect, useState } from "react";
import { Icons } from "utils/icons";
import { AdminReport, School } from "utils/type";
import { tFormat } from "utils/utils";

export const SysDashboard = () => {
  const { data } = useCustom<AdminReport>({
    url: `${apiUrl}/admin/notification/adminReport`,
    method: "get",
  });
  const { show, push } = useNavigation();
  const { data: user } = useGetIdentity();
  const [report, setReport] = useState<{
    totalStudent: number;
    totalMinutes: number;
    totalInactiveStudent: number;
    totalFluencyMedian: number;
    totalCtMedian: number;
  }>();

  useEffect(() => {
    function getTotalStudents() {
      return data?.data.schools.reduce(
        (acc, cur) => {
          return {
            totalStudent: acc.totalStudent + cur.schoolStatistics.studentCount,
            totalMinutes:
              acc.totalMinutes +
                cur.schoolStatistics.totalMinutes /
                cur.schoolStatistics.studentCount >
                0
                ? cur.schoolStatistics.studentCount
                : 1,
            totalInactiveStudent:
              acc.totalInactiveStudent + cur.inactiveStudents?.length ?? 0,
            totalFluencyMedian: acc.totalFluencyMedian + cur.medianProgress,
            totalCtMedian: acc.totalCtMedian + cur.medianActivity,
          };
        },
        {
          totalStudent: 0,
          totalMinutes: 0,
          totalInactiveStudent: 0,
          totalFluencyMedian: 0,
          totalCtMedian: 0,
        }
      );
    }
    if (data) {
      const rep = getTotalStudents();
      setReport(rep);
    }
  }, [data]);

  const schoolColumns: GridColumns<School> = [
    {
      field: "schoolName",
      headerName: "School Name",
      renderCell(param) {
        return param.row.schoolName;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "studentCount",
      headerName: "Total Active Students",
      renderCell(params) {
        return params.row.schoolStatistics.studentCount;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "totalMinutes",
      headerName: "Average Minute Per Student",
      renderCell(params) {
        return `${tFormat(
          Math.floor(
            params.row.schoolStatistics.totalMinutes /
            params.row.schoolStatistics.studentCount
          )
        )}`;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "medianProgress",
      headerName: "Median Fluency Lesson Progress",
      renderCell(params) {
        return `Lesson ${Math.floor(params.row.medianProgress)}`;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "medianActivity",
      headerName: "Median Critical Thinking Lesson Progress",
      renderCell(params) {
        return `Lesson ${params.row.medianActivity}`;
      },
      minWidth: 200,
      flex: 1,
    },
  ];

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h2>
          Welcome, {user.firstName} {user.lastName}
        </h2>
      </Stack>
      <div id="report">
        <Stack
          justifyContent="space-between"
          direction="row"
          flexWrap="wrap"
          gap="12px"
        >
          <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>Total Active Students</p>
                <Icons.Users />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                {(report?.totalStudent ?? 0) -
                  (report?.totalInactiveStudent ?? 0)}
                <span style={{ fontSize: "16px" }}> students</span>
              </p>
            </CardContent>
          </Card>

          <Card style={{ width: "24%", backgroundColor: "#FFFCB9" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Total Average Minutes Per Student
                </p>
                <Icons.Timer />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                {report?.totalMinutes.toFixed(0)}
                <span style={{ fontSize: "12px", paddingLeft: "4px" }}>
                  mins
                </span>
              </p>
            </CardContent>
          </Card>

          <Card style={{ width: "24%", backgroundColor: "#CDE8B5" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Median Fluency Lesson Progress
                </p>
                <Icons.Progress />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                Lesson {data?.data.aggregatedStatistics?.medianProgress}
              </p>
            </CardContent>
          </Card>
          <Card style={{ width: "24%", backgroundColor: "#D4E3FE" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Median Critical Thinking Lesson Progress
                </p>
                <Icons.Activity />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                Lesson {data?.data.aggregatedStatistics?.medianActivity}
              </p>
            </CardContent>
          </Card>
        </Stack>
        <div style={{ marginTop: "16px" }}>
          <h3>School Report</h3>
          <DataGrid
            columns={schoolColumns}
            rows={data?.data.schools ?? []}
            autoHeight
            rowsPerPageOptions={[10, 20, 50, 100]}
            onCellClick={(cell) => {
              push(`/school/report?id=${cell.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};
