import { useCustom } from "@pankod/refine-core";
import {
    GridColumns,
    List,
    Stack,
} from "@pankod/refine-mui";
import { DataGrid } from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { apiUrl } from "const/constants";

export const LogReport = () => {
    type Response = { lessonId: number, textId: string, total_count: string, week_number: string, text: { text: string } }
    const { data } = useCustom<Response[]>({
        url: `${apiUrl}/admin/countCorrectedLogs/`,
        method: "get",
    })
    const columns: GridColumns<Response> = [{
        field: "lessonId",
        headerName: "Lesson ID",
        flex: 1
    },
    {
        field: "textId",
        headerName: "Key",
        flex: 1

    },
    {
        field: "total_count",
        headerName: "Updated Count",
        valueGetter(params) {
            return parseInt(params.row.total_count)
        },
        sortable: true,
        flex: 1
    },
    {
        field: "text[text]",
        headerName: "Text",
        flex: 1,
        renderCell: ({ row }) => {

            return (
                <span>
                    {row.text?.text || "-"}
                </span>
            )
        }
    },
    {
        field: "week_number",
        headerName: "Week Number",
        flex: 1,
        renderCell: (params) => {
            const value = (params.value.toString() as string).substring(4, 6)
            return (
                <p>
                    {value}
                </p>
            )
        }
    }]
    return (
        <div>
            <div style={{ marginTop: "12px" }} id="export">
                <List title="Manual Correction Logs" headerButtons={() => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <ExportAs id="export" getExportName={() => "Correction Log"} getRows={() => [
                                ["lesson Id", "Key", "Count", "Week Number"],
                                ...data?.data.map((d) => [d.lessonId, d.textId, d.total_count, (d.week_number.toString() as string).substring(4, 6)]) ?? []
                            ]} />
                        </Stack>
                    )
                }}>
                    <DataGrid
                        getRowId={(row) => `${row.textId} ${row.lessonId}`}
                        rows={data?.data ?? []}
                        columns={columns}
                        disableColumnFilter={true}
                        autoHeight
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        density="comfortable"
                        sx={{
                            "& .MuiDataGrid-cell:hover": {
                                cursor: "pointer",
                            },
                        }}
                    // onCellClick={(cell) => {
                    //   if (cell.colDef.field == "principalId") {
                    //     if (cell.value) {
                    //       show("principal", cell.value);
                    //     } else {
                    //       history.push(createUrl("principal") + `?school=` + cell.row.id);
                    //     }
                    //   } else {
                    //     show("school", cell.row.id);
                    //   }
                    // }}
                    />
                </List>
            </div>


        </div>
    )
}