import { Check } from "@mui/icons-material"
import { useGetIdentity, useMutation } from "@pankod/refine-core"
import { Button, Checkbox, Input, Table, TableBody, TableCell, TableHead, TableRow, TextareaAutosize, useAutocomplete } from "@pankod/refine-mui"
import { IStudent } from "interfaces"
import { useEffect, useState } from "react"
import { $fetch } from "utils/fetch"
import { Autocomplete, TextField } from "@mui/material";


export const Contact = () => {
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)

    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [student, setStudent] = useState<IStudent | null>(null)
    console.log(student)
    const [issue, setIssue] = useState<string[]>([])
    const { mutate, isLoading } = useMutation({
        mutationFn: async (data) => {
            return await $fetch("admin/ticket", {
                method: "POST",
                body: {
                    subject: "Support Ticket",
                    message: `\nIssues: ${issue.map((iss, i) => `${i + 1}. ${iss === "Location of School ID" ? `Location of School ID (${(student as any)?.schoolId})` : iss
                        }`).join(" ")}\n${description ? `Description: ${description}` : ""}\nStudent: ${student?.firstName} ${student?.lastName}\n Email: ${email}\nPhone: ${phone}`
                }
            })
        },
        onSuccess() {
            setShowSuccess(true)

        },
        onSettled() {
            setSubject("")
            setDescription("")
            setEmail("")
            setPhone("")
            setStudent(null)
            setIssue([])

        },
    })

    const appNotWorking = [
        {
            label: "Sound Issue",
        },
        {
            label: "Frozen Screen"
        },
        {
            label: "Red Screen"
        },
        {
            label: "Answer Not Processing"
        }
    ]

    const userInfo = [
        {
            label: "Password Reset"
        },
        {
            label: "Location of School ID"
        },
        {
            label: "Creating New User"
        },
        {
            label: "Cannot Login"
        },
        {
            label: "Accidentally deleted student (need to restore student)"
        },
        {
            label: "How do I extend my license?"
        }
    ]

    const user = useGetIdentity()
    const { autocompleteProps } = useAutocomplete<IStudent>({
        resource: "student",
    });

    return (
        <div>
            <h1 className="font-bold text-2xl">
                Contact Support
            </h1>

            <p>
                Please fill out the form below.
            </p>
            <div className="my-4 flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        Teacher/Parent Name
                    </p>
                    <Input value={`${user.data?.firstName} ${user.data?.lastName}`} />
                </div>

                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        School Name
                    </p>
                    <Input value={
                        user.data?.school?.name
                    } />
                </div>

                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        Contact Email
                    </p>
                    <Input onChange={(e) => {
                        setEmail(e.target.value)
                    }} value={email} />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        Contact Phone
                    </p>
                    <Input onChange={(e) => {
                        setPhone(e.target.value)
                    }} value={phone} />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        Student Name
                    </p>
                    <Autocomplete
                        disablePortal
                        {...autocompleteProps}
                        getOptionLabel={(item) => `${item.firstName} ${item.lastName}`}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            option?.id?.toString() === (value?.id ?? value)?.toString()
                        }
                        onChange={(_, value) => setStudent(value)}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Student Name"
                                margin="normal"
                                variant="outlined"

                            />
                        )}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-bold">
                        Use the option below to describe your issue or request
                    </p>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    User Information
                                </TableCell>
                                <TableCell>
                                    App Not Working
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                userInfo.map((item, idx) => (
                                    <TableRow key={item.label}>
                                        <TableCell >
                                            <Checkbox
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setIssue(prev => [
                                                            ...prev,
                                                            item.label
                                                        ])
                                                    } else {
                                                        setIssue(prev => prev.filter(i => i !== item.label))
                                                    }
                                                }}
                                                checked={
                                                    issue.includes(item.label)
                                                }
                                            />
                                            {item.label}
                                        </TableCell>
                                        {
                                            appNotWorking[idx] && (
                                                <TableCell>
                                                    <Checkbox onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setIssue(prev => [
                                                                ...prev,
                                                                appNotWorking[idx]?.label
                                                            ])
                                                        } else {
                                                            setIssue(prev => prev.filter(i => i !== appNotWorking[idx]?.label))
                                                        }
                                                    }}
                                                        checked={
                                                            issue.includes(appNotWorking[idx]?.label)
                                                        } />
                                                    {
                                                        appNotWorking[idx]?.label
                                                    }
                                                </TableCell>
                                            )
                                        }

                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <p>
                        Other (Please describe below)
                    </p>
                    <TextareaAutosize className="outline-none focus:border-green-500 transition-all px-2 py-1 duration-100 ease-in-out focus:border-2 rounded-md bg-transparent border border-gray-500" minRows={4}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        value={description}
                    />

                </div>
                {
                    showSuccess && (
                        <div className="flex items-center gap-2 py-2">
                            <Check />
                            <p className=" text-blue-600 font-bold">
                                Your ticket has been submitted successfully. We will get back to you as soon as possible.
                            </p>
                        </div>
                    )
                }
                <Button variant="contained" onClick={() => {
                    mutate()
                }}>
                    {
                        isLoading ? "Submitting..." : "Open Ticket"
                    }
                </Button>
            </div>
        </div>
    )
}