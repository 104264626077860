import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Tooltip,
  Grid,
  Card,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Lessons } from "../../../const/lessons";
import { IPrincipal, ISchool, IStudent, ITeacher } from "interfaces";
import moment from "moment";
import { StudentType } from "utils/type";

type ReportCardType = {
  index: number;
  onClick: (school: IStudent | null) => void;
};

export const StudentReportList: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const [student, setStudent] = useState<StudentType | null>(null);
  const { autocompleteProps } = useAutocomplete<StudentType>({
    resource: "student",
  });
  return (
    <Grid
      container
      padding={4}
      gap={5}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Card
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
          Individual Progress Reports
        </Typography>
        <Autocomplete
          size="small"
          {...autocompleteProps}
          value={student}
          onChange={(_, value) => {
            setStudent(value);
          }}
          getOptionLabel={(item) => {
            return `${item.firstName} ${item.lastName} (${item.name ?? ""})`;
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || option.id === value.id
          }
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" required />
          )}
        />
        <Button
          style={{ alignSelf: "flex-end" }}
          onClick={() => {
            if (student?.id) {
              push(`${showUrl("studentReport", student?.id)}`);
            }
          }}
        >
          Generate
        </Button>
      </Card>
      {/*<Card sx={{flex:1}}>*/}

      {/*</Card>*/}
    </Grid>
  );
};
