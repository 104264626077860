import React, { useState } from "react";
import {
    IResourceComponentsProps,
    useNavigation,
} from "@pankod/refine-core";
import {
    Typography,
    Grid,
    Card,
    Button,
    useAutocomplete,
    Autocomplete,
    TextField,
} from "@pankod/refine-mui";
import { StudentType } from "utils/type";


export const CheckpointStudentReportList: React.FC<IResourceComponentsProps> = () => {
    const { showUrl, push } = useNavigation();

    const [student, setStudent] = useState<StudentType | null>(null);
    const { autocompleteProps } = useAutocomplete<StudentType>({
        resource: "student",
    });
    return (
        <Grid
            container
            padding={4}
            gap={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <Card
                style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                }}
            >
                <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
                    Individual Checkpoint Report
                </Typography>
                <Autocomplete
                    size="small"
                    {...autocompleteProps}
                    value={student}
                    onChange={(_, value) => {
                        setStudent(value);
                    }}
                    getOptionLabel={(item) => {
                        return `${item.firstName} ${item.lastName} (${item.name ?? ""})`;
                    }}
                    isOptionEqualToValue={(option, value) =>
                        value === undefined || option.id === value.id
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" required />
                    )}
                />
                <Button
                    style={{ alignSelf: "flex-end" }}
                    onClick={() => {
                        if (student?.id) {
                            push(`${showUrl("chreport", student?.id)}`);
                        }
                    }}
                >
                    Generate
                </Button>
            </Card>
        </Grid>
    );
};
