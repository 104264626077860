import React, { useEffect } from "react";
import axios from "axios";
import { ContentCopyOutlined } from "@mui/icons-material";
import {
  IResourceComponentsProps,
  useTranslate,
  useApiUrl,
  HttpError,
  useNavigation,
  useRouterContext,
  useNotification,
  useDataProvider,
  useShow,
  useGetIdentity,
  useMany,
  BaseKey,
  useOne,
} from "@pankod/refine-core";
import {
  Controller,
  UseModalFormReturnType,
  useForm,
} from "@pankod/refine-react-hook-form";
import {
  Drawer,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Avatar,
  Typography,
  FormLabel,
  Stack,
  Box,
  IconButton,
  FormControl,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Edit,
  useAutocomplete,
  TextField,
  Button,
} from "@pankod/refine-mui";
import { CloseOutlined } from "@mui/icons-material";

import { IUnifiedUser, IStudent, IPrincipal, ITeacher, ISchool } from "interfaces";
import { makeRandom } from "utils/string";
import { createAxios } from "utils/customAxios";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = process.env.REACT_APP_BACKEND;
  const { show } = useNavigation();
  const { queryResult } = useShow();
  const {
    refineCore: { onFinish, formLoading, id },
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    saveButtonProps,
  } = useForm<
    IUnifiedUser & { teacherId: number },
    HttpError,
    IUnifiedUser & { teacherId: number }
  >({
    refineCoreProps: {
      redirect: false,
      onMutationSuccess(data, variables, context) {
        if (data.data.userType === "student") {
          show("student", data.data.id);
        } else {
          // show("teacher", data.data.id)
          // if (data.data.principality?.id) {
          //     show("principal", data.data.id)
          // } else {
          //     show("teacher", data.data.id)
          // }
        }
      },
    },
  });

  const imageInput = watch("photo");
  const photoURL = watch("photoURL");
  const userType = watch("userType");
  const schoolId = watch("school.id");
  const uname = watch("uname");
  const email = watch("email");

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formData = new FormData();

    const target = event.target;
    const file: File = (target.files as FileList)[0];

    formData.append("file", file);

    const res = await createAxios(apiUrl).post(`/media/upload`, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    const { name, size, type, lastModified } = file;

    const imagePaylod: any = [
      {
        name,
        size,
        type,
        lastModified,
        url: res.data.url,
      },
    ];

    setValue("photo", imagePaylod, { shouldValidate: true });
  };
  const { data: user } = useGetIdentity();
  const teachers = useOne({
    resource: "teacher",
    id: queryResult.data?.data.teacherId,
    errorNotification: false,
    queryOptions: {
      enabled: user && user.userType !== "parent",
    }
  });
  const { autocompleteProps: teacherAutoComplete } = useAutocomplete<ITeacher>({
    resource: "teacher",
    filters: [
      {
        field: "school",
        operator: "eq",
        value: queryResult.data?.data.schoolId,
      },
    ],
    errorNotification: false,
    defaultValue: teachers.data?.data[0] as BaseKey,
    queryOptions: {
      enabled: user && user.userType !== "parent",
    }
  });

  const { autocompleteProps: schoolAutoComplete } = useAutocomplete<ISchool>({
    resource: "school",
    errorNotification: false
  });


  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Stack>
        <Box
          paddingX="50px"
          justifyContent="center"
          alignItems="center"
          sx={{
            paddingX: {
              xs: 1,
              md: 6,
            },
          }}
        >
          <form onSubmit={handleSubmit(onFinish)}>
            <Stack gap="40px" direction={"row"}>
              <FormControl sx={{ width: "40%" }}>
                <FormLabel>Profile Image</FormLabel>
                <Stack
                  display="flex"
                  alignItems="center"
                  border="1px dashed  "
                  borderColor="primary.main"
                  borderRadius="5px"
                  padding="10px"
                  marginTop="5px"
                >
                  <label htmlFor="images-input">
                    <Input
                      id="images-input"
                      type="file"
                      sx={{
                        display: "none",
                      }}
                      onChange={onChangeHandler}
                    />
                    <input id="file" {...register("photo")} type="hidden" />
                    <input {...register("photoURL")} type="hidden" />
                    <Avatar
                      sx={{
                        cursor: "pointer",
                        width: {
                          xs: 100,
                          md: 180,
                        },
                        height: {
                          xs: 100,
                          md: 180,
                        },
                      }}
                      src={(imageInput && imageInput[0].url) || photoURL}
                      alt="Photo URL"
                    />
                  </label>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 800,
                      marginTop: "8px",
                    }}
                  >
                    Upload the photo of this user
                  </Typography>
                </Stack>
                {errors.photoURL && (
                  <FormHelperText error>
                    {errors.photoURL.message}
                  </FormHelperText>
                )}
              </FormControl>

              <Stack flex={1} gap="10px" marginTop="10px">
                {
                  user?.userType !== "parent" && (
                    <>
                      <FormControl>
                        <FormLabel required>First Name</FormLabel>
                        <OutlinedInput
                          id="firstName"
                          {...register("firstName", {
                            required: "First name is required",
                          })}
                          style={{ height: "40px" }}
                        />
                        {errors.firstName && (
                          <FormHelperText error>
                            {errors.firstName.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel required>Last name</FormLabel>
                        <OutlinedInput
                          id="lastName"
                          {...register("lastName", {
                            required: "Last name is required",
                          })}
                          style={{ height: "40px" }}
                        />
                        {errors.lastName && (
                          <FormHelperText error>
                            {errors.lastName.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )
                }

                <input type={"hidden"} {...register("userType")} />
                <input type={"hidden"} {...register("school")} />
                {!(userType?.length > 0) && (
                  <>
                    <input type={"hidden"} {...register("email")} />
                    <input type={"hidden"} {...register("uname")} />
                  </>
                )}

                {(userType === "teacher" ||
                  userType === "admin" ||
                  userType === "principal") && (
                    <FormControl
                      fullWidth
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <FormLabel required>Email</FormLabel>
                      <OutlinedInput
                        id="email"
                        {...register("email", {
                          required: "Email is required!",
                          validate: async (email) => {
                            const result = await createAxios(apiUrl).get(
                              `/admin/exists/${email}?id=${id}`
                            );
                            if (result.data.count > 0) {
                              return "Email already used!";
                            } else {
                              return true;
                            }
                          },
                        })}
                        type="email"
                        value={email}
                        style={{ height: "40px" }}
                      />
                      {errors.email && (
                        <FormHelperText error>
                          {errors.email.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                {userType === "student" && (
                  <FormControl>
                    <FormLabel required>Grade</FormLabel>
                    <OutlinedInput
                      id="grade"
                      type={"number"}
                      {...register("grade", {
                        required: "Grade is required",
                      })}
                      style={{ height: "40px" }}
                      defaultValue={queryResult.data?.data.grade}
                    />
                    {errors.grade && (
                      <FormHelperText error>
                        {errors.grade.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                {userType === "student" && (
                  <FormControl
                    fullWidth
                    style={{
                      marginTop: 20,
                    }}
                  >
                    <FormLabel required>User name</FormLabel>
                    <OutlinedInput
                      id="uname"
                      {...register("uname", {
                        required: "User name is required!",
                        validate: async (uname) => {
                          const result = await createAxios(apiUrl).get(
                            `/admin/student/exists/${uname}?id=${id}&school=${schoolId}`
                          );
                          if (result.data.count > 0) {
                            return "User name already used!";
                          } else {
                            return true;
                          }
                        },
                      })}
                      value={uname}
                      style={{ height: "40px" }}
                    />
                    {errors.uname && (
                      <FormHelperText error>
                        {errors.uname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                {/* {
                  userType === "admin" && <FormControl fullWidth>
                    <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      School
                    </FormLabel>
                    <Controller
                      control={control}
                      name="school"
                      rules={{
                        required: "School required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          size="small"
                          {...schoolAutoComplete}
                          {...field}
                          value={field.value || null}
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          getOptionLabel={(item) => {
                            return item.name
                              ? item.name
                              : "";
                          }}
                          isOptionEqualToValue={(
                            option,
                            value,
                          ) =>
                            value === undefined ||
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={
                                !!errors.school
                                  ?.message
                              }
                              required
                            />
                          )}
                        />
                      )}
                    />
                    {errors.school && (
                      <FormHelperText error>
                        {errors.school.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                } */}

                {userType === "student" && user.userType !== "teacher" && (
                  <FormControl fullWidth>
                    <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      Teacher
                    </FormLabel>
                    <Controller
                      control={control}
                      name="teacherId"
                      defaultValue={queryResult.data?.data.teacherId}
                      render={({ field }) => (
                        <Autocomplete
                          size="small"
                          {...teacherAutoComplete}
                          {...field}
                          value={teacherAutoComplete.options.find(
                            (option) => option.id === field.value
                          )}
                          disabled={!queryResult.data?.data.schoolId}
                          onChange={(_, value) => {
                            field.onChange(value?.id);
                          }}
                          getOptionLabel={(item) => {
                            return item?.firstName + " " + item?.lastName;
                          }}
                          isOptionEqualToValue={(option, value) =>
                            value === undefined || option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={!!errors.teacher?.message}
                              required
                            />
                          )}
                        />
                      )}
                    />
                    {errors.teacher && (
                      <FormHelperText error>
                        {errors.teacher.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <FormLabel required>New Password</FormLabel>
                  <OutlinedInput
                    id="password"
                    {...register("password", {})}
                    type="string"
                    style={{ height: "40px" }}
                  />
                  {errors.password && (
                    <FormHelperText error>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                  {
                    user?.userType !== "parent" && (
                      <FormHelperText>
                        Ignore this field if you don't want to change the user's
                        password.
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Edit>
  );
};
