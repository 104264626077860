import React, { useState } from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useDelete,
    useNavigation, useGetIdentity,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Tooltip,
  Grid, Card, Button, useAutocomplete,Autocomplete, TextField
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import {Lessons} from "../../../const/lessons"
import { IPrincipal, ISchool, ITeacher } from "interfaces";
import moment from "moment";

type ReportCardType = {
  index: number;
  onClick: (school: ISchool | null) => void;
    userType: string;
}

const ReportCard: React.FC<ReportCardType> = ({ index, onClick, userType }) => {
  const [school, setSchool] = useState<ISchool | null>(null)

  const { autocompleteProps } = useAutocomplete<ISchool>({
    resource: "school"
  });
 return <Card sx={{ padding: 1 }}>
    <Typography variant={"h6"} style={{fontWeight:"bold"}}>Lesson {index + 1}</Typography>
    <br />
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        {userType == "admin" ?
        <Autocomplete
        size="small"
        {...autocompleteProps}
        value={school}
        onChange={(_, value) => {
          setSchool(value);
        }}
        getOptionLabel={(item) => {
          return item.name
            ? item.name
            : "";
        }}
        isOptionEqualToValue={(
          option,
          value,
        ) =>
          value === undefined ||
          option.id === value.id
        }
        sx={{flex: 0.7}}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            required
          />
        )}
      />: <div style={{flex:1}}/>}
      <Button onClick={() => onClick(school)}>Generate</Button>
    </div>

  </Card>
}

export const CTLessonReportList: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();
    const { data: user } = useGetIdentity();


  return (
    <List cardProps={{
        sx: { paddingX: { xs: 2, md: 0 } }
    }}
          cardHeaderProps={{
                  title: "Lesson Reports"
              }}
    >
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Array.from(Array(40)).map((_, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <ReportCard userType={user?.userType || "principal"} index={index} onClick={(sc)=>{
                if(sc?.id){
                    push(`${showUrl("lessonReportCT", index+1)}?school=${sc?.id}`)

                }else{
                    push(`${showUrl("lessonReportCT", index+1)}`)

                }

            }}/>
          </Grid>
        ))}
      </Grid>
    </List>
  );
};