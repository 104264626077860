import {
  useMany,
  useNavigation,
  useQuery,
  useResource,
  useShow,
} from "@pankod/refine-core";
import { Layout } from "@pankod/refine-mui";
import { PrincipalDashboard } from "components/principal-dashboard";
import { ISchool } from "interfaces";
import { useEffect, useState } from "react";

export const SchoolReport = () => {
  const [id, setId] = useState("");
  const {
    queryResult: { data },
  } = useShow<ISchool>({
    id,
    resource: "school",
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("id");
    setId(param ?? "");
  }, []);

  const { push } = useNavigation();

  return (
    <div>
      <Layout>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            push("/");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M7.05 9.293L6.343 10L12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"
            />
          </svg>
          <h2>{data?.data.name} Report</h2>
        </div>
        <PrincipalDashboard id={id} />
      </Layout>
    </div>
  );
};
