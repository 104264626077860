import { Add, CalendarMonth, PlusOne, PrintRounded, RemoveCircleOutline } from "@mui/icons-material";
import { useGetIdentity, useMutation, useNavigation } from "@pankod/refine-core";
import { Autocomplete, Button, Card, Grid, TextField, Typography, useAutocomplete } from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { ISchool, ITeacher } from "interfaces";
import { useState } from "react";

import { DateRangePicker } from 'react-dates';
import { flushSync } from "react-dom";
import { $fetch } from "utils/fetch";
import { exportAsPdf } from "utils/utils";

interface Teacher {
    id: string
    uname: any
    email: string
    phone: any
    password: string
    masId: any
    userType: string
    firstName: string
    lastName: string
    photoURL: any
    grade: any
    user_status: string
    schoolId: string
    teacherId: any
    parentId: any
    accountLocked: boolean
    availableLessons: any
    created_at: string
    updated_at: string
    introductionPresented: boolean
    ctVideoPresented: boolean
    guidedPracticePresented: boolean
    last_active: string
}
export interface SchoolRepAgg {
    "average_minutes": number,
    "total_minutes": number[],
    "median_lesson": number,
    "lessons": number[]
}
export interface Report {
    [key: string]: SchoolRepAgg
}

export const TeacherReportList = () => {
    const [searchDateRange, setSearchDateRange] = useState<any[]>([])
    const [focusedInput, setFocusedInput] = useState(null)
    const { push, show, showUrl } = useNavigation()
    const [data, setData] = useState<{
        report: Report,
        teacher: ITeacher
    }[]>()
    const { mutate } = useMutation({
        mutationFn: async () => {
            const promises = teacherBox.filter(t => t !== null).map(async teacher => {
                const res = await $fetch<Report>(`/admin/weeklyFluencyReport/fluency?teacherId=${teacher?.id}&start=${searchDateRange[0]?.format("YYYY-MM-DD")
                    }&end=${searchDateRange[1]?.format("YYYY-MM-DD")}
        `)
                return {
                    report: res.data as Report,
                    teacher: teacher!
                }
            })

            const res = await Promise.all(promises)

            return res
        },
        onSuccess(res) {

            setData(res)
        },
    })
    const [teacherBox, setTeacherBox] = useState<(ITeacher | null)[]>([null])

    const { autocompleteProps: schoolAutoCompleteProps } = useAutocomplete<ISchool>({
        resource: "school",
    });
    const [school, setSchool] = useState<ISchool | null>(null)
    const { autocompleteProps } = useAutocomplete<ITeacher>({
        resource: "teacher",
        filters: [{
            field: "school",
            value: school?.id,
            operator: "eq"
        }]
    });

    const { data: user } = useGetIdentity()

    return (
        <Grid
            container
            padding={4}
            gap={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <div className="flex bg-white rounded-md flex-col gap-4 px-8 py-4 h-max">
                <h1 className="font-bold text-xl">Generate Teacher Report List</h1>

                {
                    user?.userType === "admin" && <div>
                        <p className="font-bold">
                            Select School
                        </p>
                        <Autocomplete
                            size="small"
                            {...schoolAutoCompleteProps}
                            value={school}
                            onChange={(_, value) => {
                                setSchool(value)
                            }}
                            getOptionLabel={(item) => {
                                return item.name
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || option.id === value.id
                            }
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" required />
                            )}
                        />
                    </div>
                }
                <p className="font-bold">
                    Select Teachers
                </p>
                {
                    teacherBox.map((teacher, index) => {
                        return (
                            <div key={index.toString()} className="flex items-center gap-2">
                                <Autocomplete
                                    size="small"
                                    {...autocompleteProps}
                                    value={teacher}
                                    onChange={(_, value) => {
                                        setTeacherBox(teacherBox.map((t, i) => i === index ? value : t))
                                    }}
                                    getOptionLabel={(item) => {
                                        return `${item.firstName} ${item.lastName}`;
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined || option.id === value.id
                                    }
                                    style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" required />
                                    )}
                                />
                                <RemoveCircleOutline
                                    className="cursor-pointer" onClick={() => {
                                        setTeacherBox(teacherBox.filter((_, i) => i !== index))
                                    }} />
                            </div>
                        )
                    })
                }

                <div className="border flex items-center gap-2 rounded-md px-3 py-2" onClick={() => {
                    setTeacherBox([...teacherBox, null])
                }}>
                    <Add />
                    Add Teacher
                </div>
                <p>
                    Select Date Range
                </p>
                <DateRangePicker
                    startDate={searchDateRange[0]} // momentPropTypes.momentObj or null,
                    startDateId="uStId" // PropTypes.string.isRequired,
                    endDate={searchDateRange[1]} // momentPropTypes.momentObj or null,
                    endDateId="uEnD" // PropTypes.string.isRequired,
                    disabled={false}
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }: {
                        startDate: any;
                        endDate: any;
                    }) => setSearchDateRange([startDate, endDate])} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(inp: any) => { setFocusedInput(inp) }} // PropTypes.func.isRequired,
                />
                <Button variant="contained" style={{ alignSelf: "flex-start" }} onClick={() => {
                    mutate()
                }}>
                    Generate
                </Button>
            </div>
            <Card sx={{ flex: 1, padding: 2 }}>
                <div className="flex justify-end items-center gap-2 cursor-pointer" onClick={() => {
                    exportAsPdf("#teacher-report", "teacher-report")
                }}>
                    <PrintRounded className="text-green-700" />
                    <p className="text-green-600">
                        Print
                    </p>
                </div>
                <div id="teacher-report" className="py-4">
                    <div className="flex items-center gap-2 px-4" >
                        <CalendarMonth />
                        <p className="font-bold">
                            {searchDateRange[0]?.format("YYYY-MM-DD")} to {searchDateRange[1]?.format("YYYY-MM-DD")} Report
                        </p>
                    </div>
                    {
                        data && <div className="flex border-t border-stone-600 mt-4 p-4 flex-col gap-4">
                            {
                                data.map((d, index) => {
                                    const report = d.report
                                    const teacher = d.teacher
                                    console.log({ report, d })
                                    return (
                                        <div className="border border-stone-300 rounded-md px-4 py-2">
                                            <h2 className="font-bold">
                                                {teacher.firstName} {teacher.lastName}
                                            </h2>
                                            {
                                                Object.keys(report || {}).map((key) => {
                                                    return (
                                                        <div key={key}>
                                                            <h3 className="font-bold">
                                                                Grade {key} Report
                                                            </h3>
                                                            <h1>
                                                                - {report?.[key].total_minutes.length} students used Read 21 in this time period
                                                            </h1>
                                                            <h1>
                                                                - average time of {report?.[key].average_minutes} minutes
                                                            </h1>
                                                            <h1>
                                                                - Median  lesson {report?.[key].median_lesson}
                                                            </h1>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </div>
            </Card>
        </Grid>
    )

}