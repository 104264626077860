import { useList } from "@pankod/refine-core";
import { AddStudentModal } from "components/add-student-modal";

export const ParentDashboard = () => {
    return (
        <div>
            <div className="flex justify-end w-full">
                <a href="https://www.youtube.com/watch?v=J9d2Vzul-Ck&t=26s" className="font-bold text-xl text-blue-600 underline" target="_blank" rel="noreferrer">
                    Student Login Process
                </a>
            </div>
            <div className="h-full flex flex-col items-center">

                <img height={300} width={300} src={"/images/read21.svg"} className="mt-24" alt="" />
                <div className="flex flex-col items-center justify-center">
                    <p className="text-blue-700 text-4xl text-center max-w-3xl font-bold">
                        Welcome to Read 21’s Parent Portal!
                    </p>
                    <p className="text-2xl bg-gradient-to-tr from-yellow-400 to-green-800 bg-clip-text text-center max-w-3xl">
                        Here, you will see and hear how your child is doing in both our programs-Fluency and Critical Thinking.
                    </p>
                </div>
                <AddStudentModal />
                <div className="mt-2">
                    <a href="https://read21.org/explainer/" className="text-blue-600 font-bold underline" target="_blank" rel="noreferrer">
                        Helpful Links
                    </a>
                </div>
            </div>
        </div>
    )
}