import moment from "moment";

type DateProps = {
    date: moment.MomentInput;
};

export const DurationDate: React.FC<DateProps> = ({date}) => {
    const dt = moment(date);
    return <>
    {dt.format('L')}<br/><b>({dt.fromNow()})</b>
    </>
}