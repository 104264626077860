import {
    HttpError,
    IResourceComponentsProps,
    useApiUrl,
    useTranslate
} from "@pankod/refine-core";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
    Edit,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    FormHelperText,
    Box, useAutocomplete, Autocomplete
} from "@pankod/refine-mui";

import { ISchool, IState } from "interfaces";
import { createAxios } from "utils/customAxios";
import React from "react";

export const SchoolEdit: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const {
        register,
        control,
        refineCore: { formLoading, id },
        formState: { errors },
        saveButtonProps,
    } = useForm<ISchool, HttpError, ISchool>();
    const { autocompleteProps } = useAutocomplete<IState>({
        resource: "state",
    });
    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
                autoComplete="off"
            >
                <>
                    <Grid
                        container
                        sx={{
                            marginX: { xs: "10px" },
                        }}
                    >

                        <Grid item xs={12} md={12}>
                            <Grid container >
                                <Grid item xs={12} sx={{ paddingX: "10px" }} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel
                                            required
                                            sx={{
                                                marginBottom: "8px",
                                                fontWeight: "700",
                                                fontSize: "14px",
                                                color: "text.primary",
                                            }}
                                        >
                                            School Code
                                        </FormLabel>
                                        <TextField
                                            {...register("code", {
                                                required: "School Code required!",
                                                pattern: {
                                                    value: /^[A-Z0-9a-z]*$/i,
                                                    message: "School Code must not have space and must be A-Z + 0-9 + a-z",
                                                },
                                                min: 3,
                                                max: 10,
                                                validate: async (code) => {
                                                    const result = await createAxios(apiUrl).get(`/school/exists/${code}?school=${id}`);
                                                    if (result.data.count > 0) {
                                                        return "Code already used!"
                                                    } else {
                                                        return true
                                                    }
                                                }
                                            })}
                                            size="small"
                                            margin="none"
                                            variant="outlined"
                                            inputProps={{ style: { textTransform: "lowercase" } }}
                                        />
                                        {errors.code && (
                                            <FormHelperText error>
                                                {errors.code.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingX: "10px" }} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel
                                            required
                                            sx={{
                                                marginBottom: "8px",
                                                fontWeight: "700",
                                                fontSize: "14px",
                                                color: "text.primary",
                                            }}
                                        >
                                            School Name
                                        </FormLabel>
                                        <TextField
                                            {...register("name", {
                                                required: "School Name required!",
                                            })}
                                            size="small"
                                            margin="none"
                                            variant="outlined"
                                        />
                                        {errors.name && (
                                            <FormHelperText error>
                                                {errors.name.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingX: "10px", marginTop: 2 }} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel
                                            required
                                            sx={{
                                                marginBottom: "8px",
                                                fontWeight: "700",
                                                fontSize: "14px",
                                                color: "text.primary",
                                            }}
                                        >
                                            State
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name="state"
                                            rules={{
                                                required: "School Required!",
                                            }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    {...autocompleteProps}
                                                    {...field}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value || null}
                                                    getOptionLabel={(item) => {
                                                        return item.name
                                                            ? item.name
                                                            : "";
                                                    }}
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value,
                                                    ) =>
                                                        value === undefined ||
                                                        option.code === value.code
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="State"
                                                            variant="outlined"
                                                            error={
                                                                !!errors.state?.message
                                                            }
                                                            required
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        {errors.state && (
                                            <FormHelperText error>
                                                {errors.state.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Box>
        </Edit>
    );
};