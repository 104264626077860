import React, { useState } from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useDelete,
    useNavigation, useGetIdentity, useInvalidate, useNotification,
} from "@pankod/refine-core";
import {
    DataGrid,
    useDataGrid,
    GridColumns,
    GridActionsCellItem,
    List,
    Stack,
    Avatar,
    Typography,
    Tooltip, styled, Badge, Box, Button, GridToolbar, Input, GridRenderCellParams, Link,
    Select,
    MenuItem,
    useAutocomplete,
    Autocomplete,
    TextField,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
// @ts-ignore
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

import { IPrincipal, ISchool, IStudent, IStudentSession, ITeacher, IUser } from "interfaces";
import moment from "moment";
import { SchoolInfoText } from "pages/school";
import {
    LocalPhoneOutlined,
    MapOutlined,
    DirectionsCarFilledOutlined,
    EmailOutlined,
    AccountBalanceOutlined,
    StoreOutlined,
    AdminPanelSettings,
    CalendarViewMonthOutlined,
    AssignmentInd,
    AutoDelete,
    ChildCare,
    EventAvailable
} from "@mui/icons-material";
import UserAvatar from "../../components/custom";
import { createAxios } from "../../utils/customAxios";
import { ProgressItem } from "../../components/fieldItems";
import { GridColumnGroupingModel } from "@mui/x-data-grid/models/gridColumnGrouping";
import { AgInputRange } from "ag-grid-community";

export const TimeActivityFlReport: React.FC<IResourceComponentsProps> = () => {
    const { show, create } = useNavigation();
    const { data: user } = useGetIdentity();
    const notification = useNotification();
    const invalidate = useInvalidate()
    const { mutate: mutateDelete } = useDelete();
    const [focusedInput, setFocusedInput] = useState(null)
    const [searchDateRange, setSearchDateRange] = useState<any[]>([])
    const [search, setSearch] = React.useState<string>("");

    // @ts-ignore
    const { dataGridProps, setFilters } = useDataGrid<IStudent>({
        initialPageSize: 10,

        permanentFilter: [{
            // @ts-ignore
            field: "title_like",
            operator: "eq",
            value: search?.length > 0 ? search : undefined,
        },
        {
            field: "date_between",
            operator: "between",
            value: searchDateRange?.length > 0 ? searchDateRange : undefined,
        }

        ],
        initialSorter: [
            {
                field: "last_active",
                order: "desc",
            },
        ],
    });

    const columnGroupModel = React.useMemo<GridColumnGroupingModel>(
        () => [
            {
                groupId: 'Usage in Minutes',
                renderHeaderGroup() {
                    return <Box sx={{ display: "flex", flexDirection: "row", py: 10, gap: 20 }}>
                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>Usage / Activity</Typography>
                    </Box>
                },
                children: [

                    {
                        field: "last_day_minutes"
                    },
                    {
                        field: "last_week_minutes"
                    },

                    {
                        field: "last_month_hour"
                    },
                    {
                        field: "last_year_hour"
                    },
                ]
            },
            {
                groupId: 'Lessons Passed (YTD)',
                renderHeaderGroup() {
                    return <Box sx={{ display: "flex", flexDirection: "row", py: 10, gap: 20 }}>
                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>Lessons Details (YTD):</Typography>
                    </Box>
                },
                children: [

                    {
                        field: "passedCompleted",
                    },
                    {
                        field: "average_score"
                    }
                ]
            }

        ],
        []
    );

    const columns = React.useMemo<GridColumns<IStudentSession & IUser>>(
        () => [
            {
                field: "firstName",
                headerName: "Full Name",
                renderHeader: () => (
                    <Box sx={{ display: "flex", flexDirection: "column", py: 10 }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <Input onChange={(e) => {
                            setSearch(e.currentTarget.value!)
                        }} placeholder={"Student Name"} style={{ margin: 0 }} />
                    </Box>
                ),
                minWidth: 200,
                flex: 1,

                renderCell(params) {
                    return <UserAvatar user={params.row} />
                }
            },
            ...(user?.userType === "admin" ? [{
                field: "school",
                headerName: "School",
                flex: 0.7,
                renderCell(params) {
                    return (params.row as any).school?.name
                },
            }] : []),
            ...((user?.userType === "admin" || user?.userType === "principal") ? [{
                field: "teacher.name",
                headerName: "Teacher",
                flex: 0.7,
                renderCell(params) {
                    const row = params.row as any
                    return `${row?.teacher?.firstName} ${row?.teacher?.lastName}`
                },
            },
            {
                field: "grade",
                headerName: "Grade",
                flex: 0.7,
                renderCell(params) {
                    const row = params.row as any
                    return row?.grade
                },
            }] : []),
            {
                field: "last_day_minutes",
                headerName: "Day",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_day_minutes))} />
                }
            },
            {
                field: "last_week_minutes",
                headerName: "Week",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_week_minutes))} />

                }
            },
            {
                field: "last_month_hour",
                headerName: "Month",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_month_hour) * 60)} />

                }
            },
            {
                field: "last_year_hour",
                headerName: "Year",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_year_hour) * 60)} />
                }
            },
            // {
            //     field: "lastPassed",
            //     headerName: "Last Passed (YTD)",
            //     minWidth: 160,
            //     flex: 1,
            //     renderCell(params) {
            //         const teacher = params.row.teacher;
            //         return <><Avatar sx={{ width: 24, height: 24 }} src={teacher?.photoURL} alt={teacher?.firstName} />
            //             <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
            //                 {teacher?.firstName}
            //             </Typography></>
            //     }
            // },
            {
                field: "passedCompleted",
                headerName: "Completed / Passed",
                minWidth: 160,
                flex: 1,
                renderCell(params) {
                    return <span>{params.row.completed_lessons}<b> Total</b><br />{"\n"}{params.row.passed_lessons}<b> Passed</b></span>
                }
            },
            {
                field: "average_score",
                headerName: "%Average Grade",
                minWidth: 160,

                flex: 1,
                renderCell(params) {
                    if (!params.row.average_score) {
                        return null
                    }
                    return <>{(params.row.average_score * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</>
                }
            }
        ],
        []
    );

    return (
        <List title={"Fluency Time / Activity Report"} cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
            headerButtons={(context) => {
                return <>
                    <DateRangePicker
                        startDate={searchDateRange[0]} // momentPropTypes.momentObj or null,
                        startDateId="uStId" // PropTypes.string.isRequired,
                        endDate={searchDateRange[1]} // momentPropTypes.momentObj or null,
                        endDateId="uEnD" // PropTypes.string.isRequired,
                        disabled={false}
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }: {
                            startDate: any;
                            endDate: any;
                        }) => setSearchDateRange([startDate, endDate])} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(inp: any) => { setFocusedInput(inp) }} // PropTypes.func.isRequired,
                    />
                    {context.defaultButtons}
                </>
            }}
        >
            {//Material Input box with onchange callback function
            }
            <AdvancedFilter setFilters={setFilters} />

            <DataGrid
                {...dataGridProps}
                sortingMode={"client"}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupModel}

                autoHeight

                rowsPerPageOptions={[10, 20, 50, 100]}
                density="comfortable"
                sx={{
                    "& .MuiDataGrid-cell:hover": {
                        cursor: "pointer",
                    },
                }}
                onCellClick={(cell) => {
                    if (cell.colDef.field == "school") {
                        show("school", cell.row.school.id)

                    } else if (cell.colDef.field == "teacher") {
                        show("teacher", cell.value)

                    } else {
                        show("student", cell.row.id)
                    }

                }}
            />
        </List>
    );
};

export const TimeActivityCTReport: React.FC<IResourceComponentsProps> = () => {
    const { show, create } = useNavigation();
    const { data: user } = useGetIdentity();
    const notification = useNotification();
    const invalidate = useInvalidate()
    const { mutate: mutateDelete } = useDelete();
    const [focusedInput, setFocusedInput] = useState(null)
    const [searchDateRange, setSearchDateRange] = useState<any[]>([])
    const [search, setSearch] = React.useState<string>("");

    // @ts-ignore
    const { dataGridProps, setFilters } = useDataGrid<IStudent>({
        initialPageSize: 10,
        permanentFilter: [{
            // @ts-ignore
            field: "title_like",
            operator: "eq",
            value: search?.length > 0 ? search : undefined,
        },
        {
            field: "date_between",
            operator: "between",
            value: searchDateRange?.length > 0 ? searchDateRange : undefined,
        }

        ],
        initialSorter: [
            {
                field: "last_active",
                order: "desc",
            },
        ],
    });

    const columnGroupModel = React.useMemo<GridColumnGroupingModel>(
        () => [
            {
                groupId: 'Usage in Minutes',
                renderHeaderGroup() {
                    return <Box sx={{ display: "flex", flexDirection: "row", py: 10, gap: 20 }}>
                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>Usage / Activity</Typography>
                    </Box>
                },
                children: [

                    {
                        field: "last_day_minutes"
                    },
                    {
                        field: "last_week_minutes"
                    },

                    {
                        field: "last_month_hour"
                    },
                    {
                        field: "last_year_hour"
                    },
                ]
            },
            {
                groupId: 'Lessons Passed (YTD)',
                renderHeaderGroup() {
                    return <Box sx={{ display: "flex", flexDirection: "row", py: 10, gap: 20 }}>
                        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>Lessons Details (YTD):</Typography>
                    </Box>
                },
                children: [

                    {
                        field: "passedCompleted",
                    },
                    {
                        field: "average_score"
                    }
                ]
            }

        ],
        []
    );


    const columns = React.useMemo<GridColumns<IStudentSession & IUser>>(
        () => [
            {
                field: "firstName",
                headerName: "Full Name",
                renderHeader: () => (
                    <Box sx={{ display: "flex", flexDirection: "column", py: 10 }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <Input onChange={(e) => {
                            setSearch(e.currentTarget.value!)
                        }} placeholder={"Student Name"} style={{ margin: 0 }} />
                    </Box>
                ),
                minWidth: 200,
                flex: 1,

                renderCell(params) {
                    return <UserAvatar user={params.row} />
                }
            },
            ...(user?.userType === "admin" ? [{
                field: "school",
                headerName: "School",
                flex: 0.7,
                renderCell(params) {
                    return (params.row as any).school?.name
                },
            }] : []),
            ...((user?.userType === "admin" || user?.userType === "principal") ? [{
                field: "teacher.name",
                headerName: "Teacher",
                flex: 0.7,
                renderCell(params) {
                    const row = params.row as any
                    return `${row?.teacher?.firstName} ${row?.teacher?.lastName}`
                },
            },
            {
                field: "grade",
                headerName: "Grade",
                flex: 0.7,
                renderCell(params) {
                    const row = params.row as any
                    return row?.grade
                },
            }] : []),
            {
                field: "last_day_minutes",
                headerName: "Day",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_day_minutes))} />
                }
            },
            {
                field: "last_week_minutes",
                headerName: "Week",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_week_minutes))} />

                }
            },
            {
                field: "last_month_hour",
                headerName: "Month",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_month_hour) * 60)} />

                }
            },
            {
                field: "last_year_hour",
                headerName: "Year",
                flex: 0.7,
                minWidth: 60,
                renderCell(v) {
                    return <MomentItem minutes={Math.floor(Number.parseFloat(v.row.last_year_hour) * 60)} />
                }
            },
            // {
            //     field: "lastPassed",
            //     headerName: "Last Passed (YTD)",
            //     minWidth: 160,
            //     flex: 1,
            //     renderCell(params) {
            //         const teacher = params.row.teacher;
            //         return <><Avatar sx={{ width: 24, height: 24 }} src={teacher?.photoURL} alt={teacher?.firstName} />
            //             <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
            //                 {teacher?.firstName}
            //             </Typography></>
            //     }
            // },
            {
                field: "passedCompleted",
                headerName: "Completed / Passed",
                minWidth: 160,
                flex: 1,
                renderCell(params) {
                    return <span>{params.row.completed_lessons}<b> Total</b><br />{"\n"}{params.row.passed_lessons}<b> Passed</b></span>
                }
            },
            {
                field: "average_score",
                headerName: "%Average Grade",
                minWidth: 160,

                flex: 1,
                renderCell(params) {
                    if (!params.row.average_score) {
                        return null
                    }
                    return <>{(params.row.average_score * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</>
                }
            }
        ],
        []
    );


    const [school, setSchool] = useState<ISchool | null>(null)
    const [teacher, setTeacher] = useState<ITeacher | null>(null)
    const [gradeLevel, setGradeLevel] = useState("all")
    const { autocompleteProps: schoolAutoCompleteProps } = useAutocomplete<ISchool>({
        resource: "school",
    });
    const { autocompleteProps: teacherAutoCompleteProps } = useAutocomplete<ITeacher>({
        resource: "teacher",
        filters: [{
            field: "school",
            value: school?.id,
            operator: "eq"
        }]
    });

    const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(k => k.toString())
    return (
        <div>
            <List title={"CT Time / Activity Report"} cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
                headerButtons={(context) => {
                    return <>
                        <DateRangePicker
                            startDate={searchDateRange[0]} // momentPropTypes.momentObj or null,
                            startDateId="uStId" // PropTypes.string.isRequired,
                            endDate={searchDateRange[1]} // momentPropTypes.momentObj or null,
                            endDateId="uEnD" // PropTypes.string.isRequired,
                            disabled={false}
                            isOutsideRange={() => false}
                            onDatesChange={({ startDate, endDate }: {
                                startDate: any;
                                endDate: any;
                            }) => setSearchDateRange([startDate, endDate])} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={(inp: any) => { setFocusedInput(inp) }} // PropTypes.func.isRequired,
                        />
                        {context.defaultButtons}
                    </>
                }}
            >
                {//Material Input box with onchange callback function
                }


                <AdvancedFilter setFilters={setFilters} />
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={columnGroupModel}

                    autoHeight

                    rowsPerPageOptions={[10, 20, 50, 100]}
                    density="comfortable"
                    sx={{
                        "& .MuiDataGrid-cell:hover": {
                            cursor: "pointer",
                        },
                    }}
                    onCellClick={(cell) => {
                        if (cell.colDef.field == "school") {
                            show("school", cell.row.school.id)

                        } else if (cell.colDef.field == "teacher") {
                            show("teacher", cell.value)

                        } else {
                            show("student", cell.row.id)
                        }

                    }}
                />
            </List>
        </div>
    );
};

function MomentItem({ minutes }: { minutes: number }) {
    // if the minute is less than 60, then it's just a minute, else it's hour + minute
    if (minutes < 60) {
        return <span>{minutes} <b> mins</b></span>
    } else {
        const hours = Math.floor(minutes / 60)
        const mins = minutes % 60
        return <span>{hours} <b> hrs</b> {mins ? <><br /><span>{mins} <b> mins</b></span></> : <></>}</span>
    }
}

export const AdvancedFilter = ({ setFilters }: { setFilters: any }) => {
    const { data: user } = useGetIdentity()
    const [school, setSchool] = useState<ISchool | null>(null)
    const [teacher, setTeacher] = useState<ITeacher | null>(null)
    const [gradeLevel, setGradeLevel] = useState("all")
    const { autocompleteProps: schoolAutoCompleteProps } = useAutocomplete<ISchool>({
        resource: "school",
    });
    const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(k => k.toString())
    const { autocompleteProps: teacherAutoCompleteProps } = useAutocomplete<ITeacher>({
        resource: "teacher",
        filters: [{
            field: "school",
            value: school?.id,
            operator: "eq"
        }]
    })

    if (user?.userType !== "admin" && user?.userType !== "principal") {
        return null
    }
    return (

        <div className="border rounded-md w-full flex flex-col gap-2 px-3 py-2 my-2">
            <div className="flex items-center justify-between">
                <p className="font-bold">
                    Filters
                </p>
                <div className="flex items-center gap-2 cursor-pointer" onClick={() => {
                    setSchool(null)
                    setTeacher(null)
                    setGradeLevel("all")
                    setFilters([])
                }}>
                    <Close fontSize="small" className="text-blue-600" />
                    <p className="font-bold text-blue-600 text-sm">
                        Clear Filter
                    </p>

                </div>
            </div>
            <div className="flex items-center gap-2">
                <div>
                    <p className="font-bold py-1">
                        Choose school
                    </p>
                    <Autocomplete
                        size="small"
                        {...schoolAutoCompleteProps}
                        value={school}
                        onChange={(_, value) => {
                            setSchool(value)
                        }}
                        getOptionLabel={(item) => {
                            return item.name
                        }}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined || option.id === value.id
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" required />
                        )}
                    />
                </div>
                <div>
                    <p className="font-bold py-1">
                        Choose Teacher
                    </p>
                    <Autocomplete
                        size="small"
                        {...teacherAutoCompleteProps}
                        value={teacher}
                        onChange={(_, value) => {
                            setTeacher(value)
                        }}
                        getOptionLabel={(item) => {
                            return `${item.firstName} ${item.lastName}`
                        }}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined || option.id.toString() === value.id.toString()
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" required />
                        )}
                    />
                </div>
            </div>
            <div>
                <div>
                    <p className="font-bold py-1">
                        Choose Grade
                    </p>
                    <Select defaultValue="all" className="h-10" value={gradeLevel} onChange={(e) => setGradeLevel(e.target.value)}>
                        <MenuItem value="all">
                            All
                        </MenuItem>
                        {
                            grades.map(grade => (
                                <MenuItem value={grade}>
                                    Grade {grade}
                                </MenuItem>
                            ))
                        }

                    </Select>
                </div>
            </div>
            <Button variant="contained" className="mt-2 w-max" size="small" disabled={
                !school
            } onClick={() => {
                setFilters([{
                    field: "school",
                    value: school?.id,
                    operator: "eq"
                }, {
                    field: "teacherId",
                    value: teacher?.id,
                    operator: "eq"
                }, {
                    field: "grade",
                    value: gradeLevel === "all" ? undefined : gradeLevel,
                    operator: "eq"
                }])
            }}>
                Apply
            </Button>
        </div>
    )
}