import { useList, useRouterContext, useShow } from "@pankod/refine-core";
import { useMemo, useState } from "react";
import { Box, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { visuallyHidden } from '@mui/utils';

type Res = {
    "id": string,
    "firstName": string,
    "lastName": string,
    "uname": string,
    "grade": number,
    "school": {
        "id": string,
        "name": string,
        "principalId": string
    },
    "progresses": [],
    "checkpoint": {
        "textId": string,
        "wcpm": number
    }[]
}

interface Data {
    firstName: string,
    lastName: string,
    grade1: number,
    grade2: number,
    grade3: number,
    grade4: number,
    grade5: number,
    grade6: number,
    grade7: number,
    grade8: number,
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {
        id: 'lastName',
        label: 'Last Name',
        numeric: false
    }, {
        id: 'firstName',
        label: 'First Name',
        numeric: false
    },
    {
        id: 'grade1',
        label: 'Grade 1',
        numeric: true
    },
    {
        id: 'grade2',
        label: 'Grade 2',
        numeric: true
    },
    {
        id: 'grade3',
        label: 'Grade 3',
        numeric: true
    },
    {
        id: 'grade4',
        label: 'Grade 4',
        numeric: true
    },
    {
        id: 'grade5',
        label: 'Grade 5',
        numeric: true
    },
    {
        id: 'grade6',
        label: 'Grade 6',
        numeric: true
    },
    {
        id: 'grade7',
        label: 'Grade 7',
        numeric: true
    },
    {
        id: 'grade8',
        label: 'Grade 8',
        numeric: true
    },
]

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export const Checkpoint1to3Show = () => {
    const { useLocation } = useRouterContext();
    const { showId } = useShow()
    const location = useLocation();
    const lessonId = Number.parseInt(showId!!.toString());

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('lastName');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [search, setSearch] = useState<string>("");

    const { data: unsortedData } = useList<Res>({
        config: {
            filters: [
                {
                    field: "school",
                    operator: "eq",
                    value: (new URLSearchParams(location.search)).get("school")
                },
                {
                    field: "lessonId",
                    operator: "eq",
                    value: showId
                },
            ]
        },
        resource: `chreport/lessonreport/${lessonId}`
    })

    const visibleRows = useMemo(
        () =>
            //@ts-expect-error
            unsortedData?.data.length ? stableSort(unsortedData.data, getComparator(order, orderBy)).filter(
                (row) => {
                    return row.firstName.toLowerCase().includes(search.toLowerCase())
                }

            ) : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [order, orderBy, unsortedData, search]
    );



    return (
        <div style={{ height: "100%", width: '100%' }}>
            <div className="flex items-center justify-end py-2">
                <ExportAs id="result" multiplePages getExportName={() => `${new Date().toLocaleDateString()}`} getRows={() => {
                    return [
                        [
                            "Last Name",
                            "First Name",
                            "Grade 1",
                            "Grade 2",
                            "Grade 3",
                            "Grade 4",
                            "Grade 5",
                            "Grade 6",
                            "Grade 7",
                            "Grade 8",
                        ],
                        ...unsortedData?.data?.map((row) => {
                            return [row.firstName, row.lastName, ...row.checkpoint.map(i => i.wcpm)]
                        }) ?? []
                    ]

                }} />
            </div>
            <Input
                onChange={(e) => {
                    setSearch(e.currentTarget.value!);
                }}
                placeholder={"Type here to search"}
                style={{ margin: 10 }}
            />
            <div id="result">
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        {/* <TableHead>
                            <TableRow className="border border-black/20">
                                {
                                    columns.map(column => {
                                        return (
                                            <TableCell key={column.field} style={{
                                                fontWeight: "bold",
                                                color: "black"
                                            }} className="text-black"
                                                sortDirection={"asc"}
                                            >
                                                {column.headerName}
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead> */}
                        <TableBody className="border border-black/20">
                            {
                                visibleRows?.map((row) => (
                                    <TableRow key={row.id} className="divide-x divide-black/20">
                                        <TableCell style={{
                                            fontWeight: "bold"
                                        }}>{row.lastName}</TableCell>
                                        <TableCell style={{
                                            border: "1px solid rgb(0 0 0 / 0.2)",
                                            fontWeight: "bold"
                                        }} className="font-bold">{row.firstName}</TableCell>
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G1")?.wcpm} grade={1} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G2")?.wcpm} grade={2} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G3")?.wcpm} grade={3} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G4")?.wcpm} grade={4} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G5")?.wcpm} grade={5} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G6")?.wcpm} grade={6} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G7")?.wcpm} grade={7} />
                                        <GradeCell wcpm={row.checkpoint.find(i => i.textId === "G8")?.wcpm} grade={8} />
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

function getWcpmColor(grade: number, wcpm?: number) {
    if (!wcpm) return "#f0f0f0"
    const gradeWcpm = {
        1: 16,
        2: 59,
        3: 79,
        4: 95,
        5: 109,
        6: 116,
        7: 117,
        8: 118
    }
    return wcpm < gradeWcpm[grade] ? "#D5BE0D" : "#2091EB"
}

function GradeCell({ wcpm, grade }: { wcpm?: number, grade: number }) {
    return (
        <TableCell style={{
            border: "1px solid rgb(0 0 0 / 0.2)",
            background: getWcpmColor(grade, wcpm),
            color: "white"
        }}>{wcpm}</TableCell>
    )
}
