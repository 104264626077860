import { PrintRounded } from "@mui/icons-material"
import { useGetIdentity, useMutation } from "@pankod/refine-core"
import { Autocomplete, Button, Card, Grid, MenuItem, Select, TextField, useAutocomplete } from "@pankod/refine-mui"
import { ISchool, IUser } from "interfaces";
import { useState } from "react"
import { DateRangePicker } from 'react-dates';
import { $fetch } from "utils/fetch"
import { School } from "utils/type";
import { exportAsPdf } from "utils/utils"
import { Report, SchoolRepAgg } from "../teacher-report/list";


interface CombinedResult {
    [key: string]: SchoolRepAgg
}

interface TeacherReport {
    id: string
    name: string,
    result: {
        [key: string]: SchoolRepAgg
    }

}
interface Res {
    combinedResults: CombinedResult
    aggregateTeachers: TeacherReport[]
}


export const GradeLevelReport = () => {
    const grades = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const [grade, setGrade] = useState<string>()
    const [school, setSchool] = useState<ISchool | null>(null)
    const { data } = useGetIdentity();
    const user = data as IUser
    const [searchDateRange, setSearchDateRange] = useState<any[]>([])
    const [focusedInput, setFocusedInput] = useState(null)

    const [totalReport, setTotalReport] = useState<CombinedResult>()
    const [aggregateTeachers, setAggergateTeachers] = useState<TeacherReport[]>()
    const { mutate } = useMutation({
        mutationFn: async () => {
            const schoolId = user?.userType === "admin" ? school?.id : (user as any)?.school.id
            const res = await $fetch<Res>(`admin/weeklyFluencyReport/classAggregate?grade=${grade}&schoolId=${schoolId}&start=${searchDateRange[0]?.format("YYYY-MM-DD")
                }&end=${searchDateRange[1]?.format("YYYY-MM-DD")}`)
            if (res.data) {
                setTotalReport(res.data.combinedResults)
                setAggergateTeachers(res.data.aggregateTeachers)
            }
        }
    })
    const { autocompleteProps } = useAutocomplete<ISchool>({
        resource: "school",
    });
    return (
        <Grid
            container
            padding={4}
            gap={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <div className="flex bg-white rounded-md flex-col gap-4 px-8 py-4 h-max">
                <h1 className="font-bold text-xl">Generate Grade Level Report</h1>
                {
                    user?.userType === "admin" && <div>
                        <p className="font-bold">
                            Select School
                        </p>
                        <Autocomplete
                            size="small"
                            {...autocompleteProps}
                            value={school}
                            onChange={(_, value) => {
                                setSchool(value)
                            }}
                            getOptionLabel={(item) => {
                                return item.name
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || option.id === value.id
                            }
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" required />
                            )}
                        />
                    </div>
                }

                <p className="font-bold">
                    Select Grade Level
                </p>
                <Select placeholder="Select Grade" onChange={(e) => {
                    setGrade(e.target.value)
                }} value={grade}>
                    {
                        grades.map((grade) => {
                            return (
                                <MenuItem key={grade} value={grade}>
                                    Grade {grade}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
                <p className="font-bold">
                    Select Date Range
                </p>
                <DateRangePicker
                    startDate={searchDateRange[0]} // momentPropTypes.momentObj or null,
                    startDateId="uStId" // PropTypes.string.isRequired,
                    endDate={searchDateRange[1]} // momentPropTypes.momentObj or null,
                    endDateId="uEnD" // PropTypes.string.isRequired,
                    disabled={false}
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }: {
                        startDate: any;
                        endDate: any;
                    }) => setSearchDateRange([startDate, endDate])} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(inp: any) => { setFocusedInput(inp) }} // PropTypes.func.isRequired,
                />
                <Button variant="contained" style={{ alignSelf: "flex-start" }} onClick={() => {
                    mutate()
                }}>
                    Generate
                </Button>
            </div>
            <Card sx={{ flex: 1, padding: 2 }}>
                <div className="flex justify-end items-center gap-2 cursor-pointer" onClick={() => {
                    exportAsPdf("#report", "grade level report")
                }}>
                    <PrintRounded className="text-green-700" />
                    <p className="text-green-600">
                        Print
                    </p>
                </div>
                <div id="report" className="px-4 py-2">
                    <p className="font-bold text-xl">
                        Grade {grade} Summary
                    </p>
                    <div >
                        {
                            Object.keys(totalReport || {}).map(key => {
                                const rep = totalReport?.[key]!
                                return (
                                    <div key={key}>
                                        <p className="font-medium text-lg">
                                            Overall Summary
                                        </p>
                                        <div className="flex flex-col gap-2 mt-3">
                                            <div className="border rounded border-gray-300 px-4 py-2">
                                                {rep.total_minutes.length} students used Read 21 in this time period
                                            </div>
                                            <div className="border rounded border-gray-300 px-4 py-2">
                                                average time {rep.average_minutes} minutes
                                            </div>
                                            <div className="border rounded border-gray-300 px-4 py-2">
                                                median lesson {rep.median_lesson}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p className="py-2 text-lg font-bold">
                        Teacher Reports
                    </p>
                    <div className="flex flex-col gap-2">
                        {
                            aggregateTeachers?.filter(ag => Object.keys(ag.result).length)?.map(teacher => {
                                const report = teacher.result
                                return (
                                    <div key={teacher.id} className="border border-stone-300 p-2 rounded-md">
                                        <p className=" border-b">
                                            Teacher Name: {teacher.name}
                                        </p>

                                        {
                                            Object.keys(report).length ?
                                                Object.keys(report || {}).map((key) => {
                                                    if (key !== grade) {
                                                        return null
                                                    }
                                                    return (
                                                        <div key={key}>
                                                            <h3 className="font-bold">
                                                                Grade {key} Report
                                                            </h3>
                                                            <h1>
                                                                - {report?.[key].total_minutes.length} students used Read 21 in this time period
                                                            </h1>
                                                            <h1>
                                                                - average time of {report?.[key].average_minutes} minutes
                                                            </h1>
                                                            <h1>
                                                                - Median  lesson {report?.[key].median_lesson}
                                                            </h1>
                                                        </div>
                                                    )
                                                }) : <p className="text-sm py-2">
                                                    Not enough data in this time range
                                                </p>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Card>
        </Grid>
    )
}