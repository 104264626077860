/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @format */

import {
  IResourceComponentsProps,
  useCan,
  useCustom,
  useDataProvider,
  useDelete,
  useGetIdentity,
  useInvalidate,
  useNavigation,
  useNotification,
  useQuery,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  DeleteButton,
  Grid,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@pankod/refine-mui";
import React, { useEffect, useState, useSyncExternalStore } from "react";

import {
  CalendarMonthOutlined,
  CheckOutlined,
  ClearOutlined,
  DownloadOutlined,
  EditOutlined,
  Group,
  Groups2,
  MilitaryTech,
  OnlinePrediction,
  PauseOutlined,
  PlayArrowTwoTone,
  Print,
  RepeatOutlined,
  SchoolOutlined,
} from "@mui/icons-material";

import { DurationDate } from "components";
import { IStudent } from "interfaces";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
import moment from "moment";
import { createAxios } from "utils/customAxios";
import { ProgressItem } from "../../components/fieldItems";
import CTRecordings from "./ct";
import FlRecordings from "./fl";
import { ReportCardIcon } from "components/report-card-icon";
import { usePDF } from "react-to-pdf";
import { ReportCard, ReportCardResponse } from "components/report-card";
import { $fetch } from "utils/fetch";
import { exportAsPdf } from "utils/utils";

export const UserInfoText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent={{
      sm: "center",
      lg: "flex-start",
    }}
    gap={1}
  >
    {children}
  </Stack>
);
var timeSince = function (date: any) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "y";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "m";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "d";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hr";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "min";
          } else {
            interval = seconds;
            intervalType = "sec";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }
  if (intervalType != "now") {
    intervalType += " ago";
  }

  return interval + "" + intervalType;
};

function sortByGroup(
  myArray: any[],
  info: { [x: string]: { group: any } },
  order: string | any[],
) {
  myArray.sort(function (a, b) {
    return (
      order.indexOf(info[a.textId].group) - order.indexOf(info[b.textId].group)
    );
  });
  return myArray;
}

function TextItem({
  toogleAudio,
  isPlaying,
  log,
  dataRef,
  lessonId,
}: {
  toogleAudio: any;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  lessonId: number;
}) {
  const { data: user } = useGetIdentity();
  const notification = useNotification();

  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          marginBottom: 5,
        }}
      >
        {log.wasCorrect ? (
          <CheckOutlined color={"success"} />
        ) : (
          <ClearOutlined color={"error"} />
        )}
        {dataRef[log.textId].text || log.textId}
        <>
          {isPlaying ? (
            <PauseOutlined onClick={() => toogleAudio()} />
          ) : (
            <PlayArrowTwoTone onClick={() => toogleAudio()} />
          )}
        </>
      </div>

      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <span
          style={{
            color: "rgba(0,0,0,0.95)",
            alignSelf: "center",
            flex: 1,
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          {log.result || ""}
        </span>
        <span
          style={{
            color: "rgba(0,0,0,0.7)",
            alignSelf: "center",
            flex: 1,
            textAlign: "center",
            marginBottom: 2,
            fontSize: 10,
          }}
        >
          (Typing Practice)
        </span>
      </Stack>
    </Card>
  );
}

function WordItem({
  toogleAudio,
  showPlay,
  isPlaying,
  log,
  dataRef,
  lessonId,
}: {
  toogleAudio: any;
  showPlay: boolean;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  lessonId: number;
}) {
  const { data: user } = useGetIdentity();
  const notification = useNotification();

  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          marginBottom: 5,
        }}
      >
        {log.wasCorrect ? (
          <CheckOutlined color={"success"} />
        ) : (
          <ClearOutlined color={"error"} />
        )}
        {dataRef[log.textId].imgs ? (
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: 1 }}>
              {dataRef[log.textId].imgs.map((imgItm: string) => (
                <img src={imgItm} width={20} height={20} />
              ))}
            </div>
          </>
        ) : (
          <span
            dangerouslySetInnerHTML={{ __html: dataRef[log.textId].text }}
          ></span>
        )}
      </div>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {log.recording && (
          <>
            <span style={{ marginRight: 5, fontSize: 12 }}>
              {timeSince(log.created_at)}
            </span>
            {user?.userType == "admin" && (
              <RepeatOutlined
                onClick={() => {
                  var txtItem = (
                    dataRef[log.textId].ref || log.textId
                  ).toUpperCase();
                  notification.open!({
                    type: "progress",
                    message: "Requesting for " + txtItem,
                    undoableTimeout: 10,
                    key: "loadingResultForItem",
                  });
                  createAxios(process.env.REACT_APP_BACKEND)
                    .post(`/admin/student/testScore/` + lessonId, {
                      s3Key: log.recording,
                      key: txtItem,
                    })
                    .then((res) => {
                      notification.close!("loadingResultForItem");
                      if (res.data.isCorrect) {
                        notification.open!({
                          type: "success",
                          message: "Service responded with correct",
                        });
                      } else {
                        notification.open!({
                          type: "error",
                          message: "Service responded with incorrect",
                        });
                      }
                    });
                }}
              />
            )}
            {showPlay && (
              <>
                {isPlaying ? (
                  <PauseOutlined onClick={() => toogleAudio()} />
                ) : (
                  <PlayArrowTwoTone onClick={() => toogleAudio()} />
                )}
              </>
            )}

            <DownloadOutlined
              onClick={() => {
                //var content = content of file;
                var dl = document.createElement("a");
                dl.setAttribute(
                  "href",
                  `https://asr-record.s3.amazonaws.com/${log.recording}`,
                );
                dl.setAttribute("download", log.textId + ".wav");
                dl.target = "_blank";
                dl.click();
              }}
            />
          </>
        )}
        {!log.recording && (
          <span
            style={{
              color: "rgba(0,0,0,0.25)",
              alignSelf: "center",
              flex: 1,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Recording empty
          </span>
        )}
      </Stack>
    </Card>
  );
}

function SentenceItem({
  toogleAudio,
  isPlaying,
  log,
  dataRef,
  shouldShowLevel,
  sentenceIndex,
}: {
  toogleAudio: any;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  shouldShowLevel: any;
  sentenceIndex: any;
}) {
  var wRes = log.sentence;
  var sentence = dataRef[log.textId].text;
  if (Array.isArray(sentence)) {
    sentence = sentence.join(" ");
  }
  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
        gridColumn: log.format === "sentence" ? "1/-1" : undefined,
      }}
    >
      {shouldShowLevel && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            marginBottom: 5,
          }}
        >
          <span
            style={{
              color: "rgba(0,0,0,0.6)",
              alignSelf: "start",
              flex: 1,
              textAlign: "start",
              marginBottom: 2,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Level {sentenceIndex}
          </span>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <span style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
          {" "}
          {sentence.split(" ").map((word: string, i: any) => {
            var wrdK = word
              .toLowerCase()
              .replace(/(\.|\?)/gi, "")
              .replace(",", "");
            var result = wRes?.[wrdK + i];
            return (
              <span
                key={wrdK + i}
                style={{
                  color: result ? "green" : "red",
                  display: "inline-block",
                }}
              >
                {word}
              </span>
            );
          })}{" "}
        </span>
      </div>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {log.recording && (
          <>
            <span style={{ marginRight: 5, fontSize: 12 }}>
              {timeSince(log.created_at)}
            </span>
            {isPlaying ? (
              <PauseOutlined onClick={() => toogleAudio()} />
            ) : (
              <PlayArrowTwoTone onClick={() => toogleAudio()} />
            )}
            <DownloadOutlined
              onClick={() => {
                //var content = content of file;
                var dl = document.createElement("a");
                dl.setAttribute(
                  "href",
                  `https://asr-record.s3.amazonaws.com/${log.recording}`,
                );
                dl.setAttribute("download", log.textId + ".wav");
                dl.target = "_blank";
                dl.click();
              }}
            />
          </>
        )}
        {!log.recording && (
          <span
            style={{
              color: "rgba(0,0,0,0.25)",
              alignSelf: "center",
              flex: 1,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Recording empty
          </span>
        )}
      </Stack>
    </Card>
  );
}

export const StudentShow: React.FC<IResourceComponentsProps> = (props) => {
  const apiUrl = process.env.REACT_APP_BACKEND;
  const { data: userIden } = useGetIdentity();
  const confirm = useConfirm();
  const dataProvider = useDataProvider();
  const { show, edit } = useNavigation();
  const notification = useNotification();
  const invalidate = useInvalidate();

  const { queryResult, showId, } = useShow<IStudent>({
    resource: "student",
  });
  const student = queryResult.data?.data;
  const [openedTab, setOpenedTab] = useState("flRecord");
  const confirmRestore = useConfirm();
  const { data: canDeleteStudent } = useCan({
    resource: "student",
    action: "delete",
  });
  const { data: canTerminateStudent } = useCan({
    resource: "student",
    action: "terminate",
  });

  const { data: reportCardData, refetch } = useCustom<ReportCardResponse[]>({
    url: `
        ${process.env.REACT_APP_BACKEND}admin/classReport/generateReportCard/student/${showId}
        `,
    method: "get",
  });

  const { targetRef, toPDF } = usePDF({
    filename: `${student?.firstName} ${student?.lastName}`,
  });
  const [showInPdf, setShowInPdf] = useState(false);

  useEffect(() => {
    if (showInPdf) {
      toPDF();
      setShowInPdf(false);
    }
  }, [showInPdf]);

  const licenseType = student?.license?.license_type || "";

  const [showDyslexiaReport, setShowDyslexiaReport] = useState(false)

  useEffect(() => {
    if (licenseType === "ct") {
      setOpenedTab("ctRecord")
    }
  }, [licenseType])
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Paper sx={{ p: 2, paddingX: { xs: 4, md: 2 } }}>
            <Stack alignItems="center" spacing={1}>
              <Avatar
                src={student?.photoURL}
                sx={{ width: 120, height: 120 }}
              />
              <Typography variant="h6">
                {student?.firstName} {student?.lastName}
              </Typography>
            </Stack>
            <br />

            <Stack spacing={1}>
              {userIden?.userType !== "parent" && (
                <UserInfoText>
                  <SchoolOutlined />

                  <a
                    href="#"
                    onClick={() => {
                      show("school", student?.school?.id!);
                    }}
                    style={{
                      display: "flex",
                      color: "#000",
                      textDecorationLine: "underline",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      {student?.school?.name}
                    </Typography>
                  </a>
                  <Typography variant="body1">
                    ({student?.school?.code})
                  </Typography>

                </UserInfoText>
              )}
              {userIden?.userType !== "parent" && student?.parent && (
                <UserInfoText>
                  <Group />

                  <a
                    href="#"
                    onClick={() => {
                      show("parent", student.parent?.id!);
                    }}
                    style={{
                      display: "flex",
                      color: "#000",
                      textDecorationLine: "underline",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      {student?.parent?.firstName}
                    </Typography>
                  </a>

                </UserInfoText>
              )}
              {userIden?.userType !== "parent" && student?.teacher?.id && (
                <UserInfoText>
                  <Groups2 />
                  <a
                    href="#"
                    onClick={() => {
                      show("teacher", student?.teacher?.id!);
                    }}
                    style={{
                      display: "flex",
                      color: "#000",
                      textDecorationLine: "underline",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      src={student?.teacher?.photoURL}
                      alt={student?.teacher?.firstName}
                    />
                    <Typography variant="subtitle2">
                      {student?.teacher?.firstName}
                    </Typography>
                  </a>
                </UserInfoText>
              )}
              <UserInfoText>
                <CalendarMonthOutlined />
                <Typography variant="body1">
                  <DurationDate date={student?.created_at} />
                </Typography>
              </UserInfoText>
            </Stack>
            <br />
            {userIden?.userType !== "parent" && (
              <Stack
                sx={{ mt: 2 }}
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                flexWrap={"wrap"}
              >
                <Button
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    edit("user", showId!);
                  }}
                >
                  Edit
                </Button>

                {canDeleteStudent?.can && student?.user_status === "active" && (
                  <DeleteButton
                    fullWidth
                    onSuccess={() => {
                      show("school", student?.school?.id!);
                    }}
                    confirmOkText="Yes"
                    recordItemId={showId}
                  />
                )}
                {student?.user_status === "archive" && (
                  <Button
                    title={"Restore"}
                    color={"success"}
                    onClick={async () => {
                      await confirmRestore();
                      //axios POST api to /admin/student/restore
                      await createAxios(process.env.REACT_APP_BACKEND).post(
                        `/admin/student/${student.id}/restore/`,
                      );
                      invalidate({
                        resource: "student",
                        invalidates: ["all"],
                      });
                    }}
                  >
                    Restore
                  </Button>
                )}

                {canTerminateStudent?.can && (
                  <DeleteButton
                    fullWidth
                    title={"Terminate"}
                    confirmTitle={"Are you want to terminate?"}
                    onSuccess={() => {
                      show("school", student?.school?.id!);
                    }}
                    recordItemId={showId}
                    resourceNameOrRouteName={"student/terminate"}
                  >
                    Terminate
                  </DeleteButton>
                )}
              </Stack>
            )}
          </Paper>
          {
            userIden?.userType !== "parent" && (licenseType === "full" || licenseType === "fl") && (
              <Paper sx={{ p: 2, my: 2 }}>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="h6">Fluency Progress</Typography>
                </Stack>
                <br />
                <Stack spacing={1}>
                  <UserInfoText>
                    <MilitaryTech />
                    <ProgressItem
                      progress={Math.max(
                        ...(student?.progresses?.length
                          ? student.progresses.map((d) => d.lessonId) ?? [0]
                          : [0]),
                      )}
                      introductionPresented={
                        student?.introductionPresented ?? false
                      }
                    />
                  </UserInfoText>
                  {
                    userIden?.userType === "admin" && (
                      <Button
                        sx={{ mt: 2 }}
                        onClick={() => {
                          confirm({
                            description:
                              "Resetting the student will completely remove their progress from the database!",
                          })
                            .then(() => {
                              dataProvider()!.custom!({
                                method: "delete",
                                url: `${apiUrl}/admin/student/${student!.id!}/progress`,
                              })
                                .then((result) => {
                                  notification.open!({
                                    type: "success",
                                    message: "Student progress resetted!",
                                  });
                                  invalidate({
                                    resource: "student",
                                    invalidates: ["all"],
                                  });
                                })
                                .catch((err) => {
                                  notification.open!({
                                    type: "error",
                                    message: "Something went wrong!",
                                  });
                                });
                            })
                            .catch(() => { });
                        }}
                      >
                        Reset Progress
                      </Button>
                    )
                  }
                </Stack>
              </Paper>
            )
          }
          {
            student?.license.license_type === "full" || student?.license.license_type === "ct" ? (
              <Paper sx={{ p: 2, my: 2 }}>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="h6">CT Progress</Typography>
                </Stack>
                <br />
                <Stack spacing={1}>
                  <UserInfoText>
                    <MilitaryTech />
                    {(() => {
                      const progress = (student as IStudent)?.ctProgresses?.[0];
                      return progress?.itemNumber > 160 ||
                        (progress?.itemNumber === 160 && progress?.isComplete) ? (
                        <b>Complete</b>
                      ) : progress ? (
                        <span>
                          Lesson{" "}
                          <b>{Math.ceil((progress?.itemNumber - 4) / 4) + 1}</b>
                          <br />
                          Activity <b>{((progress?.itemNumber - 1) % 4) + 1}</b>
                        </span>
                      ) : (
                        <b>Not Started</b>
                      );
                    })()}
                  </UserInfoText>
                </Stack>
                {
                  (student as IStudent)?.ctProgresses.length ? null : <Stack>
                    <Button onClick={async () => {
                      const { error } = await $fetch("admin/ctProgress/activate", {
                        body: {
                          userId: student?.id,
                        },
                        method: "POST"
                      })
                      if (error) {
                        notification?.open?.({
                          type: "error",
                          message: "Failed to unlock CT. Please try again."
                        })
                        return
                      }
                      notification?.open?.({
                        type: "success",
                        message: "CT unlocked successfully."
                      })
                      queryResult.refetch()
                    }}>
                      Unlock CT
                    </Button>
                  </Stack>
                }

              </Paper>
            ) : null
          }

          <Paper sx={{ p: 2, my: 2 }}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">Activity</Typography>
            </Stack>
            <br />
            <Stack spacing={1}>
              <UserInfoText>
                <OnlinePrediction />
                <Typography variant="body1">
                  <b>{moment(student?.last_active).fromNow()}</b>
                </Typography>
              </UserInfoText>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2, my: 2 }}>
            <Button
              onClick={() => {
                refetch();
                setShowInPdf(true);
              }}
              startIcon={<ReportCardIcon />}
            >
              Generate Report Card
            </Button>
          </Paper>
          <Paper sx={{ p: 2, my: 2 }}>
            <Button
              color="secondary"
              onClick={() => {
                setShowDyslexiaReport(true)
              }}
              startIcon={(
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" strokeWidth={2} viewBox="0 0 1024 1024"><path fill="currentColor" d="m512 863.36l384-54.848v-638.72L525.568 222.72a96 96 0 0 1-27.136 0L128 169.792v638.72zM137.024 106.432l370.432 52.928a32 32 0 0 0 9.088 0l370.432-52.928A64 64 0 0 1 960 169.792v638.72a64 64 0 0 1-54.976 63.36l-388.48 55.488a32 32 0 0 1-9.088 0l-388.48-55.488A64 64 0 0 1 64 808.512v-638.72a64 64 0 0 1 73.024-63.36"></path><path fill="currentColor" d="M480 192h64v704h-64z"></path></svg>
              )}
            >
              Get Dyslexia Report
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Stack direction="column" spacing={2}>
            <Box
              style={{
                borderBottom: 1,
                borderColor: "divider",
                background: "white",
              }}
            >
              <Tabs
                value={openedTab}
                onChange={(e, v) => {
                  setOpenedTab(v);
                }}
              >
                {
                  licenseType === "full" || licenseType === "fl" ? (
                    <Tab label="Fluency Recordings" value="flRecord" />
                  ) : null
                }
                {
                  licenseType === "full" || licenseType === "ct" ? (
                    <Tab label="CT Recordings" value="ctRecord" />
                  ) : null
                }
              </Tabs>
            </Box>
            <div
              style={{ display: openedTab !== "flRecord" ? "none" : "unset" }}
            >
              {student && (
                <FlRecordings
                  progresses={student?.progresses}
                  userIden={userIden}
                  student={student}
                />
              )}
            </div>

            <div
              style={{ display: openedTab !== "ctRecord" ? "none" : "unset" }}
            >
              {student && (
                <CTRecordings userIden={userIden} student={student} />
              )}
            </div>
          </Stack>
        </Grid>
      </Grid>
      {showInPdf && reportCardData?.data && (
        <div
          ref={targetRef}
          id="student-report"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <ReportCard res={reportCardData?.data[0]} />
        </div>
      )}
      {
        showId && showDyslexiaReport && <DyslexiaReport showId={showId.toString()} onClose={() => setShowDyslexiaReport(false)} isOpen={showDyslexiaReport} />
      }
    </div>
  );
};

export interface DyslexiaLog {
  id: string
  dyslexiaId: string
  textId: string
  answer: string
  replayed: number
  wasCorrect: boolean
  created_at: string
}

export interface DyslexiaReportI {
  student: {
    id: string
    firstName: string
    lastName: string
    grade: number,
    school: {
      name: string
    },
    parent: {
      firstName: string
      lastName: string
    },
    teacher: {
      firstName: string
      lastName: string
    }
  },
  totalReplayed: number,
  dyslexiaRecord: {
    id: string,
    score: number,
    isComplete: boolean,
    current: number,
  },
  dyslexiaLogs: DyslexiaLog[]
}
export const DyslexiaReport = ({ showId, isOpen, onClose }: { showId: string, isOpen: boolean, onClose: () => void }) => {
  const { data } = useQuery({
    queryKey: ["dyslexiaReport", showId],
    queryFn: async () => {
      const res = await $fetch<DyslexiaReportI>(`admin/dyslexiaReports/${showId}`)
      return res
    }
  })
  function isLikelyDyslexic(score: number, grade: number) {
    if (grade <= 2) {
      if (score <= 10) {
        return true
      } else {
        return false
      }
    } else {
      if (score <= 12) {
        return true
      } else {
        return false
      }
    }
  }
  return (
    <Modal open={isOpen} onClose={() => {
      onClose()
    }}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-md px-4 flex flex-col gap-4 h-max w-1/2 py-10">
          {
            data?.data?.dyslexiaRecord?.isComplete ? <>
              <div className="ml-auto flex gap-3 px-4">
                <Button variant="contained" className="ml-auto w-max gap-2" onClick={() => {
                  exportAsPdf("#dyslexia-report", "dyslexia report")
                }}>
                  <Print />
                  Print
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => {
                  onClose()
                }}>
                  Close
                </Button>
              </div>
              <div className="flex flex-col gap-4 max-h-screen px-4 overflow-y-auto" id="dyslexia-report">
                <h1 className="font-bold text-xl text-center my-2">Dyslexia Screening Report</h1>
                <div className="flex justify-between items-center">
                  <p className="font-bold">
                    {data?.data?.student.school.name}, {
                      data.data.student.teacher ? data?.data?.student.teacher.firstName + " " + data?.data?.student.teacher.lastName : data?.data?.student.parent?.firstName + " " + data?.data?.student.parent?.lastName
                    }
                  </p>
                  <p className="font-bold">
                    {data?.data?.student.firstName} {data?.data?.student.lastName}, Grade {data?.data?.student.grade}, {new Date(data?.data?.dyslexiaLogs[0]?.created_at || 0).toLocaleDateString()}
                  </p>
                </div>

                <div>
                  <p className="font-bold text-black/80">
                    Screener Results: {data?.data?.dyslexiaRecord.score} -
                    {" "}{
                      isLikelyDyslexic(data.data.dyslexiaRecord.score || 0, data.data.student.grade || 0) ? <span className="text-red-600">
                        Shows Possible Risk for Dyslexia
                      </span> : <span className="text-black/90">
                        Likely No Risk for Dyslexia
                      </span>
                    }

                  </p>
                  <p className="">
                    Number of words correct: {data?.data?.dyslexiaLogs.filter(log => log.wasCorrect).length}
                  </p>
                  <p className="">
                    Number of times student needed word repeated: {data?.data?.totalReplayed}
                  </p>
                </div>

                <div className="flex items-center justify-between mt-4">
                  <Table>
                    <TableBody className="border">
                      {
                        data?.data?.dyslexiaLogs.slice(0, 10).map(log => (
                          <TableRow key={log.id}>
                            <TableCell className="!font-bold">{log.answer}</TableCell>
                            <TableCell>{log.wasCorrect ? <svg className="text-green-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><path fill="currentColor" fillRule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m4 24l5-5l10 10L39 9l5 5l-25 25z" clipRule="evenodd"></path></svg> : (
                              <svg className="text-red-700" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"></path></svg>
                            )}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  {
                    (data?.data?.dyslexiaLogs.length || 0) > 10 && <Table>
                      <TableBody className="border">
                        {
                          data?.data?.dyslexiaLogs.slice(10, 20).map(log => (
                            <TableRow key={log.id}>
                              <TableCell className="!font-bold">{log.answer}</TableCell>
                              <TableCell>{log.wasCorrect ? <svg className="text-green-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><path fill="currentColor" fillRule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m4 24l5-5l10 10L39 9l5 5l-25 25z" clipRule="evenodd"></path></svg> : (
                                <svg className="text-red-700" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"></path></svg>
                              )}</TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  }

                </div>
              </div>
            </> : <div className="flex flex-col items-center gap-4 justify-center">
              <p className="text-center font-bold text-xl">
                Student hasn't taken the screening test yet.
              </p>
              <p className="text-center">
                Note that the student will not take the screening test until they pass lesson 4 of the Fluency Lesson.
              </p>
              <Button variant="outlined" color="secondary" onClick={() => {
                onClose()
              }}>
                Close
              </Button>
            </div>
          }
        </div>
      </div>
    </Modal>
  )
}