import { HttpError } from "@pankod/refine-core";
import { Autocomplete, Avatar, Box, Button, Create, FormControl, FormHelperText, FormLabel, Input, InputAdornment, OutlinedInput, Stack, TextField, Typography, useAutocomplete } from "@pankod/refine-mui"
import { Parent } from "./type";
import {
    Controller,
    UseModalFormReturnType,
    useForm
} from "@pankod/refine-react-hook-form";
import { makeRandom } from "utils/string";
import { ContentCopyOutlined } from "@mui/icons-material";
import { apiUrl } from "const/constants";
import { createAxios } from "utils/customAxios";
import { ISchool } from "interfaces";
import { useState } from "react";


export const ParentCreate = () => {
    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        saveButtonProps
    } = useForm<Parent, HttpError, Parent>({
        refineCoreProps: {
            onMutationSuccess(data, variables, context) {
            },
        }
    });
    const { autocompleteProps: schoolAutoComplete } = useAutocomplete<ISchool>({
        resource: "school",
        errorNotification: false
    });
    return (
        <Create
            saveButtonProps={saveButtonProps}
        >
            <Stack>
                <Box
                    paddingX="50px"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingX: {
                            xs: 1,
                            md: 6,
                        },
                    }}
                >
                    <form onSubmit={handleSubmit(onFinish)}>
                        <Stack gap="40px" direction={"row"}>
                            <Stack flex={1} gap="10px" marginTop="10px">
                                <FormControl>
                                    <FormLabel required>
                                        First Name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="firstName"
                                        {...register("firstName", {
                                            required: "First name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.firstName && (
                                        <FormHelperText error>
                                            {errors.firstName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        Last name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="lastName"
                                        {...register("lastName", {
                                            required: "Last name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.lastName && (
                                        <FormHelperText error>
                                            {errors.lastName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Email
                                    </FormLabel>
                                    <OutlinedInput
                                        id="email"
                                        {...register("email", {
                                            required: "Email is required!",
                                            validate: async (email) => {
                                                const result = await createAxios(apiUrl).get(`/admin/exists/${email}`);
                                                if (result.data.count > 0) {
                                                    return "Email already used!"
                                                } else {
                                                    return true
                                                }
                                            }
                                        })}
                                        type="email"
                                        style={{ height: "40px" }}
                                    />
                                    {errors.email && (
                                        <FormHelperText error>
                                            {errors.email.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel
                                        required
                                        sx={{
                                            marginBottom: "8px",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            color: "text.primary",
                                        }}
                                    >
                                        School
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="school"
                                        rules={{
                                            required: "School required",
                                        }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                size="small"
                                                {...schoolAutoComplete}
                                                {...field}
                                                value={field.value || null}
                                                onChange={(_, value) => {
                                                    field.onChange(value);
                                                }}
                                                getOptionLabel={(item) => {
                                                    return item.name
                                                        ? item.name
                                                        : "";
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) =>
                                                    value === undefined ||
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        error={
                                                            !!errors.school
                                                                ?.message
                                                        }
                                                        required
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.school && (
                                        <FormHelperText error>
                                            {errors.school.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth style={{
                                    marginTop: 20
                                }}>
                                    <FormLabel required>
                                        Default Password
                                    </FormLabel>
                                    <OutlinedInput
                                        id="password"
                                        {...register("password", {
                                            required: "Password is required!",
                                        })}
                                        type="string"
                                        defaultValue={makeRandom()}
                                        style={{ height: "40px" }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button onClick={() => {
                                                    // notification.open!({
                                                    //     message: "Password copied succesfully",
                                                    //     type: "success"
                                                    // })

                                                }}>
                                                    <ContentCopyOutlined />
                                                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password && (
                                        <FormHelperText error>
                                            {errors.password.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Create>
    )
}