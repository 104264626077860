import React, { MutableRefObject, useEffect, useMemo, useRef } from "react";
import {
    IResourceComponentsProps,
    useDataProvider, useList,
    useNavigation, useRouterContext,
    useShow,
    useSyncWithLocation,
    useTranslate
} from "@pankod/refine-core";
import { Button, Stack, Typography } from "@pankod/refine-mui";
import { AgGridReact } from "ag-grid-react";
import { Lessons } from "../../../const/lessons";

import {
    ColumnDef, ColumnDefBase,
    createColumnHelper,
    flexRender,
    getCoreRowModel, GroupColumnDef,
    useReactTable,
} from '@tanstack/react-table'
import { ArrowDownward, ArrowUpward, SortOutlined } from "@mui/icons-material";


var percentageRenderer = (params: { value: any; }) => {
    return params.value ? <b>{Math.round(params.value * 100)}%</b> : <>--</>
}
function processCol(col: any): ColumnDef<any, any> {
    return columnHelper.accessor(col.field, {
        cell: info => col?.cellRenderer ? col?.cellRenderer({ value: info.cell.getValue() }) : (info.getValue()),
        header: info => col?.headerComponent ? col?.headerComponent({}) : col.headerName,
    })
}
const columnHelper = createColumnHelper<any>()
var exportTableToExcel = (function () {
    return function (table: string, name: any) {
        var data = document.getElementById(table)!!;
        data.classList.toggle("reporting")
        // @ts-ignore
        var excelFile = XLSX.utils.table_to_book(data, { sheet: "sheet1" });
        // @ts-ignore
        XLSX.write(excelFile, { bookType: "xlsx", bookSST: true, type: 'base64' });
        // @ts-ignore
        XLSX.writeFile(excelFile, 'report-export' + ".xlsx");
        data.classList.toggle("reporting")
    }
})()

export const CTLessonReportShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const gridRef = useRef<AgGridReact>() as MutableRefObject<AgGridReact>
    const apiUrl = process.env.REACT_APP_BACKEND;
    const dataProvider = useDataProvider()
    const { useLocation } = useRouterContext();
    const { showId } = useShow()
    const location = useLocation();
    const lessonId = Number.parseInt(showId!!.toString());

    //sorting controller state, for each column
    const [sortState, setSortState] = React.useState<any>({
        student: "asc"
    })

    const { data: unsortedData } = useList({
        config: {
            filters: [
                {
                    field: "school",
                    operator: "eq",
                    value: (new URLSearchParams(location.search)).get("school")
                },
                {
                    field: "lessonId",
                    operator: "eq",
                    value: showId
                }
            ]
        },
        resource: "lessonReportCT",


    })
    const { show, create, showUrl, push } = useNavigation();
    const columnsDetails = useMemo(() =>
        [{
            field: "student",
            cellRenderer(params: { value: any }) {
                return <a href={`#L${lessonId}`} onClick={() => {
                    push(showUrl("student", params.value.id!!) + `#L${lessonId}`)
                }}>{(params.value.firstName)} {(params.value.lastName)}</a>
            },
            headerName: "Student",

        } as unknown,
        {
            headerName: 'Lesson Average',
            field: "average",
            cellRenderer: percentageRenderer,
        }
            , {
            headerName: <>Activity 1 <br />(Prefix & Suffix)</>,
            field: "activity1",
            cellRenderer: percentageRenderer,

        },
        {
            headerName: <>Activity 2 <br />(Vocabulary Building)</>,
            field: "activity2",
            cellRenderer: percentageRenderer,

        },
        {
            headerName: <>Activity 3 <br />(Comprehension)</>,
            field: "activity3",
            cellRenderer: percentageRenderer,
        },
        {
            headerName: <>Activity 4 <br />(Vocabulary Application)</>,
            field: "activity4",
            cellRenderer: percentageRenderer,
        },
        ].map(itm => processCol(itm)), [])


    //sort unsortedData with useMemo by sortState
    const data = useMemo(() => {
        if (!unsortedData?.data) return unsortedData
        let sortedData = [...unsortedData.data]
        for (let col in sortState) {
            sortedData = sortedData.sort((a, b) => {
                if (sortState[col] == "asc") {
                    if (a[col] == "✓") return 1
                    if (a[col]?.length == 1) return -1
                    //if value is null, it comes first
                    if (a[col] == null) return -1
                    //if value is string, should compare with localeCompare
                    if ((typeof a[col] == "string") && (a[col].length > 0)) return a[col].localeCompare(b[col])
                    //if value is dictionary with uname, should compare with uname
                    if ((typeof a[col] == "object") && (a[col].uname)) return a[col].uname.localeCompare(b[col].uname)
                    return a[col] > b[col] ? 1 : -1
                } else {
                    //if value is ✓ it comes first
                    if (a[col] == "✓") return -1
                    if (a[col]?.length == 1) return 1

                    //if value is null or undefined or empty string, it comes last
                    if (a[col] == null) return 1

                    //if value is string should compare with localeCompare
                    if ((typeof b[col] == "string") && (b[col].length > 0)) return b[col].localeCompare(a[col])
                    //if value is dictionary with uname, should compare with uname
                    if ((typeof b[col] == "object") && (b[col].uname)) return b[col].uname.localeCompare(a[col].uname)
                    return (a[col] < b[col] ? 1 : -1)
                }
            })
        }
        return { ...unsortedData, data: sortedData }
    }, [unsortedData, sortState]);

    const table = useReactTable<any>({
        data: data?.data || [],
        columns: columnsDetails,
        getCoreRowModel: getCoreRowModel(),
    })

    // @ts-ignore
    // @ts-ignore
    return (
        <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine">
            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h5" style={{ whiteSpace: "nowrap", width: "fit-content" }}>
                    <b>Lesson {lessonId}</b>
                </Typography>

                <b onClick={() => {
                    exportTableToExcel("newExport", "fileExport")
                }}>
                    <Typography variant="h6" style={{ whiteSpace: "nowrap", width: "fit-content", textDecorationLine: "underline", cursor: "pointer" }}>
                        Export
                    </Typography>
                </b>
            </div>
            <div className={"table-container"} style={{ width: "100%", height: "100%", overflow: "scroll", position: "relative" }}>
                <table id={"newExport"} className={"report-table"}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup, ik) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, i) => (
                                    //update or set sortState with header.id

                                    <th colSpan={header.colSpan} key={header.id} className={(((i == (headerGroup.headers.length - 1)) ? "filled-group" : ""))}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        {
                                            (header.colSpan == 1) && ((ik != 0) || (table.getHeaderGroups().length == 1)) && (
                                                <div className={"sort-container"}>

                                                    <div className={"sort-button"} onClick={() => {
                                                        //if sortState of item is clicked once, set it to desc, 2nd time set it to asc, 3rd time set it to null
                                                        if (sortState[header.column.id] == "desc") {
                                                            setSortState({ ...sortState, [header.column.id]: null })
                                                            return
                                                        } else if (sortState[header.column.id] == null) {
                                                            setSortState({ ...sortState, [header.column.id]: "asc" })
                                                            return
                                                        } else if (sortState[header.column.id] == "asc") {
                                                            setSortState({ ...sortState, [header.column.id]: "desc" })
                                                            return
                                                        }
                                                    }}>
                                                        {
                                                            (typeof sortState[header.column.id] == "string") ? (sortState[header.column.id] == "asc" ? <ArrowUpward /> : <ArrowDownward />) : <SortOutlined />
                                                        }
                                                    </div>
                                                </div>
                                            )


                                        }

                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().flatRows.map(row => (

                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    {/*<tfoot>*/}
                    {/*{table.getFooterGroups().map(footerGroup => (*/}
                    {/*    <tr key={footerGroup.id}>*/}
                    {/*        {footerGroup.headers.map(header => (*/}
                    {/*            <th key={header.id}>*/}
                    {/*                {header.isPlaceholder*/}
                    {/*                    ? null*/}
                    {/*                    : flexRender(*/}
                    {/*                        header.column.columnDef.footer,*/}
                    {/*                        header.getContext()*/}
                    {/*                    )}*/}
                    {/*            </th>*/}
                    {/*        ))}*/}
                    {/*    </tr>*/}
                    {/*))}*/}
                    {/*</tfoot>*/}
                </table>
            </div>



        </div>

    );
};