import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useCan,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Tooltip,
  Grid,
  Card,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Lessons } from "../../../const/lessons";
import { IPrincipal, ISchool, IStudent, ITeacher } from "interfaces";
import moment from "moment";

type ReportCardType = {
  index: number;
  onClick: (school: IStudent | null) => void;
};

export const ClassReportList: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();
  const { data: canShowTeacher } = useCan({
    action: "list",
    resource: "teacher",
  });

  const [teacher, setTeacher] = useState<IStudent | null>(null);
  const { autocompleteProps } = useAutocomplete<IStudent>({
    resource: "teacher",
  });
  return (
    <Grid
      container
      padding={4}
      gap={5}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Card
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
          Class Progress Reports
        </Typography>
        {canShowTeacher?.can ? (
          <Autocomplete
            size="small"
            {...autocompleteProps}
            value={teacher}
            onChange={(_, value) => {
              setTeacher(value);
            }}
            getOptionLabel={(item) => {
              return `${item.firstName} ${item.lastName} (${item.school?.name})`;
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" required />
            )}
          />
        ) : (
          <>
            <div style={{ width: 300 }} />
          </>
        )}

        <Button
          style={{ alignSelf: "flex-end" }}
          onClick={() => {
            if (teacher?.id || !canShowTeacher?.can) {
              push(`${showUrl("classReport", teacher?.id || "me")}`);
            }
          }}
        >
          Generate
        </Button>
      </Card>
      {/*<Card sx={{flex:1}}>*/}

      {/*</Card>*/}
    </Grid>
  );
};
