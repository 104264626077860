/** @format */

import {
  useDataProvider,
  useGetIdentity,
  useInvalidate,
  useNotification,
  useShow,
} from "@pankod/refine-core";
import { IProgress, IStudent, ITrial, IUser } from "../../interfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { useConfirm } from "material-ui-confirm";
import {
  Badge,
  Box,
  Button,
  Card,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@pankod/refine-mui";
import {
  CheckOutlined,
  ClearOutlined,
  DownloadOutlined,
  HistoryOutlined,
  LockOpenOutlined,
  LockOutlined,
  PauseOutlined,
  PercentOutlined,
  PlayArrowTwoTone,
  PlayLessonOutlined,
  RepeatOutlined,
  StartOutlined,
} from "@mui/icons-material";
import * as LessonKeys from "../../const/flData.json";
import { Lessons } from "../../const/lessons";
import { createAxios } from "../../utils/customAxios";
function a11yProps(index: number) {
  return {
    id: `tab-L${index}`,
    "aria-controls": `L${index}`,
  };
}
var timeSince = function (date: any) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "y";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "m";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "d";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hr";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "min";
          } else {
            interval = seconds;
            intervalType = "sec";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }
  if (intervalType != "now") {
    intervalType += " ago";
  }

  return interval + "" + intervalType;
};

function sortByGroup(
  myArray: any[],
  info: { [x: string]: { group: any } },
  order: string | any[]
) {
  myArray.sort(function (a, b) {
    return (
      order.indexOf(info[a.textId].group) - order.indexOf(info[b.textId].group)
    );
  });
  return myArray;
}

function TextItem({
  toogleAudio,
  isPlaying,
  log,
  dataRef,
  lessonId,
}: {
  toogleAudio: any;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  lessonId: number;
}) {
  const { data: user } = useGetIdentity();
  const notification = useNotification();

  function formatLogResult(text: string) {
    if (lessonId === 3) {
      if (text?.length === 2 && text?.includes("1")) {
        return text[0]
      }
    }
    return text
  }

  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          marginBottom: 5,
        }}
      >
        {log.wasCorrect ? (
          <CheckOutlined color={"success"} />
        ) : (
          <ClearOutlined color={"error"} />
        )}
        {dataRef[log.textId].text || log.textId}
        <>
          {isPlaying ? (
            <PauseOutlined onClick={() => toogleAudio()} />
          ) : (
            <PlayArrowTwoTone onClick={() => toogleAudio()} />
          )}
        </>
      </div>

      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <span
          style={{
            color: "rgba(0,0,0,0.95)",
            alignSelf: "center",
            flex: 1,
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          {formatLogResult(log.result) || ""}
        </span>
        <span
          style={{
            color: "rgba(0,0,0,0.7)",
            alignSelf: "center",
            flex: 1,
            textAlign: "center",
            marginBottom: 2,
            fontSize: 10,
          }}
        >
          (Typing Practice)
        </span>
      </Stack>
    </Card>
  );
}

function WordItem({
  toogleAudio,
  showPlay,
  isPlaying,
  log,
  dataRef,
  lessonId,
}: {
  toogleAudio: any;
  showPlay: boolean;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  lessonId: number;
}) {
  const { data: user } = useGetIdentity();
  const notification = useNotification();
  const confirm = useConfirm();
  const invalidate = useInvalidate();

  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          marginBottom: 5,
        }}
      >
        {log.wasCorrect ? (
          <CheckOutlined color={"success"} />
        ) : (
          <ClearOutlined color={"error"} />
        )}
        {dataRef[log.textId].imgs ? (
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: 1 }}>
              {dataRef[log.textId].imgs.map((imgItm: string) => (
                <img src={imgItm} width={20} height={20} />
              ))}
            </div>
          </>
        ) : (
          <span
            dangerouslySetInnerHTML={{ __html: Array.isArray(dataRef[log.textId].text) ? dataRef[log.textId].text.join("") : dataRef[log.textId].text?.split("-").join("") }}
          ></span>
        )}
        {!log.wasCorrect && (
          <div
            style={{
              marginLeft: "auto",
              borderRadius: "50%",
              backgroundColor: "blue",
              width: 10,
              height: 10,
            }}
            onClick={() => {
              confirm({
                title: `Are you sure want to change the answer to ${!log.wasCorrect ? "CORRECT" : "INCORRECT"
                  }?`,
                cancellationText: "No",
                confirmationText: "Yes",
                description: `Changing the response to ${!log.wasCorrect ? "CORRECT" : "INCORRECT"
                  } will affect the student's grade.`,
              })
                .then(() => {
                  notification.open!!({
                    type: "progress",
                    message: "Changing result to correct",
                    key: "loadingResultForItem",
                  });
                  // console.log("student:", student);
                  console.log("log:", log);
                  createAxios(process.env.REACT_APP_BACKEND)
                    .post(`/admin/student/adjust`, {
                      lessonId,
                      questionId: log.textId,
                      isCorrect: !log.wasCorrect,
                      logId: log.id,
                    })
                    .then((res) => {
                      notification.close!!("loadingResultForItem");
                      notification.open!!({
                        type: "success",
                        message: "Result changed to correct",
                      });
                      invalidate({
                        resource: "student",
                        invalidates: ["all"],
                      });
                    })
                    .catch((err) => {
                      notification.open!!({
                        type: "error",
                        message: err.message,
                      });
                    });
                })
                .catch(() => { });
            }}
          ></div>
        )}
      </div>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {log.recording && (
          <>
            <span style={{ marginRight: 5, fontSize: 12 }}>
              {timeSince(log.created_at)}
            </span>
            {user?.userType == "admin" && (
              <RepeatOutlined
                onClick={() => {
                  var txtItem = (
                    dataRef[log.textId].ref || log.textId
                  ).toUpperCase();
                  notification.open!!({
                    type: "progress",
                    message: "Requesting for " + txtItem,
                    undoableTimeout: 10,
                    key: "loadingResultForItem",
                  });
                  createAxios(process.env.REACT_APP_BACKEND)
                    .post(`/admin/student/testScore/` + lessonId, {
                      s3Key: log.recording,
                      key: txtItem,
                    })
                    .then((res) => {
                      notification.close!!("loadingResultForItem");
                      if (res.data.isCorrect) {
                        notification.open!!({
                          type: "success",
                          message: "Service responded with correct",
                        });
                      } else {
                        notification.open!!({
                          type: "error",
                          message: "Service responded with incorrect",
                        });
                      }
                    });
                }}
              />
            )}
            {showPlay && (
              <>
                {isPlaying ? (
                  <PauseOutlined onClick={() => toogleAudio()} />
                ) : (
                  <PlayArrowTwoTone onClick={() => toogleAudio()} />
                )}
              </>
            )}
            <DownloadOutlined
              onClick={() => {
                //var content = content of file;
                var dl = document.createElement("a");
                dl.setAttribute(
                  "href",
                  `https://asr-record.s3.amazonaws.com/${log.recording}`
                );
                dl.setAttribute("download", log.textId + ".wav");
                dl.target = "_blank";
                dl.click();
              }}
            />
          </>
        )}
        {!log.recording && (
          <span
            style={{
              color: "rgba(0,0,0,0.25)",
              alignSelf: "center",
              flex: 1,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Recording empty
          </span>
        )}
      </Stack>
    </Card>
  );
}

function SentenceItem({
  toogleAudio,
  isPlaying,
  log,
  dataRef,
  shouldShowLevel,
  sentenceIndex,
}: {
  toogleAudio: any;
  isPlaying: boolean;
  log: any;
  dataRef: any;
  shouldShowLevel: any;
  sentenceIndex: any;
}) {
  var wRes = log.sentence;
  var sentence = dataRef[log.textId].text;
  if (Array.isArray(sentence)) {
    sentence = sentence.join(" ");
  }
  return (
    <Card
      key={log.id}
      sx={{
        display: "flex",
        border: "1px solid",
        padding: 1,
        flexDirection: "column",
        gridColumn: log.format == "sentence" ? "1/-1" : undefined,
      }}
    >
      {shouldShowLevel && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            marginBottom: 5,
          }}
        >
          <span
            style={{
              color: "rgba(0,0,0,0.6)",
              alignSelf: "start",
              flex: 1,
              textAlign: "start",
              marginBottom: 2,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Level {sentenceIndex}
          </span>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <span style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
          {" "}
          {sentence.split(" ").map((word: string, i: any) => {
            var wrdK = word
              .toLowerCase()
              .replace(/(\.|\?)/gi, "")
              .replace(",", "");
            var result = wRes?.[wrdK + i];
            return (
              <span
                key={wrdK + i}
                style={{
                  color: result ? "green" : "red",
                  display: "inline-block",
                }}
              >
                {word}
              </span>
            );
          })}{" "}
        </span>
      </div>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {log.recording && (
          <>
            <span style={{ marginRight: 5, fontSize: 12 }}>
              {timeSince(log.created_at)}
            </span>
            {isPlaying ? (
              <PauseOutlined onClick={() => toogleAudio()} />
            ) : (
              <PlayArrowTwoTone onClick={() => toogleAudio()} />
            )}
            <DownloadOutlined
              onClick={() => {
                //var content = content of file;
                var dl = document.createElement("a");
                dl.setAttribute(
                  "href",
                  `https://asr-record.s3.amazonaws.com/${log.recording}`
                );
                dl.setAttribute("download", log.textId + ".wav");
                dl.target = "_blank";
                dl.click();
              }}
            />
          </>
        )}
        {!log.recording && (
          <span
            style={{
              color: "rgba(0,0,0,0.25)",
              alignSelf: "center",
              flex: 1,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Recording empty
          </span>
        )}
      </Stack>
    </Card>
  );
}

export default function FlRecordings({
  progresses,
  userIden,
  student,
}: {
  progresses: IProgress[];
  userIden: IUser;
  student: IStudent;
}) {
  const apiUrl = process.env.REACT_APP_BACKEND;
  const confirm = useConfirm();
  const dataProvider = useDataProvider();
  const [expanded, setExpandeds] = useState({});
  const notification = useNotification();
  const invalidate = useInvalidate();
  const [selectedLesson, setSelectedLesson] = useState(1);
  const [playState, setPlayState] = useState<{ [ky: string]: any }>({});
  useEffect(() => {
    var hashChecker: string | number | NodeJS.Timeout | undefined;
    var hashChanged = (hash: string) => {
      var lessonId =
        Number.parseInt(hash.replace("#", "").replace("L", "")) || 0;
      document.getElementById(`tab-L${lessonId}`)!!.scrollIntoView({
        behavior: "smooth",
      });
      setSelectedLesson(lessonId);
    };
    if ("onhashchange" in window) {
      window.onhashchange = function () {
        hashChanged(window.location.hash);
      };
      // Or $(window).bind( 'hashchange',function(e) {
      //       alert(window.location.hash);
      //   });
    } else {
      // @ts-ignore
      var prevHash = window.location.hash;
      // @ts-ignore
      hashChecker = window.setInterval(function () {
        if (window.location.hash != prevHash) {
          prevHash = window.location.hash;
          hashChanged(window.location.hash);
        }
      }, 100);
    }
    hashChanged(window.location.hash);
    return () => {
      clearInterval(hashChecker);
    };
  }, []);

  const currentLesson = useMemo(() => {
    if (student) {
      const progresses = [...(student as any).progresses];
      var currentLesson = Array.from(new Array(21))
        .map((_, i) => {
          var prgItem = progresses.find((a) => a.lessonId == i);
          console.log(i, "prgItem:", prgItem);
          return {
            lessonId: i,
            isLocked:
              prgItem && (prgItem?.isUnlocked === false || prgItem?.hasPassed),
          };
        })
        .filter((a) => !a.isLocked)
        .map((a) => a.lessonId)
        //     ||
        //     ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
        // )
        // .map((a, i)=>{
        //     return ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
        //         ? (a.lessonId - 1) : a.lessonId
        // })
        .sort((a, b) => a - b)?.[0];

      currentLesson = currentLesson >= 1 ? currentLesson : 0;
      return currentLesson;
    } else {
      return null;
    }
  }, [student]);

  useEffect(() => {
    if (!window.location.hash && currentLesson) {
      document.getElementById(`tab-L${currentLesson}`)!!.scrollIntoView({
        behavior: "smooth",
      });

      setSelectedLesson(currentLesson);
    }
  }, [currentLesson]);

  const activeLesson = useMemo(() => {
    return (
      student?.progresses
        ?.sort(
          (a, b) =>
            moment(b.last_active).toDate().getTime()!! -
            moment(a.last_active).toDate().getTime()!!
        )
        ?.find((a) => moment(new Date()).diff(a.last_active, "minute") < 1) ||
      null
    );
  }, [student]);

  const lessonLockAction = useCallback(
    (lessonId: any, isUnlocked: any = true) => {
      confirm({ description: "Are you sure?" })
        .then(() => {
          dataProvider()!!.custom!!({
            method: "post",
            url: `${apiUrl}/admin/student/${student!!.id!!}/${isUnlocked ? "unlock" : "lock"
              }/${lessonId}`,
          })
            .then((result) => {
              notification.open!!({
                type: "success",
                message: "Student progress resetted!",
              });
              invalidate({
                resource: "student",
                invalidates: ["all"],
              });
            })
            .catch((err) => {
              notification.open!!({
                type: "error",
                message: "Something went wrong!",
              });
            });
        })
        .catch(console.error);
    },
    [student, notification]
  );

  return (
    <>
      <Typography
        variant={"h4"}
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Fluency Recordings
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          alignSelf: "stretch",
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignSelf: "stretch",
            overflow: "hidden",
            display: "flex",
            height: "80vh",
          }}
        >
          <Tabs
            value={selectedLesson}
            onChange={(_, v) => setSelectedLesson(v)}
            variant="scrollable"
            indicatorColor={"secondary"}
            textColor={"secondary"}
            orientation={"vertical"}
            aria-label="basic tabs example"
          >
            {Array.from(Array(22)).map((label, i) => {
              var progress = student?.progresses?.filter(
                (pr) => pr.lessonId == i
              )?.[0];
              var isActive = progress;
              var isLocked = progress && !progress.isUnlocked;
              var completeTrials =
                progress &&
                progress.trial.filter((trial) => trial.isComplete)?.length;
              var failedTrials =
                progress &&
                progress.trial.filter(
                  (trial) => trial.isComplete && !trial.wasSuccessful
                )?.length;
              // @ts-ignore
              var wasComplete =
                progress && (completeTrials > 0 || progress.hasPassed);
              var wasSuccessful = (progress && progress.wasSuccessful) || (i === 21 && progress?.hasPassed);
              var itemIsPreTest = i === 0;
              var itemTitle = itemIsPreTest ? "Pre-Test" : `Lesson ${i}`;
              var itemColor = isLocked
                ? "black"
                : isActive
                  ? wasComplete
                    ? itemIsPreTest
                      ? "green"
                      : wasSuccessful
                        ? "green"
                        : "red"
                    : "blue"
                  : "gray";
              var itemStatus = isLocked ? (
                <b style={{ textAlign: "center", verticalAlign: "center" }}>
                  <LockOutlined fontSize={"inherit"} />{" "}
                  {wasSuccessful ? "Passed" : "Locked"}
                </b>
              ) : isActive ? (
                wasComplete ? (
                  itemIsPreTest ? (
                    <b style={{ textAlign: "center", verticalAlign: "center" }}>
                      <CheckOutlined fontSize={"inherit"} /> Completed
                    </b>
                  ) : (wasSuccessful) ? (
                    <b style={{ textAlign: "center", verticalAlign: "center" }}>
                      <CheckOutlined fontSize={"inherit"} /> Passed
                    </b>
                  ) : (
                    <b
                      style={{
                        textAlign: "center",
                        verticalAlign: "center",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ClearOutlined fontSize={"inherit"} /> Failed (
                      {failedTrials})
                    </b>
                  )
                ) : (
                  <b
                    style={{
                      textAlign: "center",
                      verticalAlign: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PlayArrowTwoTone fontSize={"inherit"} /> Pending
                  </b>
                )
              ) : (
                <b style={{ textAlign: "center", verticalAlign: "center" }}>
                  In-Active
                </b>
              );

              return (
                <Tab
                  iconPosition={"bottom"}
                  icon={
                    <Typography variant={"body2"} style={{ fontSize: 11 }}>
                      {itemStatus}
                    </Typography>
                  }
                  key={i}
                  value={i}
                  sx={{ color: itemColor }}
                  label={itemTitle}
                  {...a11yProps(i)}
                />
              );
            })}
          </Tabs>
        </Box>

        {student?.progresses &&
          Array.from(new Array(22)).map((_, i) => {
            const progress = student?.progresses.find((pr) => pr.lessonId === i);
            var isActive = progress;

            // @ts-ignore
            const lessonkeys = LessonKeys[`lesson${i}`];
            const lesson = Lessons[i];

            // @ts-ignore
            const completeTrials: any[] =
              progress && progress.trial.filter((trial) => trial.isComplete);
            // @ts-ignore
            const lastTrial =
              progress &&
              completeTrials.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              )[0];
            // @ts-ignore
            const firstSuccessfulTrial =
              progress &&
              completeTrials
                .filter((a) => a.wasSuccessful)
                .sort(
                  (a, b) =>
                    new Date(a.updated_at).getTime() -
                    new Date(b.updated_at).getTime()
                )[0]
            const itemPreTest = i === 0;

            return (
              selectedLesson == i && (
                <Stack
                  key={`progress_${i}`}
                  sx={{
                    padding: 2,
                    position: "relative",
                    minHeight: 90,
                    flex: 1,
                  }}
                >
                  {progress && (
                    <Card>
                      <Stack
                        p={2}
                        justifyContent="space-between"
                        direction={"column"}
                        spacing={1}
                      >
                        <Typography variant="h6">
                          <b>{lesson.title}</b>
                        </Typography>
                        <Stack
                          justifyContent="space-between"
                          alignItems={"center"}
                          direction={"row"}
                          spacing={1}
                        >
                          {progress &&
                            ((lastTrial && !firstSuccessfulTrial) ||
                              firstSuccessfulTrial) ? (
                            <>
                              {lastTrial &&
                                !firstSuccessfulTrial &&
                                !itemPreTest && (
                                  i === 21 && progress && !progress?.hasPassed
                                ) && (
                                  <Badge
                                    sx={{
                                      background: "#D01D24",
                                      px: 1,
                                      py: 0.1,
                                      borderRadius: 10,
                                      fontSize: 16,
                                      color: "#fff",
                                    }}
                                    color={"success"}
                                  >
                                    <span
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 5,
                                      }}
                                    >
                                      <ClearOutlined fontSize={"inherit"} />
                                      Failed{" "}
                                      <b>
                                        {moment(lastTrial.updated_at).format(
                                          "MM/DD/YY"
                                        )}
                                      </b>
                                    </span>
                                  </Badge>
                                )}
                              {((firstSuccessfulTrial && !itemPreTest) || (i === 21 && progress?.hasPassed)) && (
                                <Badge
                                  sx={{
                                    background: "#04A94D",
                                    px: 1,
                                    py: 0.1,
                                    borderRadius: 10,
                                    fontSize: 16,
                                    color: "#fff",
                                  }}
                                  color={"success"}
                                >
                                  <span
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: 5,
                                    }}
                                  >
                                    <CheckOutlined fontSize={"inherit"} />
                                    Passed{" "}
                                    <b>
                                      {moment(
                                        firstSuccessfulTrial?.updated_at ?? lastTrial.updated_at
                                      ).format("MM/DD/YY")}
                                    </b>
                                  </span>
                                </Badge>
                              )}
                            </>
                          ) : progress.isUnlocked ? (
                            <>
                              <Badge
                                sx={{
                                  background: "gray",
                                  px: 1,
                                  py: 0.1,
                                  borderRadius: 10,
                                  fontSize: 16,
                                  color: "#000",
                                }}
                                color={"warning"}
                              >
                                <span
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "center",
                                  }}
                                >
                                  In-Progress
                                </span>
                              </Badge>
                            </>
                          ) : (
                            <>
                              <Badge
                                sx={{
                                  background: "gray",
                                  px: 1,
                                  py: 0.1,
                                  borderRadius: 10,
                                  fontSize: 16,
                                  color: "#000",
                                }}
                                color={"warning"}
                              >
                                <span
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "center",
                                  }}
                                >
                                  Lesson Locked
                                </span>
                              </Badge>
                            </>
                          )}
                          <Stack
                            justifyContent="flex-end"
                            alignItems={"center"}
                            direction={"row"}
                            spacing={1}
                          >
                            <Button
                              sx={{ color: "#fff" }}
                              variant={"contained"}
                              startIcon={
                                progress?.isUnlocked ? (
                                  <LockOutlined />
                                ) : (
                                  <LockOpenOutlined />
                                )
                              }
                              color={
                                progress?.isUnlocked ? "error" : "secondary"
                              }
                              disabled={!progress}
                              onClick={() => {
                                lessonLockAction(
                                  progress?.lessonId,
                                  !progress?.isUnlocked
                                );
                              }}
                            >
                              {progress?.isUnlocked ? "Lock" : "UnLock"} Lesson
                            </Button>
                            <Button
                              color={"error"}
                              variant={"contained"}
                              disabled={!progress}
                              onClick={() => {
                                confirm({
                                  description:
                                    "Resetting the lesson will completely remove their progress from the database!",
                                })
                                  .then(() => {
                                    console.log({ progress })
                                    dataProvider()!!.custom!!({
                                      method: "delete",
                                      url: `${apiUrl}/admin/student/${student!!
                                        .id!!}/lesson/${progress!!.id}`,
                                    })
                                      .then((result) => {
                                        notification.open!!({
                                          type: "success",
                                          message: "Student progress resetted!",
                                        });
                                        invalidate({
                                          resource: "student",
                                          invalidates: ["all"],
                                        });
                                      })
                                      .catch((err) => {
                                        notification.open!!({
                                          type: "error",
                                          message: "Something went wrong!",
                                        });
                                      });
                                  })
                                  .catch(() => { });
                              }}
                            >
                              Reset Lesson
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Card>
                  )}

                  {progress && (
                    <>
                      <Stack
                        sx={{ mt: 2 }}
                        spacing={1}
                        justifyContent={"flex-start"}
                      >
                        {/*<UserInfoText>*/}
                        {/*    <PercentOutlined />*/}
                        {/*    <Typography variant="body1">Maximum Score <b>{(progress?.trial as any[]).sort((a, b)=>(a.percentage - b.percentage))[0]?.percentage}</b></Typography>*/}
                        {/*</UserInfoText>*/}
                        {itemPreTest &&
                          Object.entries(
                            progress.trial
                              .map((itm) => itm.logs)
                              .reduce((p, c) => [...p, ...c], [])
                              .sort(
                                (
                                  a: { created_at: string },
                                  b: { created_at: string }
                                ) =>
                                  Date.parse(a.created_at) -
                                  Date.parse(b.created_at)
                              )
                              .reduce(
                                (
                                  prev: { [x: string]: any },
                                  curr: { group: string | number }
                                ) => {
                                  // @ts-ignore
                                  var gId;
                                  try {
                                    // @ts-ignore
                                    gId = lessonkeys.data[curr.textId].group;
                                    return {
                                      ...prev,
                                      [gId]: [...(prev[gId] || []), curr],
                                    };
                                  } catch (e) {
                                    // @ts-ignore
                                    // alert(curr.textId)
                                    return prev;
                                  }

                                  // return {...prev, [gId]: [...(prev[gId] || []), ...(gId?[curr]:[])]}
                                },
                                {}
                              )
                          ).map(([key, logs], i) => {
                            // @ts-ignore
                            const isExpanded = expanded[key];
                            // @ts-ignore
                            var firstItem = logs.sort(
                              (
                                a: { created_at: string },
                                b: { created_at: string }
                              ) =>
                                Date.parse(a.created_at) -
                                Date.parse(b.created_at)
                            )[0];
                            // @ts-ignore
                            var lastItem = logs.sort(
                              (
                                a: { created_at: string },
                                b: { created_at: string }
                              ) =>
                                Date.parse(b.created_at) -
                                Date.parse(a.created_at)
                            )[0];
                            var groupsLastSchemaKey = Object.keys(
                              lessonkeys.data
                            )
                              .filter((ky) => lessonkeys.data[ky].group == key)
                              .slice(-1);
                            const LessonKeyTitle = {
                              microphone: "Microphone",
                              p1: "Part 1",
                              p5: "Part 2",
                              p2: "Part 3",
                              p3: "Part 4",
                              p4: "Part 5",
                              p6: "Part 6",
                              p7: "Part 7",
                            };
                            // @ts-ignore
                            var itemTitle = LessonKeyTitle[key];

                            return (
                              <ListingGroup
                                level={""}
                                lessonId={progress.lessonId}
                                isScorable={false}
                                key={key}
                                isExpandable={false}
                                isExpanded={true}
                                showPlay={key != "microphone"}
                                {...{
                                  id: key,
                                  logs: logs,
                                  title: itemTitle,
                                  dataRef: lessonkeys.data,
                                  userIden,
                                  setPlayState,
                                  playState,
                                  setExpandeds,
                                  created_at: firstItem.created_at,
                                  isComplete:
                                    lastItem.textId == groupsLastSchemaKey,
                                  wasSuccessful: false,
                                  percentage: false,
                                }}
                              />
                            );
                          })}

                        {!itemPreTest &&
                          progress.trial
                            .sort(
                              (
                                a: { created_at: string },
                                b: { created_at: string }
                              ) =>
                                Date.parse(a.created_at) -
                                Date.parse(b.created_at)
                            )
                            .map((trial, i) => {
                              // @ts-ignore
                              const isExpanded =
                                progress.lessonId == 21 || expanded[trial.id];
                              let level = ""
                              if (progress.lessonId == 21) {
                                progress.trial.forEach((trial) => {
                                  trial.logs.forEach((log) => {
                                    if (log.format === "sentence") {
                                      if (log.score > 80 && log.wasCorrect) {
                                        level = log.textId
                                      }

                                    }
                                  })
                                })

                              }
                              return (
                                <ListingGroup
                                  level={level}
                                  lessonId={progress.lessonId}
                                  isScorable={true}
                                  key={trial.id}
                                  {...{
                                    setExpandeds,
                                    id: trial.id,
                                    logs: trial.logs,
                                    title: `#${i + 1}`,
                                    dataRef: lessonkeys.data,
                                    isExpanded,
                                    userIden,
                                    setPlayState,
                                    playState,
                                    created_at: trial.created_at,
                                    isComplete: trial.isComplete,
                                    wasSuccessful: trial.wasSuccessful,
                                    percentage: trial.percentage,
                                    student: student,
                                  }}
                                />
                              );
                            })}
                      </Stack>
                    </>
                  )}
                  {isActive && progress && progress.trial?.length < 1 && (
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ alignSelf: "stretch", flex: 1 }}
                    >
                      <Typography
                        variant={"h4"}
                        sx={{
                          verticalAlign: "center",
                          textAlign: "center",
                          fontSize: 30,
                        }}
                      >
                        {" "}
                        <PlayLessonOutlined fontSize={"inherit"} />{" "}
                        {lesson.title}
                      </Typography>
                      <span>No data yet, check back later.</span>
                    </Stack>
                  )}
                  {!isActive && (
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ alignSelf: "stretch", flex: 1 }}
                    >
                      <Typography
                        variant={"h4"}
                        sx={{
                          verticalAlign: "center",
                          textAlign: "center",
                          fontSize: 30,
                        }}
                      >
                        {" "}
                        <PlayLessonOutlined fontSize={"inherit"} />{" "}
                        {lesson.title}
                      </Typography>
                      {userIden?.userType !== "parent" && (
                        <Button
                          startIcon={<StartOutlined />}
                          onClick={() => {
                            lessonLockAction(i, true);
                          }}
                        >
                          Activate Lesson
                        </Button>
                      )}
                    </Stack>
                  )}
                </Stack>
              )
            );
          })}
      </Box>
    </>
  );
}

// @ts-ignore

// @ts-ignore
function ListingGroup({
  setExpandeds,
  showPlay = true,
  isExpandable = true,
  logs,
  isExpanded,
  dataRef,
  lessonId,
  userIden,
  setPlayState,
  playState,
  isScorable,
  id,
  title,
  created_at,
  isComplete,
  wasSuccessful,
  percentage,
  level
}) {
  // @ts-ignore
  return (
    <Card
      sx={{
        ...(isExpanded ? {} : { maxHeight: 200 }),
        padding: 1,
        position: "relative",
        marginTop: 0.4,
        marginBottom: 1,
      }}
    >
      <Stack
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}
        spacing={1}
      >
        {/*{*/}
        {/*    trial?.isComplete ?*/}

        {/*        <Typography variant="subtitle1">#{i+1} (<b>{moment(trial.created_at).format("MM/DD/YY")}</b>)*/}
        {/*            <Badge sx={{px: 1, py: 0.1, ml: 2, borderRadius: 10, background:trial.wasSuccessful ?  "#04A94D" : "#D01D24", color:"#fff"}} color={"error"}>{trial.wasSuccessful ? "Passed" : "Failed"}</Badge></Typography>*/}

        {/*        : */}

        {/*}*/}
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ fontSize: 24, fontWeight: "bold" }}
            variant={"h4"}
          >
            {title}
          </Typography>{" "}
          <Badge
            sx={{
              px: 1,
              py: 0.1,
              borderRadius: 10,
              fontSize: 16,
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <HistoryOutlined fontSize={"inherit"} />{" "}
            <span>{moment(created_at).fromNow()}</span>
          </Badge>
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {isExpandable &&
            logs.filter(
              (log: { format: string; wasCorrect: any }) =>
                userIden.userType == "admin" || !log.wasCorrect
            ).length > 0 && (
              <>
                {!isExpanded ? (
                  <a
                    style={{ color: "#000", fontSize: 16, marginRight: 10 }}
                    href={"javascript:void(0);"}
                    onClick={() => {
                      setExpandeds((exp: any) => ({ ...exp, [id]: true }));
                    }}
                  >
                    Show more
                  </a>
                ) : (
                  <a
                    style={{ color: "#000", fontSize: 16, marginRight: 10 }}
                    href={"javascript:void(0);"}
                    onClick={() => {
                      setExpandeds((exp: any) => ({ ...exp, [id]: false }));
                    }}
                  >
                    Show less
                  </a>
                )}
              </>
            )}

          {isComplete ? (
            (level && lessonId === 21) ? (
              <div
                style={{
                  padding: "5px 10px",
                  borderRadius: 10,
                  background: "#04A94D",
                  color: "#fff",
                }}
              >
                <b style={{ fontSize: 20 }}>
                  {level.replace("L", "") ?? 3}th
                </b>
              </div>
            ) :
              isScorable ? (
                <div
                  style={{
                    padding: "5px 10px",
                    borderRadius: 10,
                    background: wasSuccessful ? "#04A94D" : "#D01D24",
                    color: "#fff",
                  }}
                >
                  <b style={{ fontSize: 20 }}>{Math.round(percentage * 100)}%</b>
                </div>
              ) : (
                <Badge
                  sx={{
                    px: 1,
                    py: 0.1,
                    ml: 2,
                    borderRadius: 10,
                    background: "#04A94D",
                    color: "#fff",
                  }}
                  color={"success"}
                >
                  Complete
                </Badge>
              )
          ) : (
            <Badge
              sx={{
                px: 1,
                py: 0.1,
                ml: 2,
                borderRadius: 10,
                background: "#A3A3A3",
                color: "#000",
              }}
              color={"error"}
            >
              In-Progress
            </Badge>
          )}
        </div>
      </Stack>
      {!(
        logs.filter(
          (log: { format: string; wasCorrect: any }) =>
            userIden.userType == "admin" || !log.wasCorrect
        ).length > 0
      ) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            <span>Not practiced yet</span>
          </div>
        )}
      {Object.entries<any[]>(
        logs
          .sort(
            (a: { created_at: string }, b: { created_at: string }) =>
              Date.parse(a.created_at) - Date.parse(b.created_at)
          )
          .reduce(
            (prev: { [x: string]: any }, curr: { textId: string | number }) => {
              try {
                console.log(
                  {
                    dataRef,
                    curr: dataRef[curr.textId]
                  }
                )
                var isScored = isScorable
                  ? dataRef[curr.textId].scored
                  : "null";
                return {
                  ...prev,
                  [isScored]: [...(prev[isScored] || []), curr],
                };
              } catch (e) {
                return prev;
              }
              // var isScored = isScorable ?  dataRef[curr.textId].scored : 'null';
              // return {...prev, [isScored]: [...(prev[isScored] || []), curr]}
            },
            {}
          )
      )
        .sort(([a], [b]) => (a == "true" ? 1 : 0) - (b == "true" ? 1 : 0))
        .map(([isScored, listingLogs]) => {
          var isAny = isScored != "null";

          // @ts-ignore
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: isAny ? 10 : 0,
                }}
              >
                {isAny && (
                  <>
                    {isScored == "true" ? (
                      <b>
                        <PercentOutlined fontSize={"inherit"} /> Graded Practice
                      </b>
                    ) : (
                      <b>
                        <PlayLessonOutlined fontSize={"inherit"} /> Guided
                        Practice
                      </b>
                    )}
                  </>
                )}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    gridGap: 10,
                    marginTop: 10,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10,
                        background: "#D01D24",
                        borderRadius: 10,
                        color: "#fff",
                        fontSize: 18,
                      }}
                    >
                      <b>Incorrect Answers</b>
                      <span>
                        {new Set(getLogs(listingLogs, false, userIden.userType).map(d => d.textId)).size}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: 10,
                        marginTop: 10,
                      }}
                    >
                      {getLogs(listingLogs, false, userIden.userType).map(
                        (
                          log: {
                            format: string;
                            id: string | number;
                            textId: string;
                            recording: any;
                            student: any;
                            wasCorrect: any;
                          },
                          index
                        ) => {
                          return (
                            <>
                              {log.format == "sentence" && (
                                <SentenceItem
                                  shouldShowLevel={lessonId == 21}
                                  sentenceIndex={log.textId.replace("L", "")}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audio_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}
                              {log.format == "word" && (
                                <WordItem
                                  showPlay={showPlay}
                                  lessonId={lessonId}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audio_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}

                              {log.format == "text" && (
                                <TextItem
                                  lessonId={lessonId}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audioText_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}
                              {log.format == "text" && (
                                <audio
                                  onEnded={() => {
                                    var nPs = { ...playState };
                                    delete nPs[log.id];
                                    setPlayState(nPs);
                                  }}
                                  hidden={true}
                                  id={`audioText_${log.id}`}
                                  src={`https://read21-assets.s3.amazonaws.com/audios/lesson/${lessonId}/answers/${log.textId.toLowerCase()}.mp3`}
                                  preload="none"
                                />
                              )}
                              {log.recording && (
                                <audio
                                  onEnded={() => {
                                    var nPs = { ...playState };
                                    delete nPs[log.id];
                                    setPlayState(nPs);
                                  }}
                                  hidden={true}
                                  id={`audio_${log.id}`}
                                  src={`https://asr-record.s3.amazonaws.com/${log.recording}`}
                                  preload="none"
                                />
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10,
                        background: "#08A84C",
                        borderRadius: 10,
                        color: "#fff",
                        fontSize: 18,
                      }}
                    >
                      <b>Correct Answers</b>
                      <span>
                        {getLogs(listingLogs, true, userIden.userType).length}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridGap: 10,
                        marginTop: 10,
                      }}
                    >
                      {getLogs(listingLogs, true, userIden.userType).map(
                        (
                          log: {
                            format: string;
                            id: string | number;
                            textId: string;
                            recording: any;
                            student: any;
                            wasCorrect: any;
                          },
                          index
                        ) => {
                          return (
                            <>
                              {log.format == "sentence" && (
                                <SentenceItem
                                  shouldShowLevel={lessonId == 21}
                                  sentenceIndex={log.textId.replace("L", "")}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audio_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}
                              {log.format == "word" && (
                                <WordItem
                                  showPlay={showPlay}
                                  lessonId={lessonId}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audio_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}

                              {log.format == "text" && (
                                <TextItem
                                  lessonId={lessonId}
                                  isPlaying={playState[log.id]}
                                  toogleAudio={() => {
                                    // @ts-ignore
                                    var audioElem: HTMLAudioElement =
                                      document.getElementById(
                                        `audioText_${log.id}`
                                      )!!;
                                    var nPs = { ...playState };
                                    if (playState[log.id]) {
                                      audioElem.pause();
                                      delete nPs[log.id];
                                    } else {
                                      audioElem.play();
                                      nPs[log.id] = true;
                                    }
                                    setPlayState(nPs);
                                  }}
                                  log={log}
                                  dataRef={dataRef}
                                />
                              )}
                              {log.format == "text" && (
                                <audio
                                  onEnded={() => {
                                    var nPs = { ...playState };
                                    delete nPs[log.id];
                                    setPlayState(nPs);
                                  }}
                                  hidden={true}
                                  id={`audioText_${log.id}`}
                                  src={`https://read21-assets.s3.amazonaws.com/audios/lesson/${lessonId}/answers/${log.textId.toLowerCase()}.mp3`}
                                  preload="none"
                                />
                              )}
                              {log.recording && (
                                <audio
                                  onEnded={() => {
                                    var nPs = { ...playState };
                                    delete nPs[log.id];
                                    setPlayState(nPs);
                                  }}
                                  hidden={true}
                                  id={`audio_${log.id}`}
                                  src={`https://asr-record.s3.amazonaws.com/${log.recording}`}
                                  preload="none"
                                />
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </Card>
  );
}

const getLogs = (logs: any[], wasCorrect: boolean, userType: string) => {
  if (userType === "admin") {
    return logs.filter(
      (log: { wasCorrect: boolean }) => log.wasCorrect === wasCorrect
    );
  }

  const correctLogs = logs.filter(
    (log: { wasCorrect: boolean }) => log.wasCorrect === true
  );

  if (wasCorrect) {
    return correctLogs;
  }

  const correctTextIds = correctLogs.map(
    (log: { textId: string }) => log.textId
  );

  return logs.filter((log: { wasCorrect: boolean; textId: string }) => {
    return !correctTextIds.includes(log.textId) && !log.wasCorrect;
  });
};
