import React, { useEffect, useMemo } from "react";
import axios from "axios";
import { ContentCopyOutlined } from '@mui/icons-material';
import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
    HttpError,
    useNavigation,
    useRouterContext,
    useNotification,
    useDataProvider,
    useGetIdentity
} from "@pankod/refine-core";
import {
    Controller,
    UseModalFormReturnType,
    useForm
} from "@pankod/refine-react-hook-form";
import {
    Drawer,
    FormControlLabel,
    Input,
    Radio,
    RadioGroup,
    Avatar,
    Typography,
    FormLabel,
    Stack,
    Box,
    IconButton,
    FormControl,
    Autocomplete,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    Create,
    useAutocomplete,
    TextField,
    Button,
    MenuItem,
    Select,
} from "@pankod/refine-mui";
import { CloseOutlined } from "@mui/icons-material";

import { ITeacher, ISchool, IStudent } from "interfaces";
import { makeRandom } from "utils/string";
import { createAxios } from "utils/customAxios";
import { Parent } from "pages/parent/type";

export const StudentCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = process.env.REACT_APP_BACKEND;
    const { useLocation } = useRouterContext();
    const dataProvider = useDataProvider();
    const userData = useGetIdentity()
    const defaultDataProvider = dataProvider();
    /**
     * @type {Location}
     */
    const location = useLocation();
    const notification = useNotification();
    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors, touchedFields: { uname: unameTouched } },
        saveButtonProps
    } = useForm<IStudent, HttpError, IStudent>({
        refineCoreProps: {
            onMutationSuccess(data, variables, context) {

            },
        },
        defaultValues: useMemo(() => {
            return {
                school: userData?.data?.school,
            }
        }, [userData]),
    });
    //@ts-ignore
    const school = watch("school");
    //@ts-ignore
    const firstName = watch("firstName");
    //@ts-ignore
    const lastName = watch("lastName");
    //@ts-ignore
    const uname = watch("uname");
    useEffect(() => {
        if (!(unameTouched) && (firstName?.length > 0 && lastName?.length > 0) && school?.id) {
            setValue("uname", `${firstName}.${lastName}`.toLowerCase().replace(" ", ""), { shouldTouch: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, unameTouched, school])
    const { autocompleteProps: schoolAutoComplete } = useAutocomplete<ISchool>({
        resource: "school",
        errorNotification: false
    });
    const { autocompleteProps: teacherAutoComplete } = useAutocomplete<ITeacher>({
        resource: "teacher",
        filters: [
            {
                field: "school",
                operator: "eq",
                value: school?.id
            }
        ],
        errorNotification: false
    });

    const { autocompleteProps: parentAutoComplete } = useAutocomplete<Parent>({
        resource: "parent",
        errorNotification: false
    });



    useEffect(() => {
        const params = new URLSearchParams(location.search)
        if (params.get("school")) {
            defaultDataProvider.getOne({ resource: "school", id: params.get("school")!! }).then(result => {
                setValue("school", result.data as ISchool, { shouldValidate: true })

            })
        }
        if (params.get("parentId")) {
            defaultDataProvider.getOne({ resource: "parent", id: params.get("parentId")!! }).then(result => {
                setValue("parent", result.data as Parent, { shouldValidate: true })

            })
        }

    }, [])

    const imageInput = watch("photo");
    const password = watch("password");
    const onChangeHandler = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const formData = new FormData();

        const target = event.target;
        const file: File = (target.files as FileList)[0];

        formData.append("file", file);

        const res = await createAxios(apiUrl).post(
            `/media/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            },
        );

        const { name, size, type, lastModified } = file;

        const imagePaylod: any = [
            {
                name,
                size,
                type,
                lastModified,
                url: res.data.url,
            },
        ];

        setValue("photo", imagePaylod, { shouldValidate: true });
    };

    return (
        <Create
            saveButtonProps={saveButtonProps}
        >
            <Stack>
                <Box
                    paddingX="50px"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingX: {
                            xs: 1,
                            md: 6,
                        },
                    }}
                >
                    <form onSubmit={handleSubmit(onFinish)}>
                        <Stack gap="40px" direction={"row"}>
                            <FormControl sx={{ width: "40%" }}>
                                <FormLabel>
                                    Profile Image
                                </FormLabel>
                                <Stack
                                    display="flex"
                                    alignItems="center"
                                    border="1px dashed  "
                                    borderColor="primary.main"
                                    borderRadius="5px"
                                    padding="10px"
                                    marginTop="5px"
                                >
                                    <label htmlFor="images-input">
                                        <Input
                                            id="images-input"
                                            type="file"
                                            sx={{
                                                display: "none",
                                            }}
                                            onChange={onChangeHandler}
                                        />
                                        <input
                                            id="file"
                                            {...register("photo")}
                                            type="hidden"
                                        />
                                        <Avatar
                                            sx={{
                                                cursor: "pointer",
                                                width: {
                                                    xs: 100,
                                                    md: 180,
                                                },
                                                height: {
                                                    xs: 100,
                                                    md: 180,
                                                },
                                            }}
                                            src={
                                                imageInput && imageInput[0].url
                                            }
                                            alt="Photo URL"
                                        />
                                    </label>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontWeight: 800,
                                            marginTop: "8px",
                                        }}
                                    >
                                        Upload the photo of this user
                                    </Typography>
                                </Stack>
                                {errors.photoURL && (
                                    <FormHelperText error>
                                        {errors.photoURL.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Stack flex={1} gap="10px" marginTop="10px">

                                <FormControl>
                                    <FormLabel required>
                                        First Name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="firstName"
                                        {...register("firstName", {
                                            required: "First name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.firstName && (
                                        <FormHelperText error>
                                            {errors.firstName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        Last name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="lastName"
                                        {...register("lastName", {
                                            required: "Last name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.lastName && (
                                        <FormHelperText error>
                                            {errors.lastName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        Grade
                                    </FormLabel>
                                    <OutlinedInput
                                        id="grade"
                                        type={"number"}
                                        {...register("grade", {
                                            required: "Grade is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.grade && (
                                        <FormHelperText error>
                                            {errors.grade.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        Module Type
                                    </FormLabel>
                                    <Select
                                        id="licenseType"
                                        //@ts-ignore
                                        {...register("licenseType", {
                                            required: "License Type is required",
                                        })}
                                        // value={expiryType || null}
                                        style={{ height: "40px" }}
                                    >
                                        <MenuItem value={"fl"}>Fluency</MenuItem>
                                        <MenuItem value={"ct"}>Critical Thinking</MenuItem>
                                        <MenuItem value={"full"}>
                                            Full
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {userData?.data?.userType !== "parent" && (
                                    <>
                                        <FormControl fullWidth>
                                            <FormLabel
                                                required
                                                sx={{
                                                    marginBottom: "8px",
                                                    fontWeight: "700",
                                                    fontSize: "14px",
                                                    color: "text.primary",
                                                }}
                                            >
                                                School
                                            </FormLabel>
                                            <Controller
                                                control={control}
                                                name="school"

                                                rules={{
                                                    required: "School required",
                                                }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        size="small"
                                                        {...schoolAutoComplete}
                                                        {...field}
                                                        value={field.value || null}
                                                        onChange={(_, value) => {
                                                            field.onChange(value);
                                                        }}
                                                        getOptionLabel={(item) => {
                                                            return item.name
                                                                ? item.name
                                                                : "";
                                                        }}
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            value === undefined ||
                                                            option.id === value.id
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                error={
                                                                    !!errors.school
                                                                        ?.message
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                            {errors.school && (
                                                <FormHelperText error>
                                                    {errors.school.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        {
                                            !watch("parent") && (
                                                <FormControl fullWidth>
                                                    <FormLabel

                                                        sx={{
                                                            marginBottom: "8px",
                                                            fontWeight: "700",
                                                            fontSize: "14px",
                                                            color: "text.primary",
                                                        }}
                                                    >
                                                        Teacher
                                                    </FormLabel>
                                                    <Controller
                                                        control={control}
                                                        name="teacher"
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                size="small"
                                                                {...teacherAutoComplete}
                                                                {...field}
                                                                value={field.value || null}
                                                                disabled={!school?.id}
                                                                onChange={(_, value) => {
                                                                    field.onChange(value);
                                                                }}
                                                                getOptionLabel={(item) => {
                                                                    return item.firstName + " " + item.lastName;
                                                                }}
                                                                isOptionEqualToValue={(
                                                                    option,
                                                                    value,
                                                                ) =>
                                                                    value === undefined ||
                                                                    option.id === value.id
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        required={false}
                                                                        variant="outlined"
                                                                        error={
                                                                            !!errors.teacher
                                                                                ?.message
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    {errors.teacher && (
                                                        <FormHelperText error>
                                                            {errors.teacher.message}
                                                        </FormHelperText>
                                                    )}
                                                    <FormHelperText >
                                                        Select school before selecting a teacher
                                                    </FormHelperText>
                                                </FormControl>
                                            )
                                        }

                                        {
                                            userData?.data?.userType === "admin" && !watch("teacher") && (
                                                <FormControl fullWidth>
                                                    <FormLabel

                                                        sx={{
                                                            marginBottom: "8px",
                                                            fontWeight: "700",
                                                            fontSize: "14px",
                                                            color: "text.primary",
                                                        }}
                                                    >
                                                        Parent
                                                    </FormLabel>
                                                    <Controller
                                                        control={control}
                                                        name="parent"
                                                        rules={{
                                                            required: "Parent",
                                                        }}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                disabled={!school?.id}
                                                                size="small"
                                                                {...parentAutoComplete}
                                                                {...field}
                                                                value={field.value as any || null}
                                                                onChange={(_, value) => {
                                                                    field.onChange(value);
                                                                }}
                                                                getOptionLabel={(item) => {
                                                                    return item.firstName + " " + item.lastName;
                                                                }}
                                                                isOptionEqualToValue={(
                                                                    option,
                                                                    value,
                                                                ) =>
                                                                    value === undefined ||
                                                                    option.id === value.id
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        error={
                                                                            !!errors.teacher
                                                                                ?.message
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    {errors.teacher && (
                                                        <FormHelperText error>
                                                            {errors.teacher.message}
                                                        </FormHelperText>
                                                    )}
                                                    <FormHelperText >
                                                        Select school before selecting a parent
                                                    </FormHelperText>
                                                </FormControl>
                                            )
                                        }
                                    </>
                                )}



                                <FormControl fullWidth style={{
                                    marginTop: 20
                                }}>
                                    <FormLabel required>
                                        Username
                                    </FormLabel>
                                    <OutlinedInput
                                        id="uname"
                                        {...register("uname", {
                                            required: "Username is required!",
                                            pattern: {
                                                value: /^[A-Za-z0-9\.]{5,30}$/,
                                                message: "Invalid user name, 5-30, only A-Z 0-9 & '.' allowed."
                                            },
                                            validate: async (uname) => {
                                                const result = await createAxios(apiUrl).get(`/admin/student/exists/${uname}?school=${school?.id}`);
                                                if (result.data.count > 0) {
                                                    return ("Username already taken!")
                                                } else {
                                                    return true
                                                }
                                            },
                                            min: 5
                                        })}
                                        disabled={userData.data?.userType !== "parent" && !school?.id}
                                        value={uname || null}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.uname && (
                                        <FormHelperText error>
                                            {errors.uname.message}
                                        </FormHelperText>
                                    )}
                                    {
                                        userData?.data?.userType !== "parent" && <FormHelperText >
                                            Select school before choosing a username
                                        </FormHelperText>
                                    }

                                </FormControl>

                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Default Password
                                    </FormLabel>
                                    <OutlinedInput
                                        id="password"
                                        {...register("password", {
                                            required: "Password is required!",
                                        })}
                                        type="string"
                                        defaultValue={makeRandom()}
                                        style={{ height: "40px" }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button onClick={() => {
                                                    notification.open!({
                                                        message: "Password copied succesfully",
                                                        type: "success"
                                                    })

                                                }}>
                                                    <ContentCopyOutlined />
                                                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password && (
                                        <FormHelperText error>
                                            {errors.password.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Create>
    );
};