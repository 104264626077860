import { HttpError } from "@pankod/refine-core";
import axios, { AxiosInstance, AxiosRequestConfig,  } from "axios";
import { errorMonitor } from "events";

export function createAxios(baseURL?: string): AxiosInstance {
    const axiosInstance = axios.create({baseURL});
    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if(error.response?.status == 502){
                const customError: HttpError = {
                    message: error.response?.data?.message,
                    statusCode: error.response?.status,
                };
                return Promise.reject(customError);
            }else{
                return Promise.reject(error)
            }
           
    
        
        },
    );
    axiosInstance.interceptors.request.use(
        // Here we can perform any function we'd like on the request
        (request: AxiosRequestConfig) => {
            // Retrieve the token from local storage
            const token = localStorage.getItem("token");
            // Check if the header property exists
            if (request.headers) {
                // Set the Authorization header if it exists
                request.headers[
                    "Authorization"
                ] = `Bearer ${token}`;
            } else {
                // Create the headers property if it does not exist
                request.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }

            return request;
        },
    );


    return axiosInstance
}