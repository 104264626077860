import { useGetIdentity, useList, useMany, useResource } from "@pankod/refine-core"
import { Box, Button, Checkbox, Modal, Typography } from "@pankod/refine-mui"
import { useEffect, useState } from "react"
import ReactPlayer from 'react-player'


export const AddStudentModal = () => {
    const [checked, setChecked] = useState(false)
    const [open, setOpen] = useState(false)
    const data = useList({
        resource: "student"
    })
    const { data: user } = useGetIdentity()
    useEffect(() => {
        const isFirstTime = localStorage.getItem(
            `introduced-${user?.id}`
        )
        if (data.data?.data.length === 0 && !isFirstTime) {
            setOpen(true)
        }
    }, [data, user])
    return (
        <Modal open={open} onClose={() => { }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)"
            }} className="px-4 md:px-0">
                <Box sx={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "5px",
                    padding: "40px"
                }} className="w-full md:w-1/2">
                    <Typography sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                    }}>
                        Welcome to Read 21’s Parent Portal!
                    </Typography>
                    <Typography sx={{
                        textAlign: "center"
                    }}>
                        You haven't added any students yet. Watch the video below to learn how to add a student.
                    </Typography>
                    <div className="mt-2 lg:w-full lg:h-80">
                        <ReactPlayer url="https://youtu.be/t1sJgyidZqQ?si=oIw1n_s-iqNUkbSW" onEnded={() => {
                            setChecked(true)
                        }} onDuration={(duration) => {
                            console.log(duration)
                        }} width={
                            "100%"
                        } height={
                            "100%"

                        } />
                    </div>


                    <div className="mt-10 flex flex-col items-center">
                        <Button sx={{
                            backgroundColor: checked ? "green" : "#C8CEC8",
                            color: "white",
                            fontWeight: "bold",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "darkgreen"
                            }
                        }} onClick={() => {
                            localStorage.setItem(`introduced-${user?.id}`, "true")
                            setOpen(false)
                        }}>
                            Get Started
                        </Button>
                    </div>

                </Box>
            </Box>
        </Modal>
    )
}