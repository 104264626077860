import { Avatar, Badge, styled, Typography } from "@pankod/refine-mui";
import moment from "moment/moment";
import { IUser } from "../interfaces";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function UserAvatar({ user }: { user: IUser }) {
  var diffTime = moment(new Date()).diff(user.last_active, "minute");
  return (
    <>
      {diffTime < 5 ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar src={user?.photoURL} alt={user?.firstName} />
        </StyledBadge>
      ) : (
        <Avatar src={user?.photoURL} alt={user?.firstName} />
      )}

      <Typography variant="subtitle2" sx={{ ml: 1 }}>
        {user?.firstName} {user?.lastName}
      </Typography>
    </>
  );
}
