import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useShow,
  useList,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Stack,
  Avatar,
  Typography,
  Grid,
  Card,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
  Tab,
  Tabs,
  CircularProgress,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Lessons } from "../../../const/lessons";

import {
  IActivityLog,
  IActivityProgress,
  IPrincipal,
  ISchool,
  IStudent,
  ITeacher,
} from "interfaces";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import {
  ChartJSOrUndefined,
  TypedChartComponent,
} from "react-chartjs-2/dist/types";
import { useCTLesson } from "const/ct";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// {
//     <Grid item xs={2} sm={4} md={4} key={index}>
//         <ReportCard index={index} onClick={(sc)=>{
//             push(`${showUrl("lessonReport", index+1)}?school=${sc?.id}`)
//         }}/>
//     </Grid>
// ))}
const labels = Array.from(Array(21)).map((_, index) => `Lesson ${index + 1}`); //['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function genColor(seed: number) {
  return `hsl(${(seed * 72) % 360}, 95%, 35%)`;
}

var clsList: { [key: string]: string } = {
  first: "First",
  second: "Second",
  third: "Third",
};
export const CTStudentReportShow: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push, listUrl } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const {
    queryResult: { data: studentData },
    showId,
  } = useShow();
  const [openedTab, setOpenedTab] = useState("overview");
  const [isCapturing, setIsCapturing] = useState(false);
  const [student, setStudent] = useState<IStudent | null>(null);
  const details = studentData?.data?.details as IActivityProgress[];
  const summary = studentData?.data?.summary as {
    Beginning
    : string,
    Build
    : string
    Comprehension
    : string,
    Vocabulary
    : string
  }
  const LessonKeys = useCTLesson(student?.grade ?? 5)
  // const details = useMemo(()=>{
  //     if(studentData?.data?.details){
  //         // for each IActivityProgress return the latest IActivityProgress, grouped by itemNumber
  //         return Object.values(studentData?.data?.details.filter((a: IActivityProgress)=>a.isComplete).sort((a: { created_at: string; }, b: { created_at: string; })=>Date.parse(b.created_at) - Date.parse(a.created_at))
  //             .reduce((acc: {[key: string]: IActivityProgress}, curr: IActivityProgress)=>{
  //             if(acc[curr.itemNumber]){
  //                 return acc
  //             }
  //             return {
  //                 ...acc,
  //                 [curr.itemNumber]: curr
  //             }
  //         }, {} as {[key: string]: IActivityProgress})) as IActivityProgress[]
  //
  //     }
  //     return studentData?.data?.details as IActivityProgress[]
  // }, [studentData?.data?.details])
  useEffect(() => {
    setStudent(studentData?.data?.student as IStudent);
  }, [studentData?.data]);
  const { autocompleteProps } = useAutocomplete<IStudent>({
    resource: "student",
  });
  return (
    <Grid
      container
      padding={4}
      gap={5}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Card
        style={{
          padding: 20,
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
          Student
        </Typography>
        <div style={{ alignSelf: "flex-end" }}>
          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              if (student?.id) {
                push(`${showUrl("student", student?.id)}`);
              }
            }}
          >
            Open
          </Button>
          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              if (student?.id) {
                push(`${listUrl("studentReportCT")}`);
              }
            }}
          >
            Back
          </Button>
        </div>
      </Card>
      <Card sx={{ flex: 1, padding: 2 }}>
        <Typography
          variant={"h5"}
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          {student?.firstName} {student?.lastName}
        </Typography>
        {!isCapturing && (
          <Tabs
            value={openedTab}
            onChange={(e, v) => {
              setOpenedTab(v);
            }}
          >
            <Tab label="Overview" value="overview" />
            <Tab label="Word Parts" value="wordPart" />
            <Tab label="Comprehension" value="comprehend" />
            <Tab label="Vocabulary" value="vocabulary" />
          </Tabs>
        )}
        {!studentData && (
          <div
            style={{
              alignItems: "center",
              padding: "30px 30px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {studentData && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {(isCapturing || openedTab == "overview") && (
              <>
                {isCapturing && (
                  <Typography
                    variant={"h6"}
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      margin: "20px 0px",
                    }}
                  >
                    Overview
                  </Typography>
                )}
                {
                  /// table with two columns, three rows, first row, first column is red, second row is orange, third is green, second column is text
                }

                <div
                  style={{
                    display: "flex",
                    margin: "20px 0px",
                    flexDirection: "row",
                    alignSelf: "stretch",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: 20,
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          background: "green",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        80% - 100%
                      </td>
                      <td> - On target</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "yellow",
                          width: 125,
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        65% - 79%
                      </td>
                      <td> - Progressing</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "red",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        0% - 64%
                      </td>
                      <td> - Deficient</td>
                    </tr>
                  </table>
                </div>

                <div>
                  <table className={"report-table positioned"}>
                    <tr>
                      <td style={{ background: "#e0e0e0" }}>Domain</td>
                      <td style={{ background: "#e0e0e0" }}>
                        Average of last 3 scores
                      </td>
                    </tr>
                    {[
                      { title: "Word Parts", acts: [1, 2] },
                      { title: "Comprehension", acts: [3] },
                      { title: "Vocabulary", acts: [4] },
                    ].map((domain, index) => {
                      const acts = details
                        .filter((act) => act.isComplete)
                        .filter(
                          (act) =>
                            domain.acts.indexOf(
                              ((act.itemNumber - 1) % 4) + 1
                            ) !== -1
                        )
                        .slice(-3);
                      const score =
                        Math.round(
                          (acts.reduce(
                            (acc, act) =>
                              acc + act.actualScore / act.expectedScore,
                            0
                          ) /
                            acts.length) *
                          1000
                        ) / 10;
                      var color;
                      let textColor;
                      if (score >= 80) {
                        textColor = "#fff";
                        color = "green";
                      } else if (score >= 65) {
                        textColor = "#000";
                        color = "yellow";
                      } else if (score >= 0) {
                        textColor = "#fff";
                        color = "red";
                      }
                      return (
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{domain.title}</td>
                          <td style={{ width: "70%" }}>
                            {score && acts.length > 0 ? (
                              <div
                                style={{
                                  width: `${score}%`,
                                  background: color,
                                  height: "100%",
                                  color: textColor,
                                  fontWeight: "bold",
                                }}
                              >
                                {score}%
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>

                <Typography
                  variant={"h6"}
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    margin: "20px 0px",
                  }}
                >
                  Overall Progress
                </Typography>

                <div>
                  <table
                    className={"report-table positioned"}
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>
                          AVG
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          Word Parts
                          <br />
                          (Prefix, Root, Suffix)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          Word Parts
                          <br />
                          (Building Vocabulary)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          Comprehension
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          High Frequency Vocabulary
                        </td>
                      </tr>
                      {details &&
                        Object.entries(
                          details.reduce((prev, curr) => {
                            var activityId =
                              Math.floor((curr.itemNumber - 1) / 4) + 1;
                            console.log([activityId, curr.itemNumber]);
                            // @ts-ignore
                            return {
                              ...prev,
                              [activityId]:
                                // @ts-ignore
                                [...(prev[activityId] || []), curr],
                            };
                          }, {})
                        ).map(([activityId, activiti], index) => {
                          var activities = (
                            activiti as IActivityProgress[]
                          ).map((item) => {
                            return {
                              ...item,
                              score:
                                Math.round(
                                  (item.actualScore / item.expectedScore) * 1000
                                ) / 10,
                              type: (item.itemNumber - 1) % 4,
                            };
                          });
                          // @ts-ignore
                          var average =
                            Math.round(
                              (activities.reduce(
                                (acc, act) => acc + act.score,
                                0
                              ) /
                                activities.length) *
                              10
                            ) / 10;
                          var activity1ScoreInc = activities
                            .filter((act) => act.type == 0)
                            .map((act) => act.score)[0];
                          var activity2ScoreInc = activities
                            .filter((act) => act.type == 1)
                            .map((act) => act.score)[0];
                          var activity3ScoreInc = activities
                            .filter((act) => act.type == 2)
                            .map((act) => act.score)[0];
                          var activity4ScoreInc = activities
                            .filter((act) => act.type == 3)
                            .map((act) => act.score)[0];

                          return (
                            <tr>
                              <td style={{ fontWeight: "bold" }}>
                                Lesson {activityId}
                              </td>
                              <ColoredPercentageCell value={average} />
                              <ColoredPercentageCell
                                value={activity1ScoreInc}
                              />
                              <ColoredPercentageCell
                                value={activity2ScoreInc}
                              />
                              <ColoredPercentageCell
                                value={activity3ScoreInc}
                              />
                              <ColoredPercentageCell
                                value={activity4ScoreInc}
                              />
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {(isCapturing || openedTab == "wordPart") && (
              <>
                {isCapturing && (
                  <Typography
                    variant={"h6"}
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      margin: "30px 0px",
                    }}
                  >
                    Word Parts
                  </Typography>
                )}
                {
                  /// table with two columns, three rows, first row, first column is red, second row is orange, third is green, second column is text
                }

                <div
                  style={{
                    display: "flex",
                    margin: "20px 0px",
                    flexDirection: "row",
                    alignSelf: "stretch",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: 20,
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          background: "green",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        80% - 100%
                      </td>
                      <td> - On target</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "yellow",
                          width: 125,
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        65% - 79%
                      </td>
                      <td> - Progressing</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "red",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        0% - 64%
                      </td>
                      <td> - Deficient</td>
                    </tr>
                  </table>
                </div>

                <div>
                  <table className={"report-table positioned"}>
                    <tr>
                      <td style={{ background: "#e0e0e0" }}>Word Parts</td>
                      <td style={{ background: "#e0e0e0" }}>
                        Average of last 3 scores
                      </td>
                    </tr>
                    {[{ title: "Definitions" }, { title: "Word Building" }, { title: "Build & Apply" }, { title: "Read It Out" }].map((domain, index) => {
                      //get hi
                      // totalScores = [[1],[1],[0,0],[0,0],[0,0],[1],[1],[1],[1],[1]]
                      const wordPart = summary.Vocabulary && summary.Build ? (parseInt(summary.Vocabulary) + parseInt(summary.Build)) / 2 : summary.Vocabulary ? parseInt(summary.Vocabulary) : summary.Build ? parseInt(summary.Build) : 0

                      const score = domain.title === "Definitions" ? parseInt(summary.Beginning) : domain.title === "Word Building" ? wordPart : domain.title === "Build & Apply" ? parseInt(summary.Build) : parseInt(summary.Vocabulary)

                      var textColor = "black";
                      var color;
                      if (score >= 80) {
                        color = "green";
                        textColor = "#fff";
                      } else if (score >= 65) {
                        color = "yellow";
                      } else if (score >= 0) {
                        color = "red";
                        textColor = "#fff";
                      }
                      return (
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{domain.title}</td>
                          <td style={{ width: "70%" }}>
                            {score ? (
                              <div
                                style={{
                                  width: `${score}%`,
                                  background: color,
                                  height: "100%",
                                  color: textColor,
                                  fontWeight: "bold",
                                }}
                              >
                                {score}%
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>

                <p style={{ margin: "10px 0px" }}>
                  For Word Parts, students are learning about prefixes, roots,
                  and suffixes.
                  <ul>
                    <li>
                      Prefixes are word parts that come at the beginning of a
                      whole root word.
                    </li>
                    <li>
                      Suffixes are word parts that come at the end of a whole
                      root word.
                    </li>
                    <li>Root words are free standing, independent words.</li>
                  </ul>
                </p>

                <table className={"report-table positioned"}>
                  <tr>
                    <td style={{ background: "#e0e0e0" }}>Word Parts</td>
                  </tr>
                  <tr>
                    <td>Definitions</td>
                    <td style={{ width: "70%" }}>
                      Students learn the meaning of prefix, suffix, and root
                      words.
                    </td>
                  </tr>
                  <tr>
                    <td>Word Building</td>
                    <td style={{ width: "70%" }}>
                      Students build words to match with given definitions.
                    </td>
                  </tr>

                  <tr>
                    <td>Build & Apply</td>
                    <td style={{ width: "70%" }}>
                      Students build words to fill in comprehensive sentences.
                    </td>
                  </tr>

                  <tr>
                    <td>Read it Out!</td>
                    <td style={{ width: "70%" }}>
                      Students read out sentences that were built.
                    </td>
                  </tr>
                </table>
              </>
            )}
            {(isCapturing || openedTab == "comprehend") && (
              <>
                {isCapturing && (
                  <Typography
                    variant={"h6"}
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      margin: "30px 0px",
                    }}
                  >
                    Comprehension
                  </Typography>
                )}
                {
                  /// table with two columns, three rows, first row, first column is red, second row is orange, third is green, second column is text
                }

                <div
                  style={{
                    display: "flex",
                    margin: "20px 0px",
                    flexDirection: "row",
                    alignSelf: "stretch",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: 20,
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          background: "green",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        80% - 100%
                      </td>
                      <td> - On target</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "yellow",
                          width: 125,
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        65% - 79%
                      </td>
                      <td> - Progressing</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "red",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        0% - 64%
                      </td>
                      <td> - Deficient</td>
                    </tr>
                  </table>
                </div>

                <div>
                  <table className={"report-table positioned"}>
                    <tr>
                      <td style={{ background: "#e0e0e0" }}>Comprehension</td>
                      <td style={{ background: "#e0e0e0" }}>
                        Average of last 3 scores
                      </td>
                    </tr>
                    {[
                      { title: "Eye it Out", acts: [3], type: ["iWord"] },
                      {
                        title: "Selecting Evidence",
                        acts: [3],
                        types: ["cSent"],
                      },
                      {
                        title: "Question & Answers",
                        acts: [3],
                        types: ["cOpts", "iReplace"],
                      },
                    ].map((domain, index) => {
                      var totalScores: number[] = Object.values(
                        details
                          .filter((act) => act.isComplete)
                          .filter((act) => {
                            if (
                              domain.acts.indexOf(
                                ((act.itemNumber - 1) % 4) + 1
                              ) !== -1
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                          .slice(-3)
                          .map((act) => {
                            if (domain.types) {
                              return [
                                act.itemNumber,
                                act.logs.filter((log) => {
                                  // @ts-ignore
                                  var type =
                                    (
                                      LessonKeys.days[act.itemNumber - 1]
                                        .questions[log.itemIndex] as any
                                    )?.subType ||
                                    LessonKeys.days[act.itemNumber - 1]
                                      .questions[log.itemIndex]?.type;
                                  // @ts-ignore
                                  return domain.types.indexOf(type) !== -1;
                                }),
                              ];
                            } else {
                              return [act.itemNumber, act.logs];
                            }
                          })
                          .reduce((prev, [itemNumber, curr]) => {
                            // @ts-ignore
                            var detailActivity = (
                              curr as IActivityLog[]
                            ).reduce((prev, curr) => {
                              return {
                                ...prev,
                                // @ts-ignore
                                [`${curr.itemIndex}-${itemNumber}`]: [
                                  curr.actualScore / curr.expectedScore,
                                  ...((prev[
                                    `${curr.itemIndex}-${itemNumber}`
                                  ] as number[]) || []),
                                ],
                              };
                            }, {});
                            return {
                              ...prev,
                              ...Object.keys(detailActivity).reduce(
                                (prev, curr) => {
                                  return {
                                    ...prev,
                                    // @ts-ignore
                                    [curr]: Math.max(
                                      ...(detailActivity[curr] as number[])
                                    ),
                                  };
                                },
                                {}
                              ),
                            };
                          }, {})
                      );

                      //get hi
                      // totalScores = [[1],[1],[0,0],[0,0],[0,0],[1],[1],[1],[1],[1]]

                      // @ts-ignore

                      // @ts-ignore
                      var score = Math.round(
                        (totalScores.reduce((a, b) => a + b, 0) /
                          totalScores.length) *
                        100
                      );

                      var color;
                      var textColor = "#000";
                      if (score >= 80) {
                        color = "green";
                        textColor = "#fff";
                      } else if (score >= 65) {
                        color = "yellow";
                      } else if (score >= 0) {
                        color = "red";
                        textColor = "#fff";
                      }
                      return (
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{domain.title}</td>
                          <td style={{ width: "70%" }}>
                            {(score && totalScores.length) > 0 ? (
                              <div
                                style={{
                                  width: `${score}%`,
                                  color: textColor,
                                  background: color,
                                  height: "100%",
                                  fontWeight: "bold",
                                }}
                              >
                                {score}%
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>

                {/*<p style={{margin: "10px 0px"}}>*/}
                {/*    For Word Parts, students are learning about prefixes, roots, and suffixes.*/}
                {/*    <ul>*/}
                {/*        <li>Prefixes are word parts that come at the beginning of a whole root word.</li>*/}
                {/*        <li>Suffixes are word parts that come at the end of a whole root word.</li>*/}
                {/*        <li>Root words are free standing, independent words.</li>*/}
                {/*    </ul>*/}
                {/*</p>*/}

                <table
                  className={"report-table positioned"}
                  style={{ margin: "30px 0px" }}
                >
                  <tr>
                    <td style={{ background: "#e0e0e0" }}>Comprehension</td>
                  </tr>
                  <tr>
                    <td>Eye it Out</td>
                    <td style={{ width: "70%" }}>
                      Students learn how to select context clues in questions.
                    </td>
                  </tr>
                  <tr>
                    <td>Selecting Evidence</td>
                    <td style={{ width: "70%" }}>
                      Students learn how to identify supporting evidence for
                      questions in complex text.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>Questions & Answers</td>
                    <td style={{ width: "70%" }}>
                      Students learn how to critically think out questions to
                      choose answers.
                    </td>
                  </tr>
                </table>
              </>
            )}
            {(isCapturing || openedTab == "vocabulary") && (
              <>
                {isCapturing && (
                  <Typography
                    variant={"h6"}
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      margin: "30px 0px",
                    }}
                  >
                    Vocabulary
                  </Typography>
                )}
                {
                  /// table with two columns, three rows, first row, first column is red, second row is orange, third is green, second column is text
                }

                <div
                  style={{
                    display: "flex",
                    margin: "20px 0px",
                    flexDirection: "row",
                    alignSelf: "stretch",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: 20,
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          background: "green",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        80% - 100%
                      </td>
                      <td> - On target</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "yellow",
                          width: 125,
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        65% - 79%
                      </td>
                      <td> - Progressing</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "red",
                          width: 125,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        0% - 64%
                      </td>
                      <td> - Deficient</td>
                    </tr>
                  </table>
                </div>

                <div>
                  <table className={"report-table positioned"}>
                    <tr>
                      <td style={{ background: "#e0e0e0" }}>Vocabulary</td>
                      <td style={{ background: "#e0e0e0" }}>
                        Average of last 3 scores
                      </td>
                    </tr>
                    {[
                      {
                        title: "Word Recognition",
                        acts: [4],
                        types: ["types", "speaks"],
                      },
                      {
                        title: "Word Usage",
                        acts: [4],
                        types: ["dragAndDrops"],
                      },
                      { title: "Read it Out", acts: [4], types: ["rio"] },
                    ].map((domain, index) => {
                      var totalScores: number[] = Object.values(
                        details
                          .filter((act) => act.isComplete)
                          .filter((act) => {
                            if (
                              domain.acts.indexOf(
                                ((act.itemNumber - 1) % 4) + 1
                              ) !== -1
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                          .slice(-3)
                          .map((act) => {
                            if (domain.types) {
                              return [
                                act.itemNumber,
                                act.logs.filter((log) => {
                                  // @ts-ignore
                                  var type =
                                    (
                                      LessonKeys.days[act.itemNumber - 1]
                                        .questions[log.itemIndex] as any
                                    )?.subType ||
                                    LessonKeys.days[act.itemNumber - 1]
                                      .questions[log.itemIndex]?.type;
                                  // @ts-ignore
                                  return domain.types.indexOf(type) !== -1;
                                }),
                              ];
                            } else {
                              return [act.itemNumber, act.logs];
                            }
                          })
                          .reduce((prev, [itemNumber, curr]) => {
                            // @ts-ignore
                            var detailActivity = (
                              curr as IActivityLog[]
                            ).reduce((prev, curr) => {
                              return {
                                ...prev,
                                // @ts-ignore
                                [`${curr.itemIndex}-${itemNumber}`]: [
                                  curr.actualScore / curr.expectedScore,
                                  ...((prev[
                                    `${curr.itemIndex}-${itemNumber}`
                                  ] as number[]) || []),
                                ],
                              };
                            }, {});
                            return {
                              ...prev,
                              ...Object.keys(detailActivity).reduce(
                                (prev, curr) => {
                                  return {
                                    ...prev,
                                    // @ts-ignore
                                    [curr]: Math.max(
                                      ...(detailActivity[curr] as number[])
                                    ),
                                  };
                                },
                                {}
                              ),
                            };
                          }, {})
                      );

                      // @ts-ignore
                      var score = Math.round(
                        (totalScores.reduce((a, b) => a + b, 0) /
                          totalScores.length) *
                        100
                      );

                      var color;
                      var textColor = "#000";
                      if (score >= 80) {
                        color = "green";
                        textColor = "#fff";
                      } else if (score >= 65) {
                        color = "yellow";
                        textColor = "#000";
                      } else if (score >= 0) {
                        color = "red";
                        textColor = "#fff";
                      }
                      return (
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{domain.title}</td>
                          <td style={{ width: "70%" }}>
                            {(score && totalScores.length) > 0 ? (
                              <div
                                style={{
                                  width: `${score}%`,
                                  background: color,
                                  height: "100%",
                                  color: textColor,
                                  fontWeight: "bold",
                                }}
                              >
                                {score}%
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>

                <table
                  className={"report-table positioned"}
                  style={{ margin: "30px 0px" }}
                >
                  <tr>
                    <td style={{ background: "#e0e0e0" }}>Vocabulary</td>
                  </tr>
                  <tr>
                    <td>Word Recognition</td>
                    <td style={{ width: "70%" }}>
                      Students learn how to identify high frequency,
                      non-phonetic words.
                    </td>
                  </tr>
                  <tr>
                    <td>Word Usage</td>
                    <td style={{ width: "70%" }}>
                      Students learn how to apply high frequency words in
                      comprehensive sentences.
                    </td>
                  </tr>

                  <tr>
                    <td>Read it Out!</td>
                    <td style={{ width: "70%" }}>
                      Students read sentences that involve high frequency words.
                    </td>
                  </tr>
                </table>
              </>
            )}
          </div>
        )}
      </Card>
      {/*<Card>*/}
      {/*    {*/}
      {/*        JSON.stringify(details?.map(act=>{*/}
      {/*            return {*/}
      {/*                itemNumber: act.itemNumber,*/}
      {/*                lessonId: Math.floor((act.itemNumber-1)/4)+1,*/}
      {/*                activityId: ((act.itemNumber-1)%4) +1 ,*/}
      {/*                score: (act.actualScore/act.expectedScore) * 100,*/}
      {/*                createdOn: moment(act.created_at).fromNow()*/}
      {/*            }*/}
      {/*        }))*/}
      {/*    }*/}
      {/*</Card>*/}
    </Grid>
  );
};

function ColoredPercentageCell({ value }: { value?: number }) {
  var color;
  var textColor = "white";
  if (!value) {
    return <td></td>;
  }
  if (value >= 80) {
    color = "green";
    textColor = "white";
  } else if (value >= 65) {
    color = "yellow";
    textColor = "black";
  } else if (value >= 1) {
    color = "red";
    textColor = "white";
  }
  return (
    <td style={{ background: color, fontWeight: "bold", color: textColor }}>
      {value}%
    </td>
  );
}
