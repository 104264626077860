import React, { useEffect } from "react";
import axios from "axios";
import { ContentCopyOutlined } from '@mui/icons-material';
import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
    HttpError,
    useNavigation,
    useRouterContext,
    useNotification,
    useDataProvider
} from "@pankod/refine-core";
import {
    Controller,
    UseModalFormReturnType,
    useForm
} from "@pankod/refine-react-hook-form";
import {
    Drawer,
    FormControlLabel,
    Input,
    Radio,
    RadioGroup,
    Avatar,
    Typography,
    FormLabel,
    Stack,
    Box,
    IconButton,
    FormControl,
    Autocomplete,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    Create,
    useAutocomplete,
    TextField,
    Button,
} from "@pankod/refine-mui";
import { CloseOutlined } from "@mui/icons-material";

import { ITeacher, ISchool } from "interfaces";
import { makeRandom } from "utils/string";
import { createAxios } from "utils/customAxios";

export const TeacherCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = process.env.REACT_APP_BACKEND;
    const { useLocation } = useRouterContext();
    const dataProvider = useDataProvider();
    const defaultDataProvider = dataProvider();
    /**
     * @type {Location}
     */
    const location = useLocation();
    const notification = useNotification();
    const { autocompleteProps } = useAutocomplete<ISchool>({
        resource: "school"
    });
    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        saveButtonProps
    } = useForm<ITeacher, HttpError, ITeacher>({
        refineCoreProps: {
            onMutationSuccess(data, variables, context) {

            },
        }
    });

    const school = watch("school")
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        if (params.get("school")) {
            defaultDataProvider.getOne({ resource: "school", id: params.get("school")!! }).then(result => {
                setValue("school", ({
                    id: result.data?.id
                }) as ISchool, { shouldValidate: true })

            })
        }

    }, [])

    const imageInput = watch("photo");
    const password = watch("password");
    const onChangeHandler = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const formData = new FormData();

        const target = event.target;
        const file: File = (target.files as FileList)[0];

        formData.append("file", file);

        const res = await createAxios(apiUrl).post(
            `/media/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            },
        );

        const { name, size, type, lastModified } = file;

        const imagePaylod: any = [
            {
                name,
                size,
                type,
                lastModified,
                url: res.data.url,
            },
        ];

        setValue("photo", imagePaylod, { shouldValidate: true });
    };

    return (
        <Create
            saveButtonProps={saveButtonProps}
        >
            <Stack>
                <Box
                    paddingX="50px"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingX: {
                            xs: 1,
                            md: 6,
                        },
                    }}
                >
                    <form onSubmit={handleSubmit(onFinish)}>
                        <Stack gap="40px" direction={"row"}>
                            <FormControl sx={{ width: "40%" }}>
                                <FormLabel>
                                    Profile Image
                                </FormLabel>
                                <Stack
                                    display="flex"
                                    alignItems="center"
                                    border="1px dashed  "
                                    borderColor="primary.main"
                                    borderRadius="5px"
                                    padding="10px"
                                    marginTop="5px"
                                >
                                    <label htmlFor="images-input">
                                        <Input
                                            id="images-input"
                                            type="file"
                                            sx={{
                                                display: "none",
                                            }}
                                            onChange={onChangeHandler}
                                        />
                                        <input
                                            id="file"
                                            {...register("photo")}
                                            type="hidden"
                                        />
                                        <Avatar
                                            sx={{
                                                cursor: "pointer",
                                                width: {
                                                    xs: 100,
                                                    md: 180,
                                                },
                                                height: {
                                                    xs: 100,
                                                    md: 180,
                                                },
                                            }}
                                            src={
                                                imageInput && imageInput[0].url
                                            }
                                            alt="Photo URL"
                                        />
                                    </label>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontWeight: 800,
                                            marginTop: "8px",
                                        }}
                                    >
                                        Upload the photo of this user
                                    </Typography>
                                </Stack>
                                {errors.photoURL && (
                                    <FormHelperText error>
                                        {errors.photoURL.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Stack flex={1} gap="10px" marginTop="10px">

                                <FormControl>
                                    <FormLabel required>
                                        First Name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="firstName"
                                        {...register("firstName", {
                                            required: "First name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.firstName && (
                                        <FormHelperText error>
                                            {errors.firstName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        Last name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="lastName"
                                        {...register("lastName", {
                                            required: "Last name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.lastName && (
                                        <FormHelperText error>
                                            {errors.lastName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth>
                                    <FormLabel
                                        required
                                        sx={{
                                            marginBottom: "8px",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            color: "text.primary",
                                        }}
                                    >
                                        School
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="school"

                                        rules={{
                                            required: "School required",
                                        }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                size="small"
                                                {...autocompleteProps}
                                                {...field}
                                                value={field.value || null}
                                                onChange={(_, value) => {
                                                    field.onChange({
                                                        id: value?.id
                                                    });
                                                }}
                                                getOptionLabel={(item) => {
                                                    return item.name
                                                        ? item.name
                                                        : "";
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) =>
                                                    value === undefined ||
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        error={
                                                            !!errors.school
                                                                ?.message
                                                        }
                                                        required
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.school && (
                                        <FormHelperText error>
                                            {errors.school.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Email
                                    </FormLabel>
                                    <OutlinedInput
                                        id="email"
                                        {...register("email", {
                                            required: "Email is required!",
                                            validate: async (email) => {
                                                const result = await createAxios(apiUrl).get(`/admin/exists/${email}`);
                                                if (result.data.count > 0) {
                                                    return "Email already used!"
                                                } else {
                                                    return true
                                                }
                                            }
                                        })}
                                        type="email"
                                        style={{ height: "40px" }}
                                    />
                                    {errors.email && (
                                        <FormHelperText error>
                                            {errors.email.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth style={{
                                    marginTop: 20
                                }}>
                                    <FormLabel required>
                                        Default Password
                                    </FormLabel>
                                    <OutlinedInput
                                        id="password"
                                        {...register("password", {
                                            required: "Password is required!",
                                        })}
                                        type="string"
                                        defaultValue={makeRandom()}
                                        style={{ height: "40px" }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button onClick={() => {
                                                    notification.open!({
                                                        message: "Password copied succesfully",
                                                        type: "success"
                                                    })

                                                }}>
                                                    <ContentCopyOutlined />
                                                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password && (
                                        <FormHelperText error>
                                            {errors.password.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Create>
    );
};