/** @format */

import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  Input,
  List,
  Stack,
  Avatar,
  Typography,
  Tooltip,
  Button,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

import { IPrincipal, ISchool, ITeacher } from "interfaces";
import moment from "moment";

export const TeacherList: React.FC<IResourceComponentsProps> = () => {
  const { show, create } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);

  const { dataGridProps, setFilters, filters, setCurrent } = useDataGrid<ITeacher>({
    permanentFilter:
      search?.length > 0
        ? [
          {
            // @ts-ignore
            field: "title_like",
            operator: "eq",
            value: search,
          },
        ]
        : [],
    initialPageSize: 10,
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  const columns = React.useMemo<GridColumns<ITeacher>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return (
            <>
              <Avatar src={principal?.photoURL} alt={principal?.firstName} />
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.firstName} {principal?.lastName}
              </Typography>
            </>
          );
        },
      },
      {
        field: "school",
        headerName: "School Name",
        flex: 1,
        minWidth: 100,
        valueGetter(v) {
          return v.row.school?.name;
        },
      },
      {
        field: "users[count]",
        headerName: "# Students",
        minWidth: 170,
        valueGetter(v) {
          // @ts-ignore
          return v.row?._count?.teacherOf;
        },
      },
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        minWidth: 200,
        valueFormatter(v) {
          const dt = moment(v.value);
          return dt.format("L") + ` (${dt.fromNow()})`;
        },
      },
    ],
    []
  );

  const [hideUnselected, setHideUnselected] = useState(false);

  return (
    <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {
            (!!selected.length || hideUnselected) && (
              <div className="flex items-center gap-2">
                {/* <Button color="secondary" onClick={() => {
                  setReportCardModal(true)
                }}>
                  Generate Report Card
                </Button> */}
                <Button onClick={() => {
                  if (!hideUnselected) {
                    setHideUnselected(true)
                    setFilters([
                      ...filters,
                      {
                        field: "id",
                        operator: "in",
                        value: selected,
                      },
                    ])
                    setCurrent(1)
                  } else {
                    setHideUnselected(false)
                    //@ts-expect-error
                    setFilters(prev => prev.filter(f => f.field !== "id"))
                  }
                }}>
                  {
                    hideUnselected ? "Show All" : "Hide Unselected"
                  }
                </Button>
              </div>

            )
          }
        </div>
      </div>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        checkboxSelection
        onCellClick={(cell) => {
          if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected(prev => prev.filter(id => id !== cell.row.id))
            } else {
              setSelected(prev => [...prev, cell.row.id])
            }
            return
          }
          if (cell.colDef.field == "school") {
            show("school", cell.row.school.id);
          } else {
            show("teacher", cell.row.id);
          }
        }}
      />
    </List>
  );
};
