import React from "react";

import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
    HttpError,
    useNavigation,
    useRouterContext,
} from "@pankod/refine-core";
import {
    Avatar,
    Button,
    Create,
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    SaveButton,
    Stack,
    Step,
    Stepper,
    StepButton,
    TextField,
    Typography,
    useAutocomplete,
    Autocomplete,
    Input,
    TextFieldProps,
    CreateButton,
} from "@pankod/refine-mui";
import { useForm, Controller, useModalForm } from "@pankod/refine-react-hook-form";
import { ISchool, IPrincipal } from "interfaces";
import { createAxios } from "utils/customAxios";

export const SchoolCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const { useHistory } = useRouterContext();
    const history = useHistory();

    const {createUrl} = useNavigation()

    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        saveButtonProps
    } = useForm<ISchool, HttpError, ISchool>({
        refineCoreProps: {
            onMutationSuccess(data, variables, context) {
                history.push(createUrl("principal")+`?school=`+data.data.id)
            },
        }
    });


    // const { autocompleteProps } = useAutocomplete<IPrincipal>({
    //     resource: "principal",
    //     filters: [{
    //         field:"unassigned",
    //         operator:"eq",
    //         value:true
    //     }]
    // });

 

    return (
        <Create
            isLoading={formLoading}
            saveButtonProps={saveButtonProps}
            
        >
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
                autoComplete="off"
            >
            <>
                        <Grid
                            container
                            sx={{
                                marginX: { xs: "10px" },
                            }}
                        >

                            <Grid item xs={12} md={12}>
                                <Grid container >
                                    <Grid item xs={12} marginRight={10} md={5}>
                                    <FormControl fullWidth>
                                                <FormLabel
                                                    required
                                                    sx={{
                                                        marginBottom: "8px",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        color: "text.primary",
                                                    }}
                                                >
                                                    School Code
                                                </FormLabel>
                                                <TextField
                                                    {...register("code", {
                                                        required: "School Code required!",
                                                        pattern: {
                                                            value: /^[A-Z0-9a-z]*$/i,
                                                            message: "School Code must not have space and must be A-Z + 0-9 + a-z",
                                                        },
                                                        min: 3,
                                                        max: 10,
                                                        validate: async (code)=>{
                                                            const result = await createAxios(apiUrl).get(`/school/exists/${code}`);
                                                            if(result.data.count > 0){
                                                                return "Code already used!"
                                                            }else{
                                                                return true
                                                            }
                                                        }
                                                    })}
                                                    size="small"
                                                    margin="none"
                                                    variant="outlined"
                                                    inputProps={{ style: { textTransform: "lowercase" } }}
                                                />
                                                {errors.code && (
                                                    <FormHelperText error>
                                                        {errors.code.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                    </Grid>
                                    <Grid item  xs={12} marginRight={10} md={5}>
                                    <FormControl fullWidth>
                                                <FormLabel
                                                    required
                                                    sx={{
                                                        marginBottom: "8px",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        color: "text.primary",
                                                    }}
                                                >
                                                    School Name
                                                </FormLabel>
                                                <TextField
                                                    {...register("name", {
                                                        required: "School Name required!",
                                                    })}
                                                    size="small"
                                                    margin="none"
                                                    variant="outlined"
                                                />
                                                {errors.name && (
                                                    <FormHelperText error>
                                                        {errors.name.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
            </Box>
        </Create>
    );
};