/** @format */

import {
  CrudFilters,
  IResourceComponentsProps,
  useCustom,
  useDeleteMany,
  useGetIdentity,
  useInvalidate,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@pankod/refine-core";
import {
  Avatar,
  Box,
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogTitle,
  GridColumns,
  Input,
  List,
  Modal,
  Typography,
  useDataGrid,
} from "@pankod/refine-mui";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import React, { useEffect, useMemo, useRef, useState } from "react";

import { IStudent, IUser } from "interfaces";
import moment from "moment";
import UserAvatar from "../../components/custom";
import { createAxios } from "../../utils/customAxios";
import { StudentType } from "utils/type";
import { ReportCard, ReportCardResponse } from "components/report-card";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSearchParams } from "react-router-dom";

export const StudentList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { data: user } = useGetIdentity();
  const notification = useNotification();
  const invalidate = useInvalidate();
  const { mutateAsync: mutateDelete } = useDeleteMany();
  const routerContext = useRouterContext();
  const locationItem = routerContext.useLocation();
  const [openReportCardModal, setReportCardModal] = useState(false);
  const params = routerContext.useLocation()
  const location = routerContext.useLocation();
  const navigate = useNavigation();

  const getSearchParams = () => new URLSearchParams(location.search);
  const updateSearchParams = (params: Record<string, string>) => {
    const searchParams = getSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    navigate.push(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };


  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);
  const permanentFilters = useMemo<CrudFilters>(() => {
    const crudFilter: CrudFilters = [];
    if (search?.length > 0) {
      crudFilter.push({
        field: "title",
        operator: "contains",
        value: search,
      });
    }
    if (locationItem?.pathname?.includes?.("archive")) {
      crudFilter.push({
        field: "archived",
        operator: "eq",
        value: true,
      });
    }

    return crudFilter;
  }, [search, locationItem]);
  // @ts-ignore
  // @ts-ignore
  const { dataGridProps, setFilters, filters, setCurrent } = useDataGrid<IStudent>({
    initialPageSize: parseInt(getSearchParams().get('pageSize') || '10'),
    initialCurrent: parseInt(getSearchParams().get('page') || '1'),
    permanentFilter: permanentFilters,
    initialSorter: [
      {
        field: "last_active",
        order: "desc",
      },
    ],

  });

  const defaultColumns = React.useMemo<GridColumns<StudentType>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const row = params.row;
          return (
            <UserAvatar
              user={
                {
                  firstName: row.firstName,
                  lastName: row.lastName,
                  user_status: row.user_status,
                  last_active: row.last_active,
                } as unknown as IUser
              }
            />
          );
        },
      },
      ...(user?.userType !== "parent" ? [{
        field: "stateCode",
        headerName: "State",
        minWidth: 120,
        flex: 1,
        valueGetter(v) {
          return v.row.stateCode;
        },
      }] : []),
      ...(user?.userType !== "parent" ? [{
        field: "name",
        headerName: "School Name",
        flex: 1,
        minWidth: 150,
        valueGetter(v) {
          return v.row.name;
        },
      },] : []),
      {
        field: "grade",
        headerName: "Grade",
        flex: 1,
        minWidth: 100,
        valueGetter(v) {
          return v.row.grade;
        },
      },
      ...(user?.userType !== "parent" ? [{
        field: "teacherFirstName",
        headerName: "Teacher",
        minWidth: 160,
        flex: 1,
        renderCell(params) {
          const teacher = params.row.teacherFirstName;
          return (
            <>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={params.row.photoURL}
                alt={teacher}
              />
              <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                {teacher}
              </Typography>
            </>
          );
        },
      }] : []),
      {
        field: "progress_lessonId",
        headerName: "Fluency Progress",
        minWidth: 120,
        flex: 1,
        valueGetter(params) {
          const progresses = (params.row as any).progress_lessonId;
          return progresses;
        },
        filterable: true,
        renderCell(params) {
          console.log(params.row)
          const parsed = parseInt(params.value);
          const progress = isNaN(parsed) ? 0 : parsed;
          const pretest = params.row.introductionPresented === 0 ? false : true;
          const isComplete = params.row.isComplete === 1 ? true : false;
          return (
            <Typography>
              <b>
                {progress <= 0
                  ? pretest
                    ? "Pre Test"
                    : "Not Started"
                  : isComplete
                    ? "Completed"
                    : `Lesson ${progress}`}
              </b>
            </Typography>
          );
        },
      },
      {
        field: "ctProgressItemNumber",
        headerName: "CT Progress",
        minWidth: 120,
        flex: 1,
        renderCell(params) {
          // const license = params.row.license;
          // const expiresOn = moment(license.expiresOn);
          // const createdOn = moment(license.created_at);
          const progress = params.row.ctProgressItemNumber ?? 0;
          return progress >= 160 && params.row.isComplete ? (
            <b>Complete</b>
          ) : progress ? (
            <span>
              Lesson <b>{Math.ceil((progress - 4) / 4) + 1}</b>
              <br />
              Activity <b>{((progress - 1) % 4) + 1}</b>
            </span>
          ) : (
            <b>Not Started</b>
          );
        },
      },
      {
        field: "last_active",
        headerName: "Last Active",
        flex: 1,
        minWidth: 150,
        renderCell(params) {
          var diffTime = moment(params.row.last_active).fromNow();

          // var lastLesson =
          //   progresses
          //     .sort((a, b) => b.last_active - a.last_active)
          //     .find(
          //       (a) => moment(new Date()).diff(a.last_active, "minute") < 1
          //     ) || null;

          return (
            <Typography variant="body1">
              {diffTime}
              {/* {lastLesson && (
                <>
                  <br />
                  on{" "}
                  <b>
                    {lastLesson.lessonId > 0 ? (
                      <>
                        Lesson <b>{lastLesson.lessonId}</b>
                      </>
                    ) : (
                      <b>Pre-Test</b>
                    )}
                  </b>
                </>
              )} */}
            </Typography>
          );
        },
      },
      {
        field: "expiresOn",
        headerName: "License Expiry",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const license = params.row;
          const expiresOn = moment(license.expiresOn);
          const createdOn = moment(license.created_at);
          return (
            <Typography variant="body1">
              {expiresOn.format("MM/DD/YYYY")}
              <br />
              <b>({expiresOn.fromNow()})</b>
            </Typography>
          );
        },
      },
      {
        field: "Delete",
        headerName: "Actions",
      },
    ].filter((col) => {
      if (user?.userType === "parent") {
        return col.field !== "teacherFirstName";
      }
      return true;
    }),
    [],
  );

  const [hideUnselected, setHideUnselected] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <List
      cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
      headerButtons={(context) => {
        return (
          <>
            {(user?.userType === "admin" || user?.userType === "principal") && (
              <Button
                variant="outlined"
                onClick={() => {
                  // open file picker and upload excel file to /mas endpoint

                  const input = document.createElement("input");
                  input.type = "file";
                  input.onchange = async (e) => {
                    notification.open!({
                      type: "progress",
                      message: "Uploading Bulk student list",
                      undoableTimeout: 10,
                      key: "loadingResultForItem",
                    });
                    const file = (e.target as HTMLInputElement).files![0];
                    const formData = new FormData();
                    formData.append("file", file);
                    const result = await createAxios(
                      process.env.REACT_APP_BACKEND,
                    ).post(`/admin/student/mas`, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                      validateStatus: (status) => {
                        return true; // I'm always returning true, you may want to do it depending on the status received
                      },
                    });
                    notification.close!("loadingResultForItem");
                    //show success or error message
                    if (result.status === 200) {
                      notification.open!({
                        type: "success",
                        message: "Students imported succesfully",
                      });
                    } else {
                      notification.open!({
                        type: "error",
                        message:
                          result.data?.message || "Error importing students",
                      });
                    }
                    // invalidate cache with useInvalidate
                    invalidate({
                      resource: "student",
                      invalidates: ["all"],
                    });
                  };
                  input.click();
                }}
              >
                Import
              </Button>
            )}
            {context.defaultButtons}
            {(user?.userType === "teacher" || user?.userType === "principal") && (
              <div
                onClick={() => setReportCardModal(true)}
                className="flex items-center gap-2 rounded-md bg-blue-600 px-3  text-white transition-all duration-200 hover:bg-blue-700 py-2"
                role="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M2.77 8.286A3.495 3.495 0 0 1 5.577 6.88c.818 0 1.57.28 2.166.75" />
                    <path d="M5.076 10.629h-3.5a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3" />
                    <path d="M5.576 5.379a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3m1.764 5.184c-.351-.061-.351-.565 0-.626a3.176 3.176 0 0 0 2.558-2.45l.021-.097c.076-.347.57-.349.649-.003l.026.113a3.193 3.193 0 0 0 2.565 2.435c.353.062.353.568 0 .63A3.192 3.192 0 0 0 10.594 13l-.026.113c-.079.346-.573.344-.649-.003l-.021-.097a3.176 3.176 0 0 0-2.558-2.45" />
                  </g>
                </svg>
                <p>Generate Report Cards</p>
              </div>
            )}
          </>
        );
      }}
    >
      {
        //Material Input box with onchange callback function
      }
      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {
            (!!selected.length || hideUnselected) && (
              <div className="flex items-center gap-2">
                {/* <Button color="secondary" onClick={() => {
                  setReportCardModal(true)
                }}>
                  Generate Report Card
                </Button> */}
                <Button onClick={() => {
                  if (!hideUnselected) {
                    setHideUnselected(true)
                    setFilters([
                      ...filters,
                      {
                        field: "id",
                        operator: "in",
                        value: selected,
                      },
                    ])
                    setCurrent(1)
                  } else {
                    setHideUnselected(false)
                    //@ts-expect-error
                    setFilters(prev => prev.filter(f => f.field !== "id"))
                  }
                }}>
                  {
                    hideUnselected ? "Show All" : "Hide Unselected"
                  }
                </Button>
                <Button color="error" variant="outlined" onClick={() => {
                  setShowDeleteDialog(true)
                }}>
                  Delete Selected
                </Button>
              </div>
            )
          }

        </div>
      </div>

      <DataGrid
        {...dataGridProps}
        onPageChange={(page, details) => {
          updateSearchParams({ page: page.toString() });
          dataGridProps.onPageChange?.(page, details);
        }}
        onPageSizeChange={(pageSize, details) => {
          updateSearchParams({ pageSize: pageSize.toString(), page: '1' });
          dataGridProps.onPageSizeChange?.(pageSize, details);
        }}
        columns={defaultColumns}
        checkboxSelection
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}

        disableSelectionOnClick
        sortingOrder={["asc", "desc"]}
        onColumnHeaderClick={(col) => {
          if (col.field === "__check__") {
            setSelected([])
          }

        }}
        onCellClick={(cell) => {
          if (cell.colDef.field === "school") {
            show("school", cell.row.school.id);
          } else if (cell.colDef.field === "teacher") {
            show("teacher", cell.value);
          } else if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected(prev => prev.filter(id => id !== cell.row.id))
            } else {
              setSelected(prev => [...prev, cell.row.id])
            }
          } else {
            show("student", cell.row.id);
          }
        }}


      />
      {(user?.userType === "teacher" || user?.userType === "principal") && (
        <ReportCardGenerateModal
          open={openReportCardModal}
          onClose={() => setReportCardModal(false)}
          students={
            selected.length ? dataGridProps.rows.filter((row) => selected.includes(row.id))
              : dataGridProps.rows as any ?? []
          }
        />
      )}
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>
          Are you sure you want to delete the selected students?
        </DialogTitle>
        <DialogActions className="px-4 py-2">
          <Button
            onClick={async () => {
              await mutateDelete({
                resource: "student",
                ids: selected,
                mutationMode: "pessimistic",

              });
              invalidate({
                resource: "student",
                invalidates: ["all"],
              })
              setShowDeleteDialog(false)
            }}
            color="error"
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
};

export const ReportCardGenerateModal = ({
  open,
  onClose,
  students,
  selectedOnly
}: {
  open: boolean;
  onClose: () => void;
  students: IStudent[];
  selectedOnly?: boolean;
}) => {
  const user = useGetIdentity();
  const url = `
      ${process.env.REACT_APP_BACKEND}admin/classReport/generateReportCard/${user.data?.id}
      `;
  const { data, refetch } = useCustom<ReportCardResponse[]>({
    url,
    method: "get",
  });
  const [reportsChunk, setReportsChunk] = useState<ReportCardResponse[]>(data?.data.slice(0, 20) ?? []);
  const date = new Date().toDateString().split(" ").splice(1).join("-");

  const [showInPdf, setShowInPdf] = useState(false);
  async function generateReportCard() {
    refetch();
    setShowInPdf(true);
  }

  useEffect(() => {
    if (!reportsChunk.length) {
      if (data?.data) {
        setReportsChunk(data?.data.slice(0, 20))
      }
    }
  }, [data])

  const pdfToSave = useRef<jsPDF[]>([])
  async function exportAsPdf(selector: string, pageCount?: number) {
    const pdf = new jsPDF("portrait", "pt", "a4", true);
    const element = document.querySelector(selector) as HTMLElement;
    element.style.display = "block"
    const options = {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: element.scrollHeight,
    }
    const data = await html2canvas(element, options);
    const img = data.toDataURL("image/jpeg", 0.5)
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    let heightLeft = imgProperties.height;
    let position = 0;
    const pageHeight = pdf.internal.pageSize.getHeight();
    pageCount = pageCount || Math.ceil(heightLeft / pageHeight);
    while (pageCount--) {
      try {
        pdf.addImage(img, "JPEG", 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;
        console.log(heightLeft, pageHeight);
        // Only add a new page if there's more than a page's height left
        if (heightLeft - pageHeight > 0) {
          pdf.addPage();
          position -= pageHeight;
        }
      } catch (e) {
        console.log(e);
      }
    }
    pdfToSave.current.push(pdf)
    element.style.display = "none"
  }

  useEffect(() => {
    if (showInPdf && reportsChunk.length && data?.data) {
      exportAsPdf("#students-report", 20).then(() => {
        const index = data?.data.indexOf(reportsChunk[reportsChunk.length - 1]);
        const nextChunk = data?.data.slice(index, index + 20);
        if (index === data.data.length - 1) {
          const zip = new JSZip();
          pdfToSave.current.map(async (pdf, index) => {
            const res = pdf.output("blob");
            zip.file("Report-Cards-" + (index + 1) + ".pdf", res);
          });
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `Report-Cards-${date}.zip`);
          });
          setShowInPdf(false);
          onClose();
          setShowInPdf(false);
          onClose()
        } else {
          setReportsChunk(nextChunk);
        }
      })

    }
  }, [showInPdf, reportsChunk]);


  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box bgcolor="#fff" p="16px" borderRadius="6px">
          <Typography variant="h4">Generate Report Cards</Typography>
          <Typography variant="body1">
            Note: This will generate report cards for all students in the list below in a zip file. Please be patient as this may take a few minutes.
          </Typography>

          <Button
            style={{
              marginTop: "16px",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              generateReportCard()
            }}
          >
            Generate
          </Button>

          {(
            <div id="students-report" className={"hidden"}>
              {reportsChunk.map((report) => {
                return (
                  <div id={report.id}>
                    <ReportCard res={report} />
                  </div>
                );
              })}
            </div>
          )}

          <Modal open={showInPdf}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Box bgcolor="#fff" p="16px" borderRadius="6px">
                <Typography variant="h4">Generating Report Cards...</Typography>
                <Typography variant="body1">
                  Please wait while we generate the report cards for you.
                </Typography>
              </Box>
            </div>
          </Modal>
        </Box>
      </div>
    </Modal>
  );
};
