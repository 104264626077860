/** @format */

import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  Input,
  Stack,
  Avatar,
  Typography,
  Tooltip,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

import { IPrincipal, ISchool } from "interfaces";
import moment from "moment";

export const PrincipalList: React.FC<IResourceComponentsProps> = () => {
  const { show, create } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const [search, setSearch] = React.useState<string>("");

  const { dataGridProps } = useDataGrid<IPrincipal>({
    permanentFilter:
      search?.length > 0
        ? [
            {
              // @ts-ignore
              field: "title_like",
              operator: "eq",
              value: search,
            },
          ]
        : [],
    initialPageSize: 10,
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  const columns = React.useMemo<GridColumns<IPrincipal>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return (
            <>
              <Avatar src={principal?.photoURL} alt={principal?.firstName} />
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.firstName} {principal?.lastName}
              </Typography>
            </>
          );
        },
      },
      {
        field: "principality",
        headerName: "School Name",
        flex: 1,
        minWidth: 100,
        valueGetter(v) {
          return v.row.principality?.name;
        },
      },
      {
        field: "users[count]",
        headerName: "# Teachers",
        minWidth: 100,
        valueGetter(v) {
          return v.row.principality?._count.users;
        },
      },
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        minWidth: 200,
        valueFormatter(v) {
          const dt = moment(v.value);
          return dt.format("L") + ` (${dt.fromNow()})`;
        },
      },
    ],
    []
  );

  return (
    <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
      <Input
        onChange={(e) => {
          setSearch(e.currentTarget.value!);
        }}
        placeholder={"Type here to search"}
        style={{ margin: 10, marginTop: 5, marginBottom: 20 }}
      />
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        onCellClick={(cell) => {
          if (cell.colDef.field == "principality") {
            show("school", cell.row.principality?.id);
          } else {
            show("principal", cell.row.id);
          }
        }}
      />
    </List>
  );
};
