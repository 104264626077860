import { useRouterContext } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";

import { SvgIcon, SvgIconProps } from "@pankod/refine-mui";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    const { Link } = useRouterContext();

    return (
        <Link to="/">
            <Box
                sx={{
                    height: "72px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {collapsed ? (
                    <SvgIcon
                    width="32"
                    height="25"
                    viewBox="0 0 229 240"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{ color: "common.white" }} 
                >
                    <path d="M54.1316 25.1958C58.2564 25.1958 62.2108 25.1958 66.2503 25.1958C66.2503 33.2926 66.2503 41.2091 66.2503 49.2529C58.2015 49.2529 50.1839 49.2529 42.0454 49.2529C42.0454 41.2902 42.0454 33.3336 42.0454 25.1958C46.0524 25.1958 50.0069 25.1958 54.1316 25.1958Z" fill="white"/>
                <path d="M96.0231 30.7602C94.0555 30.7602 92.258 30.7602 90.3759 30.7602C90.3759 22.6442 90.3759 14.7293 90.3759 6.69606C98.3524 6.69606 106.265 6.69606 114.183 6.69606C114.541 7.85263 114.673 27.795 114.308 30.7602C108.289 30.7602 102.241 30.7602 96.0231 30.7602Z" fill="white"/>
                <path d="M50.784 66.4638C50.784 69.6767 50.784 72.7193 50.784 75.851C45.3574 75.851 40.1201 75.851 34.7717 75.851C34.7717 70.4855 34.7717 65.1852 34.7717 59.8707C35.8769 59.5276 47.9987 59.4131 50.784 59.7617C50.784 61.9174 50.784 64.1055 50.784 66.4638Z" fill="white"/>
                <path d="M93.0113 50.1967C93.0366 49.5923 93.0619 49.1489 93.0939 48.5884C98.448 48.5884 103.736 48.5884 109.139 48.5884C109.139 53.8141 109.139 58.9986 109.139 64.1876C107.97 64.5516 96.5414 64.6903 93.2856 64.3765C93.2474 64.3364 93.2077 64.2976 93.1714 64.2559C93.1342 64.2133 93.0693 64.168 93.069 64.1237C93.0466 59.535 93.0291 54.9464 93.0113 50.1967Z" fill="white"/>
                <path d="M72.8224 9.54683C72.8224 10.3789 72.8223 11.0463 72.8223 11.6467C71.3183 12.1144 62.4591 12.1534 60.7669 11.7299C60.7669 7.9442 60.7669 4.1273 60.7669 0.334576C62.1703 -0.0936076 71.0397 -0.126545 72.8225 0.327259C72.8225 3.30151 72.8225 6.34186 72.8224 9.54683Z" fill="white"/>
                <path d="M129.058 38.4907C131.09 38.4907 132.952 38.4907 135.047 38.4907C135.242 42.4534 135.125 46.2649 135.121 50.0298C133.836 50.4845 125.848 50.6033 123.568 50.2465C123.15 49.098 123.021 41.2046 123.416 38.6136C125.195 38.3506 127.05 38.5521 129.058 38.4907Z" fill="white"/>
                <path d="M75.5654 70.8377C73.8198 70.8377 72.2403 70.8377 70.5403 70.8377C70.5403 68.1251 70.5403 65.5031 70.5403 62.7682C73.1593 62.7682 75.786 62.7682 78.5073 62.7682C78.5073 65.3725 78.5073 67.9511 78.5073 70.755C77.5943 70.7822 76.663 70.81 75.5654 70.8377Z" fill="white"/>
                <path d="M38.5263 1.57797C38.5264 3.81658 38.5264 5.90307 38.5264 8.07948C35.807 8.07948 33.2263 8.07948 30.5434 8.07948C30.14 6.78543 30.3984 5.43113 30.3538 4.10288C30.3102 2.80689 30.3443 1.50829 30.3443 0.10152C33.0699 0.10152 35.6965 0.10152 38.4352 0.10152C38.4664 0.554419 38.4963 0.990128 38.5263 1.57797Z" fill="white"/>
                <path d="M134.894 67.5004C133.127 69.1582 131.387 70.8458 129.588 72.4679C125.384 76.2589 121.154 80.0203 116.931 83.7905C112.244 87.9751 107.552 92.1539 102.867 96.3401C100.545 98.4147 98.2288 100.496 95.9204 102.586C95.6372 102.842 95.4179 103.169 94.9568 103.714C104.71 104.746 114.209 105.751 123.794 106.765C123.794 151.171 123.794 195.47 123.794 240C111.762 238.756 99.7695 237.515 87.5638 236.253C87.5638 235.552 87.5639 234.891 87.5639 234.229C87.5637 198.018 87.5634 161.807 87.5632 125.597C87.5632 119.183 87.5569 112.77 87.5727 106.356C87.5743 105.685 87.7197 105.015 87.7751 104.343C87.8761 103.118 88.5405 102.275 89.4904 101.551C93.5446 98.4606 97.562 95.3222 101.62 92.2367C110.729 85.3093 119.853 78.3995 128.977 71.4907C130.738 70.1571 132.528 68.861 134.509 67.5259C134.774 67.503 134.834 67.5017 134.894 67.5004Z" fill="white"/>
                <path d="M127.638 230.281C127.638 190.269 127.655 150.427 127.6 110.585C127.596 107.903 128.382 105.794 130.199 103.855C134.031 99.7664 137.736 95.559 141.506 91.4124C141.79 91.1002 142.179 90.8838 142.684 90.4968C142.815 91.0179 142.934 91.313 142.956 91.6152C142.992 92.1234 142.967 92.636 142.967 93.1468C142.967 129.356 142.961 165.566 142.983 201.775C142.985 203.681 142.639 205.433 141.873 207.192C137.446 217.364 133.091 227.568 128.707 237.759C128.515 238.206 128.277 238.633 128.061 239.069C127.937 239.05 127.812 239.032 127.688 239.014C127.671 236.159 127.655 233.305 127.638 230.281Z" fill="white"/>
                <path d="M130.472 84.2257C126.666 88.1987 122.98 92.1208 119.21 95.9604C117.235 97.972 114.826 98.6039 111.768 97.59C121.807 88.8898 131.681 80.3313 141.501 71.8212C141.741 71.9568 141.806 71.9739 141.836 72.0157C141.862 72.0523 141.875 72.1402 141.851 72.1655C138.089 76.1595 134.323 80.15 130.472 84.2257Z" fill="white"/>
                <path d="M145.689 196.904C145.689 162.292 145.687 127.85 145.694 93.4075C145.695 90.9699 145.841 88.5305 145.777 86.0956C145.734 84.4654 146.293 83.301 147.596 82.3314C150.278 80.3374 152.887 78.2464 155.537 76.2092C166.316 67.9223 177.097 59.6383 187.884 51.3624C188.532 50.865 189.242 50.4471 189.923 49.9922C190.016 50.115 190.11 50.2379 190.204 50.3607C177.708 61.5721 165.212 72.7835 152.394 84.2832C153.345 84.512 153.901 84.7123 154.472 84.7724C159.36 85.2875 164.252 85.779 169.143 86.2738C172.458 86.6092 175.776 86.9272 179.09 87.2749C180.971 87.4723 181.535 88.1165 181.564 90.0004C181.567 90.2273 181.565 90.4543 181.565 90.6813C181.565 133.181 181.565 175.68 181.565 218.18C181.565 218.848 181.565 219.515 181.565 220.322C179.147 220.115 176.915 219.956 174.69 219.727C168.284 219.066 161.881 218.374 155.476 217.701C153.004 217.441 150.531 217.189 148.056 216.948C146.057 216.754 145.699 216.412 145.694 214.437C145.681 208.65 145.69 202.862 145.689 196.904Z" fill="white"/>
                <path d="M190.067 209.932C188.701 212.932 187.601 215.917 186.169 218.742C186.03 218.703 185.891 218.664 185.751 218.625C185.717 218.147 185.654 217.669 185.654 217.192C185.65 174.863 185.653 132.533 185.638 90.2042C185.638 88.2159 186.345 86.6397 187.699 85.1876C191.638 80.9654 195.487 76.6592 199.378 72.3921C202.545 68.9189 205.73 65.4623 208.891 61.9843C212.623 57.879 216.335 53.7556 220.061 49.6456C222.458 47.0029 224.863 44.3688 227.27 41.7363C227.418 41.5753 227.619 41.4633 227.932 41.2234C228.005 41.6482 228.107 41.9513 228.103 42.2527C227.998 49.0513 227.898 55.8501 227.761 62.6481C227.683 66.5534 227.522 70.457 227.434 74.3622C227.302 80.1956 227.209 86.0299 227.085 91.8635C226.984 96.5628 226.856 101.262 226.75 105.961C226.63 111.227 226.55 116.494 226.395 121.76C226.31 124.659 225.696 127.415 224.51 130.124C218.723 143.35 213.067 156.634 207.349 169.891C201.61 183.201 195.853 196.502 190.067 209.932Z" fill="white"/>
                <path d="M192.356 71.2041C190.877 73.1472 189.368 74.9288 188.114 76.8745C186.393 79.5452 184.057 80.244 180.853 79.527C190.518 68.9163 200.092 58.4507 209.691 48.0078C209.834 48.1129 209.977 48.2181 210.12 48.3233C209.935 48.6745 209.797 49.0631 209.557 49.3712C205.831 54.1448 202.098 58.9123 198.354 63.6717C196.394 66.1626 194.411 68.635 192.356 71.2041Z" fill="white"/>
                <path d="M171.427 79.2579C170.718 78.687 170.107 78.1926 169.513 77.7126C170.052 76.4805 197.408 51.7051 199.201 50.42C198.925 50.9515 198.843 51.2428 198.661 51.4426C196.066 54.2843 193.461 57.1172 190.851 59.9453C187.473 63.6057 184.094 67.265 180.706 70.9152C178.164 73.6533 175.599 76.3701 173.061 79.1116C172.58 79.6302 172.137 79.9454 171.427 79.2579Z" fill="white"/>
                <path d="M89.7855 80.6609C79.1709 85.3021 68.6736 89.8888 58.1779 94.4792C55.1122 95.8201 52.0499 97.169 48.9894 98.5217C48.6835 98.657 48.4008 98.8449 48.0399 99.046C48.3282 99.9091 49.1284 100.04 49.7428 100.322C53.4539 102.024 57.1828 103.687 60.9103 105.353C64.5864 106.996 68.2753 108.611 71.9443 110.27C74.1194 111.253 74.3395 111.796 73.5705 114.137C70.136 124.592 66.691 135.043 63.2479 145.494C58.8644 158.801 54.4732 172.105 50.0961 185.414C45.0988 200.608 40.112 215.806 35.1248 231.004C34.5944 232.621 34.1028 234.25 33.5717 235.866C33.4 236.389 33.1595 236.889 32.8672 237.602C21.8754 232.687 11.0261 227.836 0 222.905C0.158169 222.323 0.277051 221.793 0.445551 221.28C3.96389 210.555 7.48698 199.831 11.0108 189.108C16.4472 172.565 21.8878 156.024 27.3209 139.48C31.692 126.169 36.0784 112.864 40.3966 99.5364C41.0704 97.4568 42.2858 96.2148 44.3478 95.4993C58.9753 90.4235 73.5809 85.2846 88.1937 80.1664C90.3882 79.3978 92.5826 78.6288 94.7808 77.8707C95.0292 77.785 95.3018 77.7693 95.6768 78.0278C93.7524 78.8878 91.8279 79.7478 89.7855 80.6609Z" fill="white"/>
                <path d="M83.3775 128.727C83.2626 132.798 83.1426 136.705 83.0349 140.612C82.9163 144.917 82.8025 149.221 82.6984 153.526C82.5763 158.568 82.4703 163.61 82.351 168.652C82.2437 173.184 82.1614 177.716 82.006 182.246C81.8527 186.716 81.6023 191.181 81.4386 195.65C81.398 196.757 80.9569 197.602 80.1834 198.335C76.8512 201.495 73.532 204.669 70.1917 207.82C66.7286 211.087 63.2478 214.335 59.7723 217.589C55.2627 221.811 50.7483 226.029 46.2412 230.253C45.0828 231.339 43.9481 232.451 42.793 233.54C42.5145 233.803 42.1979 234.025 41.5896 234.515C41.6941 233.774 41.7001 233.364 41.8126 232.987C45.1152 221.903 48.4217 210.82 51.7416 199.742C55.5822 186.925 59.4346 174.112 63.2845 161.298C67.3952 147.615 71.5087 133.933 75.6215 120.251C76.2907 118.025 77.0124 115.814 77.6186 113.571C78.1802 111.493 79.4038 110.361 81.6023 110.341C82.2077 110.335 82.8122 110.22 83.5783 110.138C84.046 116.378 83.4227 122.47 83.3775 128.727Z" fill="white"/>
                <path d="M96.3001 84.399C96.4099 84.6147 96.4987 84.9576 96.3892 85.0414C95.9883 85.348 95.5324 85.5905 95.0777 85.8181C86.1967 90.263 77.3133 94.7029 68.4291 99.1414C68.0234 99.3441 67.6194 99.5629 67.1918 99.7064C66.1467 100.057 65.1733 99.9388 64.3551 99.1327C63.8366 98.6218 63.3295 98.0995 62.6247 97.3879C63.2782 97.0685 63.7405 96.7979 64.234 96.6081C71.4345 93.8391 78.6373 91.0762 85.8436 88.3222C89.2875 87.006 92.7398 85.7115 96.3001 84.399Z" fill="white"/>
                <path d="M79.4903 99.3508C83.816 97.2105 87.9926 95.0503 92.2575 93.0075C92.4151 93.4328 92.4481 93.4853 92.4507 93.5394C92.4532 93.592 92.4414 93.6641 92.4068 93.6965C92.1611 93.926 91.9291 94.1781 91.6543 94.3671C86.5682 97.8643 81.4766 101.354 76.3183 104.891C75.3642 104.117 74.4982 103.415 73.4862 102.594C74.4263 101.702 75.5246 101.383 76.4875 100.86C77.4342 100.346 78.4131 99.8909 79.4903 99.3508Z" fill="white"/>
                
                </SvgIcon>
                
                ) : (
                    <img src="/images/read21-white.svg" alt="Read21" />
                )}
            </Box>
        </Link>
    );
};